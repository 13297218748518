// import _ from 'lodash';
import {jsonStringify, parseJson, sleep} from './Functions';
const prefex = 'grc_';

const isSupported = () => {
  let supported = typeof(Storage) !== "undefined";
  if(!supported) alert('Local Storage Not available');
  return supported;
}


/*******************
* Local Storage
*********************/
export const _LocalStorage = {

  setJsonAsync: async function (key, json_value) {
      await null;
      let val = jsonStringify(json_value); //_.isString(value) ? val : JSON.stringify(value);
      return localStorage.setItem(`${prefex}${key}`, val);
  },
  getJsonAsync: async (key) => {
    await null;
    let val = await localStorage.getItem(`${prefex}${key}`);
    val = val ? parseJson(val) : false;
    return (!val || val == "false") ? false : val;
  },

  setJson: function (key, json_value) {
    let val = jsonStringify(json_value); //_.isString(value) ? val : JSON.stringify(value);
    return localStorage.setItem(`${prefex}${key}`, val);
  },
  getJson: function (key) {
    let val = localStorage.getItem(`${prefex}${key}`);
        val = parseJson(val);
      
    return (!val || val == "false") ? false : val;
  },

  setAsync: async function (key, value) {
    await null;
    return localStorage.setItem(`${prefex}${key}`, value);
  },
  getAsync: async function (key) {
    await null;
    let val = localStorage.getItem(`${prefex}${key}`);
    return (!val || val == "false") ? false : val;
  },

  set: function (key, value) {
    return localStorage.setItem(`${prefex}${key}`, value);
  },
  get: function (key) {
    let val = localStorage.getItem(`${prefex}${key}`);
    return (!val || val == "false") ? false : val;
  },
  remove: async function(key) {
    await null;
    localStorage.removeItem(`${prefex}${key}`);
    // await sleep(10)
    
    let val = await this.get(key); // this.getAsync(key);
    return val;

    // let result = await new Promise(function (resolve, reject) {
    //   while (val) {
    //     val = await this.getAsync(key);
    //   }
    //   if (!val) resolve(false);
    // });

    // return;
  },


  logout: async function () {
    this.remove("rights");
    this.remove("token");
    this.remove("refreshToken");

    // this.set('rights', false);
    // this.set('token', false);
    // this.set('refreshToken', false);
    // this.set('user', false);
    
    await sleep(50);
    return null;
    // window.location.href = "/";
  },

  login: async function ({ token, refreshToken, user, rights }) {
    // const data = {
    //   token: login_resutls.token,
    //   refreshToken: login_resutls.refreshToken,
    //   user: { ...login_resutls.user.user, error: undefined, autoLoginInfo: undefined },
    //   rights: login_resutls.user.rights,
    // }

    if(!user || !token){
      alert("Invalid login data!")
      return false;
    }

    await this.setAsync('token', token);
    // await this.setJsonAsync('user', user);

    if (rights) await this.setAsync('rights', rights);
    if (refreshToken) await this.setAsync('refreshToken', refreshToken);
       
    await sleep(50);
    // window.location.href = "/";
    return;
  },


  // getUser: function () {
  //   let val = await this.getJsonAsync('user')
  //   return val;
  //   // return (!val || val == "false") ? false : val;
  // },
  
  // getToken: async function () {
  //   let val = await this.getAsync('token');
  //   return val;
  //   // return (!val || val == "false") ? false : val;
  // },

  // getRefreshToken: function () {
  //   let val = await this.getAsync('refreshToken');
  //   return val;
  //   // return (!val || val == "false") ? false : val;
  // },

  // getUser: () => {
  //   let _user = await this.getJsonAsync('user');
  //   return _user;
  // },

  getAll: localStorage,
}
export const LocalStorage = isSupported() ? _LocalStorage : false;
// LocalStorage.addEventListener('awesome', e => console.log("custom event received"));
export default LocalStorage;




/*******************
* Session Storage
*********************/
const _SessionStorage = {
  set: async function (key, value) {
    await null;
    return sessionStorage.setItem(`${prefex}${key}`, value);
  },
  get: async function (key) {
    await null;
    return sessionStorage.getItem(`${prefex}${key}`);
  },
  getAll: sessionStorage,
}
export const SessionStorage = isSupported() ? _SessionStorage : false;
