import React, { useState, useEffect } from 'react'
import Axios from 'axios';
import { __error, __yellow } from './consoleHelper';
import { Button, DevBlock, Icon, Loader } from 'Common/components';
import { Row, Col, Progress, Tooltip, message, Modal } from 'antd'
import { gql } from 'graphql.macro';
import { withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import { couponSelect } from 'Modules/Checkout/redux/actions';
import { connect } from "react-redux";
import '../../Layout_v1/styles/loyalty_plan.scss';


const STORE_QUERY = gql`query store($id: ID!) {
    store(id: $id) {
        _id
        title
        slug
        hipo_loyalty_program_apikey
        status
    }
}`

const VALIDATE_VOUCHER = loader('src/graphqls/discount_voucher/validateVoucherForOrder.graphql');


export const getLoyaltyProgramData = async ({ hipo_loyalty_program_endpoint, hipo_loyalty_program_apikey, reff_id }) => {
    // console.log(__yellow('getLoyaltyProgramData()'))

    let input = {
        api_key: hipo_loyalty_program_apikey,
        reff_id: reff_id,
        action: 'customer-info'
    }

    let response = await Axios.put(hipo_loyalty_program_endpoint, input)
        .then(({ data }) => (data))
        .catch(err => {
            console.log(__error('Error: '), err)
            return { error: { message: "Unable to process your request at the moment" } }
        });

    return response;
}


const MilestoneProgressWrapper = ({ theme="orange", title, order, api_key, storeInfo, settings, store_id, user, client, on_couponSelect, showDiscountButton, showNoPointsInfo }) => {
    const [loyaltyData, set_loyaltyData] = useState(null);
    const [busy, setBusy] = useState(false);
    const [validating, setValidating] = useState(false);
    const [showHoeItWorks, set_showHoeItWorks] = useState(false);
    // const [_api_key, set_api_key] = useState(api_key);
    const [postData, set_postData] = useState({
        hipo_loyalty_program_apikey: api_key,
        hipo_loyalty_program_endpoint: settings.hipo_loyalty_program_endpoint,
        reff_id: user && user._id,
        // loyalty_plan: true
    });

    useEffect(() => {
        if (!postData.reff_id) return;

        if (postData.hipo_loyalty_program_apikey){
            fetchData()
            return;
        }

        if (!store_id) return;

        if (store_id) {
            fetchStoreData(store_id);
            return;
        }

    }, [postData.hipo_loyalty_program_apikey])

    const fetchStoreData = async() => {
        console.log(__yellow("fetchStoreData()"))
        if (!store_id) return;

        setBusy(true)

        let resutls = await client.query({ query: STORE_QUERY, variables: { id: Number(store_id) } }).then(r=>(r.data.store))
            .catch(err=>{
                console.log(__error("Invalid response"), err)
                return { error:{ message:"Invalid response"}}
            })
        if (!resutls || resutls.error){
            console.log(__error((resutls && resutls?.error?.message) || "Invalid response"))
            return;
        }
        if (!resutls.hipo_loyalty_program_apikey || !postData.hipo_loyalty_program_endpoint) {
            setBusy(false)
            return;
        }
        // set_storeData(resutls);
        set_postData({
            ...postData,
            hipo_loyalty_program_apikey: resutls.hipo_loyalty_program_apikey
        }, () => {
            fetchData()
        })
        // set_api_key(resutls.hipo_loyalty_program_apikey, ()=>{
        //     fetchData(resutls.hipo_loyalty_program_apikey)
        // })
    }

    const fetchData = async () => {
        setBusy(true);

        let resutls = await getLoyaltyProgramData(postData).catch(err => {
            console.log(__error("Error: "), err);
            return { error: { message: "Invalid response!" } }
        })

        setBusy(false);

        if (!resutls || resutls.error) {
            console.log(__error((resutls && resutls?.error?.message) || "Invalid Loyalty response!"))
            return;
        }
        set_loyaltyData(resutls);
    }

    const validateVoucher = async (coupon_code) => {
        setValidating(true);

        let results = await client.mutate({ mutation: VALIDATE_VOUCHER, 
            variables: {
                code: String(coupon_code).trim(), 
                customer_id: user._id,
                loyalty_plan: true
            }
        }).then(r => (r.data.validateVoucherForOrder))
        .catch(err=>{
            console.log(__error("Invalid response"), err)
            return { error:{message:"Unable to validate discount"}}
        })

        setValidating(false);

        if (!results || results.error){
            message.error((results && results.error.message) || "Unable to validate milestone discount");
            return;
        }

        on_couponSelect(results)
    }

    if (busy) return <Loader loading={true} />
    if (!loyaltyData) return null;

    let activeMilestone = loyaltyData?.milestoneDetails?.activeMilestone
    let nextMilestone = loyaltyData?.milestoneDetails?.nextMilestone;
    let lastMilestone = loyaltyData?.milestoneDetails?.lastMilestone;
    if (!activeMilestone && !nextMilestone) return null;

    let expiring_in = loyaltyData?.milestoneDetails?.expiring_in;
    let current_percent = activeMilestone ? (activeMilestone?.points / (nextMilestone ? nextMilestone?.points : activeMilestone?.points)) * 100 : 0;
        current_percent = Math.floor(current_percent)
    let next_percent = (loyaltyData.current_points / (nextMilestone ? nextMilestone?.points : ((activeMilestone && activeMilestone?.points) || 0))) * 100;
        next_percent = Math.floor(next_percent)

    let couponUsed = (activeMilestone?.key_value === order?.coupon?.code);

    const lastMilestoneAchieved = (!nextMilestone && lastMilestone && activeMilestone && activeMilestone.points >= lastMilestone.points)

    return (<Loader loading={validating}>

        <div className={`loyalty_plan ${theme}`}>
            {activeMilestone && showDiscountButton && <div className='congrats_heading'>Congratulations! <b>{activeMilestone.title}</b> achieved</div>}
            
            <div style={{ padding: "5px", }}>
                <Row gutter={[10]} align="middle" className='nowrap'>
                    {activeMilestone ? <Col>
                        <div align="center">Current</div>
                        <Tooltip color="green" title={activeMilestone.title}><Progress type="circle" percent={100} width={60} /></Tooltip>
                    </Col> : 
                    <Col>
                        <div align="center">Current</div>
                        <Progress type="circle" percent={0} width={60} />
                    </Col>}
                    <Col flex="auto" style={{ minWidth: "150px" }}>
                        <div align="center">
                            <div onClick={() => set_showHoeItWorks(true)} style={{ fontSize: "16px", fontWeight: "bold" }} className='ellipsis a'>{title} Milestone Rewards <Icon icon="question-circle" /></div>
                            <div style={{ fontSize: "12px" }}>
                                Points: {loyaltyData.current_points}<br />
                                {/* Purchase: {loyaltyData.total_purchase_amount} */}
                            </div>
                        </div>
                        <Progress showInfo={false} percent={next_percent} success={{ percent: current_percent }} style={{ margin: "0" }} />

                        {(expiring_in > 0) && <>
                            <div style={{ fontSize: "12px", textAlign: "center" }}>Expiring in {expiring_in} day(s)</div>
                        </>}

                        {(expiring_in === -1) && <>
                            <div style={{ color: "red", fontWeight: "bold" }}>Expired</div>
                        </>}
                        
                        {/* {expiring_in > 0 ? <div style={{ fontSize:"12px", textAlign:"center" }}>Expiring in {expiring_in} day(s)</div> : (expiring_in === -1) ? <div style={{ color: "red", fontWeight: "bold" }}>Expired</div> : null} */}

                    </Col>

                    {nextMilestone && <Col>
                        <div align="center">Next</div>
                        <Tooltip title={nextMilestone.title} color="blue"><Progress type="circle" percent={next_percent} width={60} /></Tooltip>
                    </Col>}

                    {(lastMilestone && lastMilestone.img) && <Col>
                        <Tooltip color='#FFEF00' title={<span style={{ color: "black" }}>
                            {lastMilestoneAchieved ? `You have achieved the ${lastMilestone.title}` : <>Buy more and win <b>{lastMilestone.title}</b> when you reach <b>{lastMilestone.points}</b> points</>}
                            </span>}>
                            <img src={lastMilestone.img} alt={lastMilestone.title} width={70} />
                        </Tooltip>
                    </Col>}
                </Row>

                {activeMilestone && (!couponUsed && showDiscountButton) && <div style={{ textAlign: "center", padding: "0px", marginTop:"-10px" }}>
                    <Tooltip title={`You can take advantages of this right now ${nextMilestone ? "or skip for next achievement" : ""}`}>
                        <Button onClick={() => validateVoucher(activeMilestone.key_value)} theme={theme} shape="round">Utilize This Milestone</Button>
                    </Tooltip>
                </div>}

            </div>
        </div>

        
        <Modal visible={showHoeItWorks} onCancel={() => set_showHoeItWorks(false)} title={`How to earn Loyalty Rewards`} footer={false}>
            <div>
                <p><b>Grocer Zambia is offering 2 types of Rewards on single order:</b></p>

                <h3>Grocer Store Rewards</h3>
                <p>Offerd by the Store</p>
                <ul>
                    <li>Place an order and you will automatically be enroled for loyalty plan if offered by the store</li>
                    <li>Your Milestone progress will be showen on your next order</li>
                    <li>If a milestone is reached, you will be offerd to take advantage when placing new order on same store</li>
                </ul>
                
                <h3>Grocer Zambia Rewards</h3>
                <p>Grocer Zambia is offering double rewards</p>
                <ul>
                    <li>When placing an order, you will not only receive rewards by the store, but also by Grocer Zambia</li>
                    <li>Keep an eye on your loyalty points by Grocer Zambia to take advantages of Extra Rewards</li>
                    <li>You will still get the Grocer Zambia Rewards even if no Loyalty plan is offerd by the store you are placing the order to.</li>
                </ul>

            </div>
        </Modal>

    </Loader>)
}
const WithApollo = withApollo(MilestoneProgressWrapper);

const mapDispatchToProps = (dispatch, ownProps) => ({
    on_couponSelect: (payload) => dispatch(couponSelect(payload)),
})
const mapStateToProps = state => {
    return ({
        settings: state.grocer_storefront,
        order: state.order,
        user: state?.grocer_storefront?.user,
    });
}
export const MilestoneProgress = connect(mapStateToProps, mapDispatchToProps)(WithApollo);
