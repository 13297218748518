import React, { Component } from 'react'
// import { graphql } from '@apollo/client/react/hoc';
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { connect } from "react-redux";
import { Modal } from 'antd';
import { __error } from 'Common/scripts/consoleHelper';
import { Loader, Button, DevBlock } from 'Common/components'
import { Select } from 'Common/components/Form';
import { addressSelect } from '../redux/actions'
import AddressForm from 'Modules/User/components/AddressForm'



const USER_ADDRESSES = loader('src/graphqls/user/userAddresses.graphql');

export const _AddressSelect = props => {
    const { loading, userAddresses } = props;
    const [showAddressForm, setShowAddressForm] = React.useState(false)
    const [busy, setBusy] = React.useState(false)

    const selectedAddress = props.order.address; // ? JSON.parse(props.order.address) : false;
    let default_address = !userAddresses ? null : (userAddresses.find(o => o.is_default == '1') || userAddresses[0]);

    React.useEffect(() => {
        // if (!userAddresses) return;
        if (default_address){
            // default_address = !userAddresses ? null : (userAddresses.find(o => o.is_default == '1') || userAddresses[0]);
            props.addressSelect(default_address)
        }
        
    }, [userAddresses]);

    const toggleAddressForm = args => {
        setShowAddressForm(!showAddressForm);
    }

    const onAddressAdded = args => {
        setBusy(true);
        toggleAddressForm();

        props.refetch({ id: props.user._id }).then(r => {
            default_address = JSON.stringify(r.data.userAddresses[r.data.userAddresses.length - 1]);

            setBusy(false);
            return r;
        });
        // props.loadMoreRows({ id: props.user._id }).then(r=>{
        //     setBusy(false);
        //     return r;
        // });

    }

    const onAddressSelect = address_id => {
        let address = userAddresses.find(o => o._id == address_id);
        props.addressSelect(address);
    }

    if (loading) return <Loader loading={true} />

    return (<>
        <label style={{ fontWeight: "600" }}>Delivery address</label>
        <Select width="300px" loading={busy || loading} disabled={busy || loading} name="address_id" placeholder="Select delivery address"
            onChange={onAddressSelect}
            defaultValue={default_address ? default_address._id : null}
            data={!userAddresses ? null : userAddresses.map(item => ({ _id: item._id, title: `${item.label} :: ${item.full_address}` }))}
            dropdownRender={menu => {
                return (<div>
                    {menu}
                    <hr />
                    <div style={{ margin: "5px" }}><Button type="default" theme="blue-outline" block onClick={toggleAddressForm}>Add another address</Button></div>
                </div>)
            }}
        />
        {selectedAddress && selectedAddress.delivery_instructions && <div style={{ maxWidth: "300px", marginTop: "5px" }}>{selectedAddress.delivery_instructions}</div>}

        <Modal title="" visible={showAddressForm} width={620} onCancel={toggleAddressForm} footer={null} bodyStyle={{ padding: "0px" }}>
            {showAddressForm && <AddressForm onCancel={toggleAddressForm} user_id={props.user._id} onSuccess={onAddressAdded} />}
        </Modal>
    </>)

    return (<>

        <label style={{fontWeight:"600"}}>Delivery address</label>
        <Select width="300px" loading={busy || loading} disabled={busy || loading} name="address_id" placeholder="Select delivery address"
            onChange={onAddressSelect}
            defaultValue={default_address}
            data={userAddresses.map(item => {
                return { _id: item._id, title: `${item.label} :: ${item.full_address}` };
            })}
            dropdownRender={menu => {
                return(<div>
                    {menu}
                    <hr />
                    <div style={{ margin: "5px" }}><Button type="default" theme="blue-outline" block onClick={toggleAddressForm}>Add another address</Button></div>
                </div>)
            }}
        />
        {selectedAddress && selectedAddress.delivery_instructions && <div style={{maxWidth:"300px", marginTop:"5px"}}>{selectedAddress.delivery_instructions}</div>}

    </>)
}

const AddressSelect = compose(
    graphql(USER_ADDRESSES, {
        options: props => {
            return { 
                variables: { id: props.user._id },
                notifyOnNetworkStatusChange: true,
                // onCompleted: (e) => console.log(">>>>>>>>>>> onCompleted: ", e),
                onError: (e) => console.log(__error("QUERY ERROR:"), e),
            };
        },
        props: (apolloProps) => {
            const { ownProps, data } = apolloProps;
            const { loading, userAddresses, error, fetchMore, subscribeToMore, refetch } = data;
            if (error) console.log(__error("error"), error);

            const loadMoreRows = (filter) => {
                let updateQuery = (previousResult, { fetchMoreResult }) => (fetchMoreResult.userAddresses);
                return fetchMore({ variables: filter, updateQuery: updateQuery });
            }

            return { loading, userAddresses, queryErrors: error, subscribeToMore, loadMoreRows, refetch }
        },
    }),
)(_AddressSelect);


const mapStateToProps = state => {
    return ({ order: state.order });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    addressSelect: (payload) => dispatch(addressSelect(payload)),
})
export default connect(mapStateToProps ,mapDispatchToProps)(AddressSelect);
// export default connect(mapStateToProps, mapDispatchToProps)(AddressSelect);
// export default AddressSelect;
