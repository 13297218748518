import React, { Component } from 'react'
// import { graphql, withApollo } from '@apollo/client/react/hoc';
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { Row, Col, Modal, message } from 'antd';
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";

import { E404 } from "Layout_v1";
import { Icon, Button, Heading, Loader } from 'Common/components'
import { FormComponent, FormField, FormFieldGroup, rules, composeValidators } from 'Common/components/Form'
import { phoneCodes, genders } from 'configs/constants';
import { __error } from 'Common/scripts/consoleHelper'
import Column from 'antd/lib/table/Column';

// const GET_PROFILE = loader('src/graphqls/user/user.graphql');
const UPDATE_USER_PASSWORD = loader('src/graphqls/user/updateUserPassword.graphql');


const UserPwdPage = props => {
    const [busy, setBusy] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState(null);

    const onSubmit = values => {
        // if (values.password !== values.confirm_pwd) { message.error("Password missmatch"); return; }
        setBusy(true);
        setSuccessMessage(null);
        
        props.updateUserPassword(props.user._id, values.new_password, values.old_password)
            .then(r => {
                const response = r.data.updateUserPassword;

                setBusy(false);
                if (!response || response.error) { 
                    message.error(response ? response.error.message : "Invalid response"); 
                    return; 
                }
                setSuccessMessage("Password updated");
            }).catch(err => {
                setBusy(false);
                console.log(__error("Unable to update"), err);
                message.error("Unable to update!")
            });
    }

    const { loading, user } = props;

    if (!user || !user._id) return <Redirect to="/" />

    return (<div className="h-center" style={{ marginBottom: "50px" }}>
        <div style={{ width: "100%", maxWidth: "400px" }}>
            <h3 style={{ textAlign: "center" }}>Password Update</h3>
        
            {successMessage && <div>{successMessage}</div> }
            {!successMessage && <FormComponent onSubmit={onSubmit} id='ProdCatForm' loading={busy} hideDevBlock={true} style={{ maxWidth: "350px", width: "100%"}}><>
                <FormField type="password" name="old_password" label="Old Password" validate={composeValidators(rules.required, rules.minChar(4))} compact style={{marginBottom:"20px"}} />
                <FormField type="password" name="new_password" label="New Password" validate={composeValidators(rules.required, rules.minChar(4))} compact style={{ marginBottom: "20px" }} />
                <Button block type="primary" size="large" htmlType="submit" style={{ marginTop: "10px" }}>Update</Button>
            </></FormComponent>}
    </div></div>)
}

const mapStateToAvatarPopProps = state => {
    return ({ 
        user: state?.grocer_storefront?.user,
    });
}
export const WithRedux = connect(mapStateToAvatarPopProps)(UserPwdPage);

const WithApollo = compose(

    graphql(UPDATE_USER_PASSWORD, {
        props: ({ mutate }) => ({
            updateUserPassword: (_id, new_password, old_password) => mutate({
                variables: { _id, new_password, old_password }
            }),
        })
    }),

)(WithRedux);

export default WithApollo;
