import React from 'react'
import PropTypes from 'prop-types';
import { Col, Row, message, Alert } from 'antd';
import _ from 'lodash'
import { connect } from "react-redux";
// import { graphql, withApollo } from '@apollo/client/react/hoc';
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { Link } from 'react-router-dom';
import { __error } from 'Common/scripts/consoleHelper';
import { Icon, Button, IconButton, Drawer, Avatar, Loader } from 'Common/components';
import LastMinBuy from './LastMinBuy';
import CartItem from './CartItem';

const REMOVE_FROM_CART = loader('src/graphqls/cart/deleteCartItem.graphql');
const REMOVE_UNAVAILABLE_CART = loader('src/graphqls/cart/removeUnavailableItemsFromCart.graphql');


class CartDrawer extends React.Component {
    state = { busy: false, reviewVisible:false };
    constructor(props) {
        super(props);
        this.onDelete = this.onDelete.bind(this);
        this.clearUnavailableItems = this.clearUnavailableItems.bind(this);
        this.toggleReview = this.toggleReview.bind(this);
    }

    async onDelete(prod) {
        const { user, redux_cart, deleteCartItem, updateCart } = this.props;

        if (user && user._id) {
            let data = { customer_id: user._id, product_id: prod.product_id, delete_all: "yes" };
            let removed = await deleteCartItem({ input: data })
                .then(r => {
                    if (!r || !r.data) {
                        message.error("Invalid response!");
                        return false;
                    }
                    if (r.data.deleteCartItem.error) {
                        console.log(__error("ERROR"), r.data.deleteCartItem);
                        message.error(r.data.deleteCartItem.error.message);
                        return false;
                    }
                    return r;
                })
                .catch(err => {
                    console.log(__error("ERROR"), err);
                    message.error("Unable to complete your request at the moment");
                    return false; //{error:{message:"Unable to complete request"}}
                })

            console.log("removed: ", removed);
            // updateCart(removed.data.deleteCartItem);
            return true; // disable local cart update if user is logged in.. this will be done via subscription
        }

        // deleteProduct(prod);
        return redux_cart.items.length > 1; // return false if deleteing last item in cart, to avoide state error of unmountend
        // return false;

    }

    async clearUnavailableItems(){
        const { user } = this.props;
        this.setState({busy:true});

        const updatedCart = await this.props.client.mutate({
            mutation: REMOVE_UNAVAILABLE_CART,
            variables: { id: user._id },
            // reducer: (previousResult, action, variables) => {
            //     console.log('reducer!!!!!', previousResult, action, variables);
            // }
        }).then(data => {
            // console.log('then: ', data);
            return data.data.removeUnavailableItemsFromCart;
        }).catch(err => {
            console.log(__error("ERROR: "), err)
            // this.setState({ busy: false })
            return false;
        });

        this.setState({ busy: false });

    }

    async toggleReview(val){
        if (val==undefined) this.setState({ reviewVisible: !this.state.reviewVisible });
        else this.setState({ reviewVisible: val });
    }

    render() {
        const { busy } = this.state;
        const { isSmallDevice, loading, offerByKey, onClose, visible, redux_cart, user, settings } = this.props;

        if (!settings) return <Alert message="System settings are not available!" type='error' showIcon />

        let invalid_cart = (user && user._id && user._id != redux_cart.customer_id || (!redux_cart.items || !redux_cart.items[0]?._id)) ? <div style={{ padding: "20px", textAlign: "center" }}><Loader>Loading...</Loader></div> : false;
        if (!user || !user._id) invalid_cart = true;

        const items = _.isArray(redux_cart.items) ? redux_cart.items.slice() : [];
        
        const totalItems = items.length;
        const unavailableItems = !invalid_cart ? items.filter(i => i.available_qty<1) : null;
        const availableItems = !invalid_cart ? items.filter(i => i.available_qty>0) : null;

        var hasOffer = false;
        let filtered_offer_items = [];
        if (offerByKey) {
            let offer_items = offerByKey && offerByKey.items || null;
            if (offer_items){
                offer_items.forEach(row => {
                    let item = items.find(o => o._id == row._id)
                    if (!item) filtered_offer_items.push(row);
                });
            }
            hasOffer = (!filtered_offer_items || filtered_offer_items.length < 1) ? false : true;
        }

        let stores = []; //items.map(o=>o.store)
        items.forEach(item => {
            if (!stores.find(o => o == item.store_id)) stores.push(item.store_id);
        });

        let disableCheckout = false;
        if (stores.length > 1) disableCheckout = true;
        if (settings.min_order_amount > redux_cart.grandTotal) disableCheckout = true;
        if (unavailableItems && unavailableItems.length > 0) disableCheckout = true;


        return (
            <Drawer width={isSmallDevice ? "100%" : '600px'} destroyOnClose={true} maskClosable={true} placement="right"
                loading={busy}
                className={`cart-drawer ${!invalid_cart && hasOffer ? "hasPromo" : ""}`}
                // onClose={onClose}
                visible={visible}
                bodyStyle={{}}
                onClose={()=>{
                    this.toggleReview(false);
                    onClose()
                }}
                // headerStyle={{ backgroundColor:"#F6F6F6"}}
                footer={totalItems<1 ? null : 
                    <div className="footer-inner-container">
                        {settings.min_order_amount > redux_cart.grandTotal ? <>
                            <p style={{color:"red"}}>Min order limit is {settings.currency} {settings.min_order_amount}</p>
                        </> : <><p>* Promo code can be applied on order confirmation</p></>}

                        <Link disabled={disableCheckout} to="/checkout" className={`proceed-button ${disableCheckout ? 'disabled' : ''}`}>
                            Proceed To Checkout
                            {/* <Row style={{ flexWrap: "nowrap", width:'100%' }}>
                                        <Col flex="auto">{settings.currency} {redux_cart.grandTotal}</Col>
                                        <Col>Proceed <Icon icon="cart-arrow-down" /></Col>
                                    </Row> */}
                        </Link>

                    </div>
                }
                title={<Row align='middle'>
                    <Col><Icon className="icon" icon="cart-arrow-down" /></Col>
                    <Col flex="auto">
                        {(redux_cart.totalItems > 0) ? 
                            <div className='ellipsis' style={{ maxWidth: "480px" }}>{redux_cart?.store?.title}</div> : 
                            <b>Your cart is empty</b>
                        }
                    </Col>
                    {/* {(redux_cart.totalItems > 0) ? <><b>{redux_cart?.store?.title} </b> ({redux_cart.totalItems} items)</> : <b>Your cart is empty</b>} */}
                </Row>}
            >
                {invalid_cart && <div className="cart-contents" style={{ height: "calc(100vh - 70px)", flex: 1, display: "flex", backgroundColor:"#F8F8F8" }}>
                    <div style={{ width: "100%", textAlign: "center", alignItems: "center", display: "flex", boxSizing: "border-box", paddingBottom: "150px" }}>
                        <img src={`/images/empty-cart.jpg`} width="100%" />
                    </div>
                </div>}

                {!invalid_cart && redux_cart.totalItems > 0 && <>
                    {(unavailableItems && unavailableItems.length > 0) && <div style={{ color: "red", backgroundColor: "rgba(255, 0, 0, 0.1)", margin:"10px 0"}}>
                        <Row align="middle">
                            <Col flex="auto" style={{padding:"10px"}}>
                                <div><b>{unavailableItems.length} item(s) out of stock</b></div>
                                <div style={{fontSize:"10px"}}>review to add alternatives</div>
                            </Col>
                            <Col style={{ paddingRight: "10px" }}><Button onClick={this.toggleReview}>Review</Button></Col>
                        </Row>
                    </div>}

                    <div className="cart-contents">
                        <Row>
                            <Col>Sub total</Col>
                            <Col flex="auto" align="right">{settings.currency} {redux_cart.totalPrice}</Col>
                        </Row>
                    </div>

                    {redux_cart.deliveryCharges > 0 && <div className="cart-contents">
                        <Row>
                            <Col>Delivery Charges</Col>
                            <Col flex="auto" align="right">+ {settings.currency} {redux_cart.deliveryCharges}</Col>
                        </Row>
                    </div>}

                    {redux_cart.total_savings > 0 && <div className="cart-contents">
                        <Row>
                            <Col>You saved</Col>
                            <Col flex="auto" align="right">{settings.currency} {redux_cart.total_savings}</Col>
                        </Row>
                    </div>}

                    <div style={{ marginBottom: "5px", }} />

                    {stores.length>1 && <>
                        <Alert message="You have multiple store items in cart" type="warning" showIcon />
                    </>}

                    <div style={{ padding: "0" }}>
                        {items.reverse().map((item, i) => {
                            return <CartItem settings={settings} key={`${i}`} prod={item} onDelete={this.onDelete} />
                        })}
                    </div>
                    
                    <div style={{ padding: "0" }}>
                        {stores.map((store, i)=>{
                            return <div key={i}>
                                {/* <h3 style={{ padding:"20px 0 0px 0" }}>{store.title}</h3> */}
                                {items.reverse().filter(item => item.store_id==store._id ).map((item, ii)=>{
                                    return <CartItem settings={settings} key={`${i}_${ii}`} prod={item} onDelete={this.onDelete} />
                                } )}
                            </div>
                        })}
                    </div>

                    {hasOffer && <LastMinBuy loading={loading} offers={!loading ? offerByKey.items : []} />}

                </>}

                {this.state.reviewVisible && 
                    <div style={{ position:"absolute", bottom:0, backgroundColor:"rgba(0, 0, 0, 0.3)", width:"100%", height:"100%"}}>
                        <div style={{ position: "absolute", bottom: 0, backgroundColor: "white", width: "100%", height: "50%", minHeight:"500px" }}>
                            <div style={{ color: "red", backgroundColor: "rgba(255, 0, 0, 0.1)", margin: "0", padding:"10px" }}>
                                <Row>
                                    <Col flex="auto"><b>Review unavailable items</b></Col>
                                    <Col><Button onClick={() => this.toggleReview(false)}>X</Button></Col>
                                </Row>
                            </div>
                            
                            {unavailableItems.map((item, i)=>{
                                return <CartItem settings={settings} key={i} prod={item} />
                            })}

                            <div style={{textAlign:"center", padding:"10px"}}><Button type="danger" onClick={this.clearUnavailableItems}>Clear All Unavailable Products</Button></div>
                        </div>
                    </div>
                }

            </Drawer>
        )
    }

}
CartDrawer.propTypes = {
    visible: PropTypes.bool.isRequired
}

const WithApollo = compose(

    graphql(REMOVE_FROM_CART, {
        props: ({ mutate }) => ({
            deleteCartItem: (args) => mutate({
                variables: { ...args }
            }),
        })
    }),

)(CartDrawer);

const mapStateToProps = state => {
    // console.log("state.grocer_storefront: ", state.grocer_storefront)
    return ({ 
        settings: state.grocer_storefront, 
        redux_cart: state.cart, 
        user: state?.grocer_storefront?.user,
    });
}
const WithRedux = connect(mapStateToProps)(WithApollo);

export default withApollo(WithRedux);