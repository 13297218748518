import React from 'react'
import { E404, LayoutWrapper } from "Layout_v1";
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { DevBlock, Loader, StoreListItem } from 'Common/components';
import { defaultPageSize } from 'configs'
import { __error } from 'Common/scripts/consoleHelper'
// import ProductListItem_skeleton from 'Layout_v1/ProductListItem_skeleton';

const LIST_DATA = loader('src/graphqls/stores/storesQuery.graphql');

const defaultFilter = { status: { $ne:"offline" } }

const StoresHome = ({ loading, storesQuery }) => {
    return (<LayoutWrapper topMenu={null} menuOverride={null}>
        <div style={{ fontSize: "32px", marginBottom:"20px" }}>Stores Available</div>
        <Loader loading={loading} type="stores" style={{ border: "1px solid black", width: "100%", flex: 1 }} />

        {/* {loading && <div className="product-list">
            {String("123456").split("").map((item, i) => {
                return <ProductListItem_skeleton key={i} />
            })}
        </div>} */}

        <div className='store-list'>
            {/* <DevBlock obj={storesQuery} /> */}
            {storesQuery && storesQuery.edges.map((store, i)=>{
                return <StoreListItem store={store} key={i} />
            })}
        </div>
    </LayoutWrapper>)
}

const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            var filter = {};
            let vars = { first: defaultPageSize, after: 0, filter: JSON.stringify({ ...defaultFilter }) };

            return {
                variables: vars,
                // fetchPolicy: "no-cache", //no-cache, cache-first, network-only, cache-only, cache-and-network
                notifyOnNetworkStatusChange: true,
            };

        },
        props: (props) => {
            const { ownProps, data } = props;
            const { loading, storesQuery, error, fetchMore, subscribeToMore } = data;

            if (error) console.log(__error("error"), error);

            const loadMoreRows = (first, after, filter, others, reset) => {
                let vars = {
                    first, after, others,
                    filter: JSON.stringify({
                        ...filter,
                        ...ownProps?.permanentFilter,
                    })
                };

                return fetchMore({
                    variables: vars,
                    fetchPolicy: "no-cache", //"network-only",
                    updateQuery: (prev, { fetchMoreResult }) => {

                        return {
                            storesQuery: {
                                totalCount: fetchMoreResult.storesQuery.totalCount,
                                edges: [...prev.storesQuery.edges, ...fetchMoreResult.storesQuery.edges,],
                                pageInfo: fetchMoreResult.storesQuery.pageInfo,
                                __typename: 'StoresQuery'
                            }
                        }

                    }
                })
            }

            return { loading, storesQuery, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),


)(StoresHome);

export default WithApollo;
