import React from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Row, message } from 'antd';
import { IconButton, Avatar, Loader } from 'Common/components';
import AddToCartButton from '../AddToCartButton'



export const CartItem = props => {
    const propTypes = {
        settings: PropTypes.object.isRequired,
        prod: PropTypes.shape({
            _id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            price: PropTypes.number.isRequired,
            price_was: PropTypes.number.isRequired,
            // tax_value: PropTypes.number,
            // tax_unit: PropTypes.string,
            tax_total: PropTypes.number,
            limit_max_qty_in_cart: PropTypes.number,
            picture_thumb: PropTypes.string,
            qty: PropTypes.number,

            attributes: PropTypes.arrayOf(
                PropTypes.shape({
                    _id: PropTypes.string,
                    title: PropTypes.string,
                    show_in_store: PropTypes.number,
                    value: PropTypes.string,
                    displayAs: PropTypes.string,
                })
            ),
        }).isRequired

    }
    const { prod, settings } = props;

    const [loading, setLoading] = React.useState(false);

    const onDelete = args => {
        setLoading(true)
        props.onDelete(prod).then(r => {
            if (r === true) setLoading(false)
        })
    }

    const renderAttributes = (item) => {
        if (!item.attributes) return null;
        return item.attributes.map((o, i) => {
            if (o.value && o.show_in_store == 1) return <span key={i}>{o.title} {o.value}</span>
            return null;
        })
    }

    let was = false;
    if (prod.price_was && prod.price_was > prod.price) {
        was = Number(((prod.price_was - prod.price) / prod.price_was) * 100).toFixed(2);
    }

    let thumb = prod.picture_thumb ? `${process.env.REACT_APP_DATA_URL}/${prod.picture_thumb}` : undefined;

    return (<Loader loading={loading} key={prod.product_id}><div className="cart-contents-cartitem">

        <Row align="middle">
            <Col className="prod-image-holder" style={{marginRight:"5px"}}>
                <Link to={`/product/${prod.slug}`}><Avatar type="product" src={thumb} size={62} shape="square" /></Link>
                
                {prod.available_qty < 1 && <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)', position: 'absolute', width: '100%', height: '100%', top: '-0px', left: '-0px', display: 'flex', alignItems: "center", justifyContent: "center" }}>
                    <div style={{ backgroundColor: '#CCC', width:"100%", textAlign:"center", fontSize: '10px', padding: '0px 3px' }}>Sold Out</div>
                </div>}
            </Col>
            <Col flex="auto" className="prod-details">
                <div className="pro-title" to={`/product/${prod.slug}`}>{prod.title}</div>
                <div className="prod-attribute">{renderAttributes(prod)}</div>
                <span style={{marginTop:"5px"}}><AddToCartButton prod={{ ...prod }} size="small" /></span>
            </Col>
            <Col flex="auto" className="prod-qty">
                <span>
                    {/* {prod.price_was && prod.price_was > 0 && prod.price_was > prod.price && <div className="price-cut tag">Price Cut</div> || null} */}
                    {was && <div className="price-cut"><div className="price-was-">{`${was}% off`}</div></div>}
                    <div>{prod.qty} x {prod.price}</div>
                </span>
            </Col>
            {props.onDelete && 
                <Col flex="auto" className="prod-cost" align="right">
                    <div>
                        <IconButton icon="times" onClick={onDelete} style={{ paddingBottom: "1px" }} />
                        <div>{settings.currency} {prod.price * prod.qty}</div>
                    </div>
                </Col>
            }
        </Row>
    </div></Loader>)
}

export default CartItem;