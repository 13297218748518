import React, { Component } from 'react'
import { graphql } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { Loader, Button } from 'Common/components';
import { __error } from 'Common/scripts/consoleHelper';

const LIST_DATA = loader('src/graphqls/product/client_productsQuery.graphql');

export class ListComp extends Component {
    state = {
        pagination: { first: 5, after: 0 },
        busy: false,
    };

    loadMore = () => {
        const {pagination} = this.state;

        let first = pagination.first;
        let after = pagination.after + pagination.first;

        this.setState({
            pagination: { first, after},
            busy: true,
        })

        this.props.loadMoreRows(first, after).then(r => {
            this.setState({ busy: false })
        })
    };


    render() {
        const { loading, client_productsQuery } = this.props;
        const { busy } = this.state;
        console.log("this.props: ", this.props);
        

        // if (loading || busy) return <Loader loading={true} />
        if (!client_productsQuery) return <div>Query not found</div>

        return (
            <div style={{padding:"20px"}}>
                <h4>Total : {client_productsQuery && client_productsQuery.totalCount || 0} </h4>
                <ol>
                    {client_productsQuery.edges.map((item, i)=>{
                        return <li key={i}>{item.title}</li>
                    })}
                </ol>

                <Button disabled={client_productsQuery.totalCount <= client_productsQuery.edges.length} loading={loading || busy} block onClick={() => this.loadMore()}>More</Button>
                {/* {loading || busy && <Loader loading={true} />} */}
            </div>
        )
    }
}


const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { 
                variables: { first: 5, after: 0 },
                fetchPolicy: "cache-and-network"
            };
        },
        props: ({ ownProps, data }) => {
            const { loading, client_productsQuery, error, fetchMore, subscribeToMore } = data;

            if (error) console.log(__error("error"), error);

            const loadMoreRows = (count, startFrom, filter, others) => {

                let vars = { first: count, after: startFrom, filter: filter, others: others };

                let updateQuery = (previousResult, { fetchMoreResult }) => {

                    const totalCount = fetchMoreResult.client_productsQuery.totalCount
                    const prevEdges = previousResult.client_productsQuery.edges
                    const newEdges = fetchMoreResult.client_productsQuery.edges
                    const pageInfo = fetchMoreResult.client_productsQuery.pageInfo
                    const combinedEdges = prevEdges.concat(newEdges);

                    return {
                        client_productsQuery: {
                            totalCount,
                            // edges: [...prevEdges, ...newEdges],
                            edges: combinedEdges,
                            pageInfo,
                            __typename: 'ProductQuery'
                        }
                    }
                }

                return fetchMore({ variables: vars, updateQuery: updateQuery }).then(r => {

                    if (r.error || r.errors) {
                        console.log(__error("QUERY ERROR"), r);
                    }
                    return r;
                });
            }

            return { loading, client_productsQuery, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

)(ListComp);

export default WithApollo;
