import React from 'react'
import PropTypes from 'prop-types';
import { DatePicker as _DatePicker } from 'antd';
import { Field } from 'react-final-form'
import moment, { isMoment } from 'moment';
import { Label } from './FormField'
// import debounce from 'lodash/debounce';


export const DateField = _props => {
    let props = { ..._props };
    delete props.label;
    delete props.format;

    return (
        <Field {...props}>
            {({ input, meta }) => {

                let _input = { ...input};
                delete _input.value;
                delete _input.onChange;

                let value = moment(input.value).isValid() ? moment(input.value) : null;

                return(
                    <div className={`form-field ${!props.compact && "field-margins"} date`}>
                        {_props.label && <label>{_props.label}</label>}
                        <div>
                            <_DatePicker 
                                {...props}
                                {..._input}
                                value={value}
                                onChange={(e) => {
                                    input.onChange(e.format(_props.valueFormat || "DD-MM-YYYY"));
                                    // input.onChange(e.format(_props.valueFormat || "YYYY-MM-DD"));
                                }}
                                defaultValue={moment(input.value)} 
                                // defaultValue={moment('01/01/2015', "YYYY-MM-DD")} 
                                // format={_props.format || "YYYY-MM-DD[T]HH:mm:ss[Z]"} 
                                format={_props.displayFormat || "DD-MM-YYYY"} 
                                // format={_props.displayFormat || "YYYY-MM-DD"} 
                            />
                        </div>
                        {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                   </div>
                )
            }}
        </Field>
    )


}
DateField.propTypes = {
    name: PropTypes.string.isRequired,
    inputProps: PropTypes.object,
}

export default DateField;




export const DatePicker = props => {
    let _props = { ...props }
    
    let style = { width: "100%", ...props.style }
    if (props.width) style = Object.assign(style, { width: props.width })
    
    delete _props.label
    delete _props.style
    // delete _props.value

    return null;
    
    // return (<_DatePicker defaultValue={moment('01/01/2015', "YYYY/MM/DD")} format={"YYYY/MM/DD"} />)


    // return (<div className="simple-field">
    //     {props.label && <Label>{props.label}</Label>}
    //     <_DatePicker
    //         name="abc"
    //         // defaultValue={moment()}
    //         style={{...style}}
    //         // {..._props}
    //         // onChange={onChange}
    //     />
    // </div>)
}