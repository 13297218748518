import React from 'react';
import { addModule, addReducers } from '../connector';
import StoresHome from './StoresHome';
// import {Icon} from 'common/components'
export const ROOT = '/stores';
export const MODULE = 'STORES';


// addReducers({ admins: reducers });
addModule(
    { path: ROOT, component: StoresHome, icon: 'laptop-code', title: "Stores", exact: 0, hideNav: 0 },
)
