import { utcToDate } from "Common/scripts/Functions";
import { __error } from "Common/scripts/consoleHelper";
import { message } from "antd";

export const collectCheckoutData = ({ order, cart }) => {
    if (!order) return { error:{message:"No order data found!"}}
    if (!cart) return { error:{message:"No cart data found!"}}
    
    const { timeslot, address } = order;

    if (!address || !address.geo_point || !address.geo_point.coordinates || address.geo_point.coordinates.length < 2) {
        console.log(__error("address: "), address);
        message.error('Address not found!');
        return { error: { mesage: "Address not found!" } };
    }

    let data = {
        // cart_id: cart._id,
        // paymentMethod: "COD",
        shipping_address: {
            _id: address._id,
            full_address: address.full_address,
            city: address.city,
            label: address.label,
            geo_point: {
                type: 'Point',
                coordinates: address.geo_point.coordinates
            },
            delivery_instructions: address.delivery_instructions,
        },
        geo_point: {
            type: address.geo_point.type, //'Point',
            coordinates: [address.geo_point.coordinates[0], address.geo_point.coordinates[1]] //  [center.lat, center.lng]
        },
        note: order.note,
        delivery_slot: {
            _id: timeslot._id,
            day: timeslot.day,
            date: utcToDate(timeslot.date),
            time_range_utc: timeslot.time_range_utc,
        },
        discount_voucher_code: order?.coupon?.code || null,
    }

    return data;
}

export function calculateCouponDiscount({ order, cart, currency }) {
    if (!order) return;
    const { coupon } = order;

    let deliveryCharges = Number(order.deliveryCharges || 0);
    var grandTotal = (cart && cart.grandTotal || 0) + deliveryCharges;
    var discount_value = 0;

    let returnVal = { d_message: false, d_details: false, discount_value, grandTotal, deliveryCharges }
    if (!coupon) return returnVal;

    if (coupon){
        returnVal.d_message = coupon.title;
        

        if (coupon.voucher_type.key == "gift"){ }
        if (['delivery', 'discount'].includes(coupon.voucher_type.key)){
            if (coupon.discount_type == 'fix'){
                // if delivery charges is >= discount value
                if (deliveryCharges >= coupon.discount_value){
                    discount_value = Number(coupon.discount_value); // Number(deliveryCharges) - Number(coupon.discount_value);
                    returnVal.d_details = `${currency} ` + Number(discount_value).toFixed(2);
                    // grandTotal = grandTotal - discount_value;
                    returnVal.d_message += `(${discount_value})`
                }else{
                    discount_value = deliveryCharges;
                    returnVal.d_message += `(FREE)`
                    returnVal.d_details = `${currency} ` + Number(discount_value).toFixed(2);
                    // grandTotal = grandTotal - discount_value;
                }
            }
            if (coupon.discount_type == '%' && deliveryCharges > 0) {
                discount_value = (Number(deliveryCharges) / 100) * Number(coupon.discount_value);
                returnVal.d_details = currency + " " + Number(discount_value).toFixed(2);
                returnVal.d_message += `(${discount_value}%)`
            }

            grandTotal = grandTotal - discount_value;
        }
        // if (coupon.voucher_type.key == "discount"){
        //     if (coupon.discount_type == 'fix') {
        //         discount_value = coupon.discount_value;
        //         returnVal.d_details = `${currency} ` + discount_value;
        //     }
        //     if (coupon.discount_type == '%') {
        //         discount_value = (Number(cart.totalPrice) / 100) * Number(coupon.discount_value);
        //         returnVal.d_details = discount_value;
        //     }
        // }
        // if (coupon.payment_method.id) returnVal.sub_message += `applies on ${coupon.payment_method.title} only`
    }



    // var grandTotal = cart && cart.grandTotal || 0;
    // var discount_value = 0;
    // let deliveryCharges = Number(order.deliveryCharges || 0);

    // if (coupon){
    //     if (coupon.category == "delivery") {
    //         if (coupon.discount_type == 'fix' && deliveryCharges < coupon.discount_value) discount_value = deliveryCharges - Number(coupon.discount_value);
    //         if (coupon.discount_type == '%' && deliveryCharges > 0) discount_value = (Number(deliveryCharges) / 100) * Number(coupon.discount_value);
    //     }
    //     else {
    //         if (coupon.discount_type == 'fix') discount_value = coupon.discount_value;
    //         if (coupon.discount_type == '%') discount_value = (Number(cart.totalPrice) / 100) * Number(coupon.discount_value);
    //         // grandTotal = Number(grandTotal - discount_value).toFixed(2)
    //     }
    //     if (coupon.discount_type == 'free-shipping') discount_value = deliveryCharges;
    // }

    // grandTotal = Number(Number(grandTotal) + Number(order.deliveryCharges) - Number(discount_value));
    // grandTotal = Number(grandTotal - discount_value)
    // grandTotal = Number(Number(grandTotal) + Number(order.deliveryCharges));

    // console.log("returnVal: ", returnVal)
    
    return { ...returnVal, grandTotal };

    // return { grandTotal, discount_value, ...returnVal }
}