import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { Dropdown, Row, Col, Divider } from 'antd';
import { __error } from 'Common/scripts/consoleHelper';
import { Icon, Button, Loader } from 'Common/components';


const _LayoutTopMenu = ({ affixed, loading, categories }) => { 
    const [isOpen, set_isOpen] = useState(false);

    useEffect(() => {
        if (affixed && isOpen) set_isOpen(false);
    }, [affixed])


    const RenderMenus = () => {
        if (loading || !categories) return null;

        return categories.map((item, i)=>{
            return <Col key={i} span={8} className="dd-menu-col">
                <Link to={`/category/${item.slug}`} className={`dd-menu-item`}>
                    <span style={{ fontSize:"10px", display:"inline-block" }}><Icon icon="circle" color="#DDD" style={{ margin:"0 5px 3px 0" }} /></span>
                    {`${item.title} (${item.prodCounts})`}
                </Link>
            </Col>
        })
    }

    const ddMenu = () => (<div className="dropdown-menu">
        <Loader loading={loading} style={{ padding: "25px" }} />
        <div className='menu-wrapper'><div className='menu-background'>
                <Row>
                    <Col flex="800px">
                    {!loading && categories &&
                            <Row className="dd-menu-row">
                                {RenderMenus()}
                            </Row>
                        }
                    </Col>
                    <Col flex="auto"></Col>
                </Row>
        </div>
        </div>
    </div>)

    return (
        <div className="site-layout-topmenu-wrapper"><main>
            <div className="site-layout-topmenu">
                {/* <Dropdown overlay={ddMenu()} onOpenChange={set_isOpen} open={isOpen} overlayClassName="dropdown-menu-wrapper" trigger={['click']}> */}
                <Dropdown overlay={ddMenu()} onOpenChange={set_isOpen} open={isOpen} overlayClassName="dropdown-menu-wrapper">
                    <div className='menu-item'><div className='gray-link'>Categories <Icon className="_icon" icon="chevron-down" /></div></div>
                </Dropdown>
                <Link to="/stores" className='menu-item'><div className='gray-link'>Stores</div></Link>
            </div>
        </main></div>
    )
}
const mapStateToProps = state => {
    return ({ 
        settings: state.grocer_storefront, 
        cart: state.cart
    });
}
const LayoutTopMenu = connect(mapStateToProps)(_LayoutTopMenu);

export default LayoutTopMenu;