export * from './FormField';
export * from './FormComponent';
export * from './UploadField';
export * from './FormFieldGroup';
export * from './DateField';

/*
adding value parser function

rules.minChar(
    13, 
    (val)=> val.replace(/_/, "") // this will remove all _
) 

*/

export const rules = {
    required: value => ((value || value===0) ? undefined : 'Required'),
    
    isNumber: value => (isNaN(value) ? 'Must be a number' : undefined),
    
    minValue: min => value => {
        return isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`
    },
    maxValue: max => value => isNaN(value) || value <= max ? undefined : `Should be less than ${max}`,
    
    minChar: (min, func) => value => {
        let val = value;
        if (func) val = func(value)
        return (val && val.length < min) ? `Should be atleast ${min} charactors` : undefined;
    },
    maxChar: (max, func) => value => {
        let val = value;
        if (func) val = func(value)
        return (val && val.length > max) ? `Should be maximum ${max} charactors` : undefined;
    },
    
    nospace: value => String(value).match(/\s/g) ? "Space characters are not allowed" : undefined,

    isEmail: value => String(value).match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g) ? undefined : "Invalid email address",

    // func: _func => values => _func(values) 
}

/***
 * composeValidators(rules.required, rules.isNumber, rules.minValue(18))
 */
export const composeValidators = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined)

