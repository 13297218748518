import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Alert, Radio, Space, Select, Row, Col, PageHeader, Image } from 'antd';
import { Button, DevBlock, Loader, Icon } from 'Common/components';
import KazangPayment from './kazang';
import SpennPayment from './spenn';
import { useLazyQuery, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import { collectCheckoutData } from '../checkout_lib';
// import compose from 'lodash.flowright';

const LIST_DATA = loader('src/graphqls/payment_methods/paymentMethods.graphql');

const PaymentMethods = ({ client, order, cart, onBackClick, devMode }) => {
    const [selectedMethod, setSelectedMethod] = useState(null)
    const [orderData, setOrderData] = useState(null)

    const [fetchData, { called, loading, data }] = useLazyQuery(
        LIST_DATA,
        { variables: { filter: JSON.stringify({ }) } }
    );

    useEffect(() => {
        if (called || loading || data) return;
        fetchData();
    }, [])

    useEffect(() => {
        if (selectedMethod && orderData) return;

        let _orderData = collectCheckoutData({ order, cart });
        setOrderData(_orderData);

        // select method if there is only 1 payment method
        // if (data?.paymentMethods && data?.paymentMethods.length == 1) onPaymentMethodSelected(data.paymentMethods[0]);
    }, [data])

    const onPaymentMethodSelected = (val) => setSelectedMethod(val);

    if (!called) return null;
    if (loading) return <Loader loading={true} />;
    if (!data || !data.paymentMethods) return <Alert message="No payment method available" showIcon type='error' />;
    if (!orderData) return <Alert message="No order data found!" showIcon type='error' />;

    if (selectedMethod){
        if (selectedMethod.ref_key == "kazang_mobile_payment") return <KazangPayment orderData={orderData} initialValues={selectedMethod} />;
        if (selectedMethod.ref_key == "spenn_payment") return <SpennPayment orderData={orderData} initialValues={selectedMethod} />;
        return <Alert message="Invalid paument method selected" showIcon type="error" />
    }

    return (<>
        <h3>Please choose a payment method to proceed</h3>

        {data?.paymentMethods?.map((method, i) => {
            let disabled = order?.coupon?.payment_method?.id && (order?.coupon?.payment_method?.ref_key !== method.ref_key);
            const buttonProps = {
                onClick: disabled ? undefined : () => onPaymentMethodSelected(method),
                className: `payment-method-bt ${disabled ? 'disabled' : ''}`,
                style: { marginBottom: "30px" },
                key: i
            }
            
            if (method.ref_key == 'kazang_mobile_payment'){
                return (<>
                    {(!disabled && order?.coupon?.payment_method?.ref_key === method.ref_key) && <div align="center">This payment method is selected by the discount voucher</div>}
                    <div {...buttonProps}>
                        <div><img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/kazang_payment.jpg`} width={400} height={100} alt={method.title} /></div>
                    </div>
                </>)
            }

            if (method.ref_key == 'spenn_payment'){
                return (<>
                    {(!disabled && order?.coupon?.payment_method?.ref_key === method.ref_key) && <div align="center">This payment method is selected by the discount voucher</div>}
                    <div {...buttonProps}>
                        <div><img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/spenn_payment.jpg`} width={400} height={100} alt={method.title} /></div>
                    </div>
                </>)
            }

            return <Alert message="Invalid Payment Method" showIcon type='error' />

        })}


        <hr style={{ margin:"50px" }} />

        {onBackClick && <Row gutter={[10]} align="middle">
            <Col><Button type="default" className="back-button" size="large" onClick={onBackClick} iconLeft={<Icon icon="angle-left" />}>Back</Button></Col>
            {/* <Col><h3>Payment & Proceed</h3></Col> */}
        </Row>}

        {/* <DevBlock obj={order} /> */}

    </>)
}
PaymentMethods.propTypes = {
    order: PropTypes.object.isRequired,
    cart: PropTypes.object.isRequired,
    onBackClick: PropTypes.func.isRequired,
}

const WithApollo =  withApollo(PaymentMethods)

const mapStateToProps = state => {
    return ({
        // devMode: true, // state?.grocer_storefront?.user?.email == "gibbaronsson@gmail.com",
        order: state.order,
        cart: state.cart,
    });
}
export default connect(mapStateToProps)(WithApollo);
