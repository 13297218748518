import React from 'react'
import PropTypes from 'prop-types';
import { __error } from 'Common/scripts/consoleHelper'
import CategoryPage from 'Modules/Category/containers/index';
import { DevBlock } from 'Common/components';
import ProdList from 'Modules/Category/components/products_list';


const SearchPage = props => {

  let filter = {};
  if (props.match.params.kw) Object.assign(filter, { search: { keywords: props.match.params.kw } })
  else return <p>Nothing to search</p>
  
  // return <DevBlock obj={filter} />
  return <CategoryPage {...props} searchFilter={filter} />
}

SearchPage.propTypes = {
  // toggleDrawer: PropTypes.func.isRequired
  // prop: PropTypes.type.isRequired
  // onEditRecord: PropTypes.func.isRequired
}

export default SearchPage;
