import React from 'react'
import PropTypes from 'prop-types';
import { Col, Row, message } from 'antd';
import _ from 'lodash'
import { connect } from "react-redux";
// import { graphql, withApollo } from '@apollo/client/react/hoc';
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { __error } from 'Common/scripts/consoleHelper';
// import { Loader } from 'Common/components';
import { updateCart } from 'Store/cart.actions'
import CartDrawer from './CartDrawer';

import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
// import { updateToken } from 'Modules/User/redux/actions';

const QUERY_SUBSCRIPTION = loader('src/graphqls/cart/subscription.graphql');
const MY_CART = loader('src/graphqls/cart/myCart.graphql');
const GET_LAST_MIN_OFFER = loader('src/graphqls/offers/offerByKey.graphql');


class CartSubncriptionWrapper extends React.Component {
    state = { busy: true };
    subscription = null;
    constructor(props) {
        super(props);
    }

    onUpdateReceived({ node, mutation }) {
        this.props.updateCart(node);
        // if (mutation == 'UPDATED'){
        //     // this.props.updateCart(node);
        // }
    }

    componentDidCatch(error, errorInfo) {
        console.log(__error("componentDidCatch"), error, "\n", errorInfo);
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
    }

    componentDidMount() {
        if (!this.subscription) {
            this.subscription = new SubscriptionHandler({
                _onUpdateReceive: (subData) => this.onUpdateReceived(subData),
                _subscribeToMore: this.props.subscribeToMore,
                _document: QUERY_SUBSCRIPTION,
                _variables: {
                    id: this.props.user._id
                },
                _subscriptionName: "cartUpdated",
                _subscriptionType: "object",
                _queryName: "myCart",
                _typename: "Cart",
                debug: true
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { subscribeToMore } = nextProps;
        const { loadingCart, myCart } = this.props;

        if (myCart && myCart.error){
            console.log(__error("Error: "), myCart)
            // alert(myCart.error.message)
        }

        // first time loading/
        if (loadingCart && !nextProps.loadingCart)
            this.props.updateCart(nextProps.myCart);

        if (!loadingCart){
            if (nextProps.ping){
                // localStorage.setItem("token", nextProps?.ping?.token)
                // localStorage.setItem("refreshToken", nextProps.ping.refreshToken)

                // document.dispatchEvent(global.LogOutUser, { hello:"world" });
                // if (!nextProps?.ping?.token) document.dispatchEvent(new CustomEvent('logOutUser', { bubbles: true, detail: nextProps?.ping?.token }));

                // if (this.props.token != nextProps?.ping?.token) this.props.updateToken({ token: nextProps.ping.token, refreshToken: nextProps.ping.refreshToken });
            }


            if (nextProps.myCart && JSON.stringify(myCart) != JSON.stringify(nextProps.myCart)){
                this.props.updateCart(nextProps.myCart);
            }

        }

        // if (!loadingCart && nextProps.myCart && JSON.stringify(myCart) != JSON.stringify(nextProps.myCart))
        //     this.props.updateCart(nextProps.myCart);
        

        // if (!this.subscription) {
        //     this.subscription = new SubscriptionHandler({
        //         // _onUpdateReceive: (subInfo) => console.log(subInfo),
        //         _subscribeToMore: subscribeToMore,
        //         _document: QUERY_SUBSCRIPTION,
        //         _variables: {
        //             id: this.props.user._id
        //         },
        //         _subscriptionName: "cartUpdated",
        //         _subscriptionType: "object",
        //         _queryName: "myCart",
        //         _typename: "Cart",
        //         debug: true
        //     });
        // }

    }

    render() {
        return <CartDrawer {...this.props} />
    }

}
CartSubncriptionWrapper.propTypes = {
    // todos: PropTypes.arrayOf(
    //     PropTypes.shape({
    //         id: PropTypes.number.isRequired,
    //         completed: PropTypes.bool.isRequired,
    //         text: PropTypes.string.isRequired
    //     }).isRequired
    // ).isRequired,
    // toggleTodo: PropTypes.func.isRequired.
    visible: PropTypes.bool.isRequired
}


const WithApollo = compose(
    graphql(MY_CART, {
        options: props => {
            return {
                // variables: { id: props.user._id },
                fetchPolicy: "network-only", //no-cache, cache-first, network-only, cache-only, cache-and-network
                notifyOnNetworkStatusChange: true,
            };
        },
        props: (props) => {
            const { ownProps, data } = props;
            const { loading, myCart, ping, error, refetch, subscribeToMore } = data;

            if (error) console.log(__error("error"), error);

            return { loadingCart: loading, myCart, ping, error, refetch, subscribeToMore }
        },
    }),

    graphql(GET_LAST_MIN_OFFER, {
        options: (props) => {
            return {
                variables: { key: 'last-min' },
                // fetchPolicy: "no-cache", //no-cache, cache-first, network-only, cache-only, cache-and-network
            };
        },
        props: ({ ownProps, data }) => {
            const { loading, offerByKey, error } = data;

            if (error) console.log(__error("error"), error);

            return { loading, offerByKey, queryErrors: error, }
        },
    }),

)(CartSubncriptionWrapper);


const mapStateToProps = state => {
    return ({ 
        redux_cart: state.cart, 
        user: state?.grocer_storefront?.user,
    });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    updateCart: (cart) => dispatch(updateCart(cart)),
})
const WithRedux = connect(mapStateToProps, mapDispatchToProps)(WithApollo);

export default WithRedux;