import React from 'react'
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';
import compose from 'lodash.flowright';
import { __error } from 'Common/scripts/consoleHelper'
// import { Loader } from 'Common/components'
import Home from './components/Home';
import { LayoutWrapper } from 'Layout_v1';

const RECORD = loader('src/graphqls/page_settings/page_setting.graphql');

export const MainPage = props => {
    const { loading, pageSettingByKey } = props;
    var pageConfigs = { homePageHeader: { banners: [{}, {}, {}] } }

    if (pageSettingByKey && pageSettingByKey.banners) pageConfigs = Object.assign(pageConfigs, { banners: pageSettingByKey.banners });
    if (pageSettingByKey && pageSettingByKey.products) pageConfigs = Object.assign(pageConfigs, { products: pageSettingByKey.products });

    return (<LayoutWrapper topMenu={null} menuOverride={null}>
        {/* {loading && <Loader type="skeleton" loading={true} />} */}
        <Home {...pageSettingByKey} loading={loading} />
    </LayoutWrapper>)
}


const WithApollo = compose(
    graphql(RECORD, {
        options: () => {
            return {
                variables: { key: "home-page" },
                fetchPolicy: "no-cache", //no-cache, cache-first, network-only, cache-only, cache-and-network
            };
        },
        props: ({ ownProps, data }) => {
            const { loading, pageSettingByKey, error } = data;
            if (error) console.log(__error("error"), error);
            return { loading, pageSettingByKey, queryErrors: error, }
        },
    }),
)(MainPage);

export default WithApollo;
