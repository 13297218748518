import React, { useEffect } from 'react'
import { connect } from "react-redux";
import moment from 'moment'
import { Result, Row, Col, Space } from 'antd';
import { Link, useLocation } from 'react-router-dom';
// import { E404 } from 'Layout_v1'
// import { CheckoutSuccess } from "Layout_v1/Pages";
import { DevBlock, Button } from 'Common/components';


const SuccessContainer = props => {
    var location = useLocation();
    /*
    This hook returns the current location object.
    This can be useful if you'd like to perform some side effect whenever the current location changes.

    React.useEffect(() => {
        // Google Analytics
        ga('send', 'pageview');
    }, [location]);
    */

    let query = decodeURI(location.search).substring(1)
    let vars = query.split("&");
    var params = {}
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        Object.assign(params, { [pair[0]]: pair[1] })
    }

    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        //   return () => { };
    })

    return (<div>
        <Col align="center">
            <div style={{ border: "0px solid black", width: "100%", maxWidth: "700px", margin: "80px 0" }}>
                <Row style={{ flexWrap: "nowrap" }} gutter={[20]}>
                    <Col><img src={`/assets/grocer-icon-img.png`} width="100" /></Col>
                    <Col align="left"><Space direction='vertical'>
                        <div style={{ fontSize: "24px" }}>Thank You! Your Order Has Been Submitted Successfully</div>
                        <div style={{ color: "#3D3D3D" }}>Our representative will contact you for delivery.</div>
                        {/* {params.message && <div>scheduled for: {params.message && (<b> {String(params.message)}</b>)}</div>} */}
                        {params.message && <div>{params.message && (<b> {String(params.message)}</b>)}</div>}
                        <div style={{ height:"20px" }} />
                        <Button key={2} size="large"><Link to="/">Continue Shopping</Link></Button>
                    </Space></Col>
                </Row>
            </div>
        </Col>
    </div>)

    return (<>
        <Result
            icon={<div key={1}><img src={`/assets/grocer-icon-img.png`} width="100" /></div>}
            status="success"
            title="Your Order Has Been Submitted Successfully"
            subTitle={<span style={{ color:"#3D3D3D"}}>Thank you for your order you will be informed before the delivery at your selected time slot 
                {params.message && (<b> {String(params.message)}</b> )}
                {/* {timeslot && (<b> {target_date} - {start_time} - {end_time}</b> )} */}
                {/* {timeslot && ( <b> {moment(timeslot.date).format('MMMM Do YYYY')} - {convertTo12Hrs(timeslot.start_time)} - {convertTo12Hrs(timeslot.end_time)}</b> )} */}
            </span>}
            extra={[
                // <div key={1}><img src={`/assets/grocer-icon-img.png`} width="100" /></div>,
                <Button key={2} size="large"><Link to="/">Home</Link></Button>,
            ]}
        />
    </>)

    // return (
    //     <div>
    //         {/* <CheckoutSuccess timeslot={`( Monday 13:00 - 15:00 )`} /> */}
    //         <CheckoutSuccess timeslot={`( ${moment(timeslot.date).format('MMMM Do YYYY')} - ${convertTo12Hrs(timeslot.start_time)} - ${convertTo12Hrs(timeslot.end_time)} )`} />
    //         <DevBlock obj={props.order} />
    //     </div>
    // )
}


const mapStateToProps = state => {
    return ({ 
        order: state.order, 
        user: state?.grocer_storefront?.user,
    });
}
export default connect(mapStateToProps)(SuccessContainer);
// export default SuccessContainer;
