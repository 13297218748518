import React from 'react';
import PropTypes from 'prop-types';
import { Spin, Skeleton, List, Avatar, Space } from 'antd';

import ClockLoader from "react-spinners/ClockLoader";
import { CircleSpinner } from './';

// https://ant.design/components/spin/

/***
 * Spin.setDefaultIndicator(indicator: ReactNode)

 * <Spin 
 * spinning={boolean}
 * tip={string}
 * size={small | default | large}
 * wrapperClassName={className}
 * delay={number}
 */

/**
   //  custom icon
   import { LoadingOutlined } from '@ant-design/icons';
   const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
   <Spin indicator={antIcon} />
 */

export const Loader = props => {
  const propTypes = {
    center: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    // skeleton settings
    center: PropTypes.bool,
    title: PropTypes.string,
    avatar: PropTypes.bool,
    round: PropTypes.bool,
    paragraph: PropTypes.object, // {rows: 4}
  }

  if (props.type == 'stores') {
    return (<Skeleton active loading={props.loading}
      avatar={true}
      paragraph={props.paragraph || { rows: 4 }}
      title={true}
      round={props.round}
    />)
  }

  if (props.type == 'skeleton'){
    return <Skeleton active loading={props.loading} 
      avatar={props.avatar}
      paragraph={props.paragraph || { rows: 4 }}
      title={props.title}
      round={props.round}
    >
      <List.Item.Meta />
    </Skeleton>
  }

  if (props.type == 'home'){
    return <>
      <Skeleton active avatar paragraph={{ rows: 4 }} />
      <Skeleton active avatar paragraph={{ rows: 4 }} />
      <Skeleton active avatar paragraph={{ rows: 4 }} />
    </>   
  }

  let _props = { ...props };
  delete _props.loading;
  delete _props.center;

  _props = Object.assign(_props, {spinning:props.loading || false})
  let TheLoader =  <Spin {..._props} indicator={<ClockLoader color="green" size={Number(props.size) || 50} />} />

  if (props.center) return <div style={{ display: "flex", flex: 1, height:"100%", width:"100%", border: "0px solid black" }} className='h-center'>{TheLoader}</div>
  
  return TheLoader;

  // return (<div><ClockLoader /> {props.children}</div>)
  // return <Spin {..._props} indicator={<CircleSpinner size={Number(props.size) || 50} />} />
};
export default Loader;