import React, { Component } from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { Icon, Heading } from 'Common/components';

const Databox = props => {
    return <div style={{ border: "1px solid #CCC", margin: "10px", padding: "10px", textAlign:"center", width:"150px" }}>
        {props.children}
    </div>
}

export const IconPage = props => {
    
    // console.log("library: ", library.definitions.fas);

    const renderIconsSolid = () => {
        let icons = library.definitions.fas;
        let iconsArray = []
        for (let icon in icons) {
            iconsArray.push(<Databox key={icon}><Icon icon={icon} style={{ marginRight: '10px' }} /><div>{icon}</div></Databox>)
        }
        return iconsArray;
    }
    
    const renderIconsRegular = () => {
        let icons = library.definitions.far;
        let iconsArray = []
        for (let icon in icons) {
            iconsArray.push(<Databox key={icon}><Icon icon={['far', icon]} style={{ marginRight: '10px' }} /><div>{icon}</div></Databox>)
        }
        return iconsArray;
    }
    
    return (
        <div style={{display:'flex', flex:'1', flexWrap:'wrap'}}>
            <Heading>Icons Solid</Heading>
            <div style={{ display: 'flex', flexWrap: "wrap" }}>{renderIconsSolid()}</div>
            
            <Heading>Icons Regular</Heading>
            <div style={{ display: 'flex', flexWrap: "wrap" }}>{renderIconsRegular()}</div>
        </div>
    )
}


export default IconPage;