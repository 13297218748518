import React from 'react'
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import ProductGalleryItem from 'Layout_v1/ProductGalleryItem';
// import { ReactSmartScroller } from 'Common/components/react-smart-scroller'
import styled from 'styled-components'
import Icon from 'Common/components/Icon'

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const scrollerProps = {
    additionalTransfrom: 0,
    arrows: true,
    autoPlaySpeed: 3000,
    centerMode: false,
    className: "",
    containerClass: "container-with-dots",
    dotListClass: "",
    draggable: true,
    focusOnSelect: false,
    infinite: true,
    itemClass: "",
    keyBoardControl: true,
    minimumTouchDrag: 80,
    pauseOnHover: true,
    renderArrowsWhenDisabled: false,
    renderButtonGroupOutside: false,
    renderDotsOutside: false,
    responsive: {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
            partialVisibilityGutter: 40
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            partialVisibilityGutter: 30
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            partialVisibilityGutter: 30
        }
    },
    rewind: false,
    rewindWithAnimation: false,
    rtl: false,
    shouldResetAutoplay: true,
    showDots: false,
    sliderClass: "",
    slidesToSlide: 1,
    swipeable: true
}


export const ProductGalleryScroller = props => {
    const [imgView, setImgView] = React.useState((props.data && props.data.length > 0) ? props.data[0].img : null);
    const [previewVisible, setPreviewVisible] = React.useState(false);
    if (!props.data || props.data.length < 1) return null;

    const onThumbClick = args => setImgView(args.img);
    const onPreviewClick = img => {
        if (!img) return;
        setPreviewVisible(img);
    }

    let img = "/assets/no-image-available.png";
    if (imgView) img = (imgView.indexOf("http") > -1) ? imgView : `${process.env.REACT_APP_DATA_URL}/${imgView}`;

    return (<>
        <div className="products-gallery-scroller">
            <img onClick={() => onPreviewClick(imgView)} src={img} alt={props.title} style={{ maxWidth: "100%", maxHeight: '400px' }} />

            {(props.data && props.data.length>1) && <>
                <div style={{ width: "100%" }} className="products-li-scroller">
                    <Carousel {...scrollerProps}>
                        {props.data.map((el, i) => {
                            return <ProductGalleryItem {...el} onClick={props.showDirectPop ? () => onPreviewClick(`${el.img}`) : onThumbClick} className={`item_${i}`} key={i} />
                        })}
                    </Carousel>
                </div>
            </>}
        </div>

        <Modal visible={previewVisible != false} title={""} footer={null} onCancel={() => setPreviewVisible(!previewVisible)}>
            <div style={{ textAlign: "center" }}>
                {previewVisible && <img alt={props.title} src={(imgView.indexOf("http") > -1) ? previewVisible : `${process.env.REACT_APP_DATA_URL}/${previewVisible}`} style={{ maxWidth: "100%" }} />}
            </div>
        </Modal>
    
    </>)

    // return (<><div className="products-gallery-scroller">
    //     <img onClick={() => onPreviewClick(imgView)} src={`${process.env.REACT_APP_DATA_URL}/${imgView}`} alt={props.title} style={{ maxWidth: "100%", maxHeight:'400px' }} />

    //     {props.data && props.data.length > 1 &&
    //         <ReactSmartScroller draggable
    //             hideScrollbar={props.hideScrollbar}
    //             CustomLeftArraw={LeftArrowButton}
    //             CustomRightArraw={RightArrowButton}
    //             spacing={0}
    //             arrowNavigation={true}
    //         >
    //             {props.data.map((el, i) => {
    //                 return <ProductGalleryItem {...el} onClick={props.showDirectPop ? () => onPreviewClick(`${el.img}`) : onThumbClick} className={`item_${i}`} key={i} />
    //             })}
    //         </ReactSmartScroller>
    //     }
    // </div>
    
    // <Modal visible={previewVisible != false} title={""} footer={null} onCancel={() => setPreviewVisible(!previewVisible)}>
    //     <div style={{textAlign:"center"}}>
    //             {previewVisible && <img alt={props.title} src={`${process.env.REACT_APP_DATA_URL}/${previewVisible}`} style={{maxWidth:"100%"}} />}
    //     </div>
    // </Modal>
    
    // </>)

}
ProductGalleryScroller.propTypes = {
    data: PropTypes.array,
    showDirectPop: PropTypes.bool,
    // labelA: PropTypes.oneOfType([
    //             PropTypes.string,
    //             PropTypes.object
    //         ]).isRequired,
}

export default ProductGalleryScroller;



export const ArrowLeftButtonHolder = styled.div`
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -17px 0 0 2px;
    padding-right:2px;
    height: 20px;
    width: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    border-radius:100%;
    cursor:pointer;
    z-index:1;
    &:hover{
        background: #EEE;
    }
    &[disabled]{
        cursor:default;
        opacity: 0.2;
    }
`
export const LeftArrowButton = (props) => <ArrowLeftButtonHolder {...props}><Icon size="sm" icon="angle-left" /></ArrowLeftButtonHolder>


export const ArrowRightButtonHolder = styled.div`
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -17px 2px 0 0;
    padding-left: 1px;
    height: 20px;
    width: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    border-radius:100%;
    cursor:pointer;
    z-index:1;
    &:hover{
        background: #EEE;
    }
    &[disabled]{
        cursor:default;
        opacity: 0.2;
    }
`
export const RightArrowButton = (props) => <ArrowRightButtonHolder {...props}><Icon size="sm" icon="angle-right" /></ArrowRightButtonHolder>
