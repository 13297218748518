import React from 'react';
import PropTypes from 'prop-types';
import { jsonStringify } from 'Common/scripts/Functions';

/**
 * DevBlock
 **** this will print anything only in dev environment
 */
export const DevBlock = props => { // eslint-disable-line react/prefer-stateless-function
  if (process.env.NODE_ENV !== 'development' && !props.force) return null;
  
  const { json, array, obj, force } = props;
    return (<>
      <pre className="dev_block">
        {/* {obj && <pre>{JSON.stringify(obj, 0, 2)}</pre>} */}
        {obj && jsonStringify(obj, 0, 2)}
        {json && jsonStringify(json, 0, 2)}
        {array && jsonStringify(array, 0, 2)}
      </pre>
      {props.children && props.children}
    </>);
}

DevBlock.propTypes = {
  force: PropTypes.bool,
}

export default DevBlock;
