import React, { Component } from 'react'
import MainPage from './containers';

const WebViewPage = props => {
    return (
        <div style={{padding:"10px"}}>
            <MainPage {...props} />
        </div>
    )
}

export default WebViewPage;