import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'antd';
import { Loader } from './';

/**
 * DataGrid
 */
export class DataGrid extends Component { // eslint-disable-line react/prefer-stateless-function
  render() {
    const { loading, dataSource, pagination, onChange, onShowSizeChange } = this.props;
    if (loading) return <><Loader loading={loading} /></>

    if (!dataSource || dataSource.length < 1) return <div>No records found.</div>

    return (<>
      <div className='dataGrid'>
        {dataSource.map((item, i)=>{
          return <this.props.gridItem {...item} key={i} />
          // return <UserCard key={i} {...item} />
        })}
      </div>

      <div style={{ textAlign: "center", padding: "20px"}}>
        <Pagination {...pagination} onChange={onChange}
          pageSizeOptions={['10', '15', '20', '25', '30']}
          defaultPageSize={10}
          showSizeChanger={true}
          onShowSizeChange={onShowSizeChange}
        />
      </div>

    </>)

  }
}

DataGrid.propTypes = {
  loading: PropTypes.bool,
  // queryName: PropTypes.string.isRequired,
  // onChange: PropTypes.func.isRequired,
  dataSource: PropTypes.array,
  gridItem: PropTypes.oneOfType([
              PropTypes.func,
              PropTypes.object,
            ]).isRequired,
  // prop: PropTypes.type.isRequired
}


export default DataGrid;
