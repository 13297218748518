import React from 'react'
import { connect } from "react-redux";
import { Badge } from 'antd';
// import { useMediaQuery } from 'react-responsive'
import { Button, Icon } from 'Common/components';
import CartDrawer from './CartDrawer';
import CartSubncriptionWrapper from './CartSubncriptionWrapper';
import { useWindowSize } from 'Common/hooks';


export const CartIndex = props => {
    const [showDrawer, setShowDrawer] = React.useState(false);
    // const isTabletOrMobileDevice = useMediaQuery({ query: '(max-device-width: 1224px)' })
    // const isSmallDevice = useMediaQuery({ query: '(max-device-width: 550px)' })

    const [screenSize] = useWindowSize();
    const isSmallDevice = screenSize.width < 550;


    const { settings, user, cart } = props;
    const totalItems = cart && cart.items ? cart.items.length : 0

    return(<>
        <Button shape="round" theme="light-gray" size="large" onClick={() => setShowDrawer(!showDrawer)}>
            <Badge count={totalItems}><Icon style={{ fontSize: '1.5em' }} color="#008D84" icon="cart-arrow-down" /></Badge>
            {/* <span style={{marginLeft:"5px"}}>{settings.currency} {Number(cart.totalPrice || 0).toFixed(2)}</span> */}
            <Icon style={{marginLeft:"3px"}} icon="chevron-down" />
        </Button>

        {user && user._id && <CartSubncriptionWrapper visible={showDrawer} onClose={() => setShowDrawer(false)} isSmallDevice={isSmallDevice} />}
        {(!user || !user._id) && <CartDrawer visible={showDrawer} onClose={() => setShowDrawer(false)} isSmallDevice={isSmallDevice} />}
    </>)

}

const mapStateToProps = state => {
    return ({ 
        user: state?.grocer_storefront?.user,
        cart: state.cart, 
        settings: state.grocer_storefront 
    });
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     logoutUser: () => dispatch(logoutUser()),
//     updateUser: (payload) => dispatch(updateUser(payload)),
// })
export default connect(mapStateToProps)(CartIndex);
