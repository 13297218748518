import { sleep } from 'Common/scripts/Functions';
import { message } from 'antd';
import React, { useState, useEffect } from 'react'
import { withApollo } from 'react-apollo'
import { loader } from 'graphql.macro';
import { connect } from "react-redux";
import { __error } from 'Common/scripts/consoleHelper';
import { set_delivery_charges } from '../redux/actions'
import { calculateCouponDiscount } from '../checkout_lib';

const InvoicePreviewComp = ({ cart, client, order, on_set_delivery_charges, settings: { currency } }) => {
    const { coupon } = order;

    const { d_message, d_details, grandTotal, discount_value } = calculateCouponDiscount({ order, cart, currency })

    // var grandTotal = cart && cart.grandTotal || 0;
    // var discount_value = 0;
    // let deliveryCharges = Number(order.deliveryCharges || 0);

    // if (coupon) {
    //     if (coupon.category =="delivery"){
    //         if (coupon.discount_type == 'fix' && deliveryCharges < coupon.discount_value) discount_value = deliveryCharges - Number(coupon.discount_value);
    //         if (coupon.discount_type == '%' && deliveryCharges > 0) discount_value = (Number(deliveryCharges) / 100) * Number(coupon.discount_value);
    //     }
    //     else{
    //         if (coupon.discount_type == 'fix') discount_value = coupon.discount_value;
    //         if (coupon.discount_type == '%') discount_value = (Number(cart.totalPrice) / 100) * Number(coupon.discount_value);
    //         // grandTotal = Number(grandTotal - discount_value).toFixed(2)
    //     }
    // }

    // grandTotal = Number(grandTotal - discount_value)
    // grandTotal = Number(Number(grandTotal) + Number(order.deliveryCharges));

    return (<div>
        <table className="invoice-total">
            <tbody>
                <tr>
                    <td>Tax</td>
                    <td>{currency} {Number(cart.totalTax).toFixed(2)}</td>
                </tr>
                <tr>
                    <td>Sub Total</td>
                    <td>{currency} {Number(cart.totalPrice).toFixed(2)}</td>
                </tr>
                <tr>
                    <td>Delivery Charges</td>
                    <td>{`${currency} ${Number(order.deliveryCharges || 0).toFixed(2)}`}</td>
                </tr>
                {coupon && <tr style={{ fontWeight:"bold", color:"green" }}>
                    <td>{d_message || ""}</td>
                    <td>{d_details || ""}</td>
                </tr>}

                {/* {coupon &&
                    <tr style={{ fontWeight:"bold", color:"green" }}>
                        <td>Discount
                            ({coupon.discount_type == 'fix' && currency} {coupon.discount_value} {coupon.discount_type == '%' && '%'})
                        </td>
                        <td>- {currency} {Number(discount_value).toFixed(2)}</td>
                    </tr>
                } */}
            </tbody>
            <tfoot>
                <tr>
                    <td>Total Payable</td>
                    <td>
                        {currency} {grandTotal.toFixed(2)}
                    </td>
                </tr>
            </tfoot>
        </table>

        {cart.total_savings > 0 &&
            <p style={{ color: "green", textAlign: "center", padding: "10px", fontSize: "18px" }}>You saved <b>{currency} {cart.total_savings}</b> on this order </p>
        }

    </div>)
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    on_set_delivery_charges: (payload) => dispatch(set_delivery_charges(payload)),
})
const mapStateToProps = state => {
    return ({
        settings: state.grocer_storefront,
        order: state.order,
        cart: state.cart,
        user: state?.grocer_storefront?.user,
    });
}
export default connect(mapStateToProps, mapDispatchToProps)(withApollo(InvoicePreviewComp));
