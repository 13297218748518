import React, { Component, useState, useEffect, useMemo, useRef } from 'react'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import ProductListScroller from './ProductListScroller'


function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) =>
                setIsIntersecting(entry.isIntersecting),
            ),
        [],
    );

    useEffect(() => {
        // disable observer if element is in view once
        if (isIntersecting){
            observer.disconnect();
            return;
        }

        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref, observer, isIntersecting]);

    return isIntersecting;
}


export const StoreListItem = ({ store }) => {
    const ref = useRef();
    const isInViewport = useIsInViewport(ref);

    return (<div className='list-item' ref={ref}>

        <Row align="top" gutter={[10]} style={{ flexWrap: "nowrap" }}>
            {store.logo_thumb && <Col><img src={store.logo_thumb} style={{ width: "80px", maxHeight: "80px" }} /></Col>}
            <Col flex="auto">
                <Row align="middle">
                    <Col flex="auto"><div style={{ fontSize: "24px" }}><Link to={`/store/${store.slug}`}>{store.title}</Link></div></Col>
                    <Col><Link to={`/store/${store.slug}`}>View all products</Link></Col>
                </Row>
                
                <div>{store.description}</div>
            </Col>
        </Row>
        <div style={{height:"10px"}} />

        {isInViewport && <ProductListScroller limit={10} filter={{ store_id: Number(store._id), featured: "yes", status:"published", store_status: { $ne: 'offline' } }} />}

    </div>)

}
