import React from 'react'
import PropTypes from 'prop-types';
import { graphql, useLazyQuery, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import moment from 'moment'
import { connect } from "react-redux";
import { Button, Loader, DevBlock, Icon } from 'Common/components';
import TimeSlotSelectorPage from 'Layout_v1/Pages/TimeSlotSelector';
import { utcToDate } from 'Common/scripts/Functions'
import { __error, __yellow } from 'Common/scripts/consoleHelper'
import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
import { timeslotSelect } from '../redux/actions'
import { Alert, message } from 'antd';
import { gql } from 'graphql.macro';


// const LIST_DATA = loader('src/graphqls/delivery_slot/delivery_slots.graphql');
const QUERY_SUBSCRIPTION = loader('src/graphqls/delivery_slot/subscription.graphql');
const GET_QTYS = loader('src/graphqls/delivery_slot/getTotalOrdersForSlots.graphql');

// const GET_STORE = gql`
//     query store($id: ID!) {
//       store(id: $id) {
//         _id
//         title
//         availability_days
//         status
//       }
//     }`

const GET_TIMESLOT_DATA = gql`
    query slot_data($store_id: ID!, $slot_filter: String, $settings_filter: String) {

        store(id: $store_id) {
            _id
            title
            availability_days
            status
        }

        deliverySlots(filter: $slot_filter) {
            _id
            start_time
            end_time
            time_range_utc
            day
            days
            blocked_dates
            max_order_limit
            totalOrders 
            error{ message }
        }

        settings(filter: $settings_filter) {
            _id
            tooltip
            title
            value
            code
            cat
            sub_cat
            sort_order
            type
        }

    }`


const TimselotSelectionTimeout = 1000 * 60 * 5; // 5 min


class TimeSlotSelector_Page extends React.Component {
    state = { busy: false, loading:false, data: null, currentDate: moment(), selectedSlot: null, pageConfigs:null };
    slotClock = null;
    constructor(props){
        super(props);
        this.fetchSlotData = this.fetchSlotData.bind(this);
    }

    columns = [
        { title: ' ', dataIndex: 'time_range' },
    ];//.map(item => ({ ...item, width: "200px" }));

    // shouldComponentUpdate(nextProps, nextState){
    //     const { subscribeToMore } = nextProps;

    //     if (!this.subscription) {
    //         this.subscription = new SubscriptionHandler({
    //             _subscribeToMore: subscribeToMore,
    //             _document: QUERY_SUBSCRIPTION,
    //             _variables: { filter: "" },
    //             _subscriptionName: "deliverySlotsUpdated",
    //             _subscriptionType: "simple-array",
    //             _queryName: "deliverySlots",
    //             _typename: "DeliverySlot",
    //             debug: true
    //         });
    //     }

    //     if (nextProps.order.timeslot && nextProps.order.timeslot != null && nextProps.order.timeslot != this.props.order.timeslot){ 
    //         this.stopSlotClock();
    //         this.startSlotClock();
    //     }

    //     return true;
    // }

    // componentWillReceiveProps(nextProps) {
    //     const { subscribeToMore } = nextProps;

    //     if (!this.subscription) {
    //         this.subscription = new SubscriptionHandler({
    //             _subscribeToMore: subscribeToMore,
    //             _document: QUERY_SUBSCRIPTION,
    //             _variables: { filter: "" },
    //             _subscriptionName: "deliverySlotsUpdated",
    //             _subscriptionType: "simple-array",
    //             _queryName: "deliverySlots",
    //             _typename: "DeliverySlot",
    //             debug: true
    //         });
    //     }

    //     if (nextProps.order.timeslot && nextProps.order.timeslot != null && nextProps.order.timeslot != this.props.order.timeslot){ 
    //         this.stopSlotClock();
    //         this.startSlotClock();
    //     }
    // }

    // componentDidUpdate(nextProps) { }

    componentWillUnmount(){
        this.stopSlotClock();
    }

    componentDidMount(){
        if (this.props.settings.default_timezone) moment.tz.setDefault(this.props.settings.default_timezone);
        this.fetchSlotData()
        this.startSlotClock();
    }

    startSlotClock = () => {
        this.slotClock = setInterval(() => this.refetchSlots(), TimselotSelectionTimeout);
        // this.slotClock = setInterval(() => this.deselectSlot(), TimselotSelectionTimeout);
    }
    stopSlotClock = () => this.slotClock ? clearInterval(this.slotClock) : null;

    refetchSlots(){
        this.deselectSlot();
        // this?.props?.loadMoreRows().then(r => {
        //     this.stopSlotClock();
        //     this.startSlotClock();
        //     return r;
        // });
    }

    async fetchSlotData(){
        const { cart } = this.props;
        this.setState({ loading:true })

        let resutls = await this.props.client.query({ query: GET_TIMESLOT_DATA, 
            variables: {
                store_id: Number(cart.store._id),
                slot_filter: JSON.stringify({}),
                settings_filter: JSON.stringify({
                    store_id: Number(cart.store._id),
                    cat: "store-settings"
                }),
            },
            fetchPolicy: "cache-first",
        })
        .then(r => (r.data))
        .catch(err=>{
            console.log(__error("ERROR: "), err)
            return { error:{message:"Invalid resutls"}}
        })


        if (!resutls || resutls.error){
            console.log(__error((resutls && resutls?.error?.message) || "Invalid result"))
            this.setState({ loading: false })
            return;
        }

        let store_settings = {}
        if (resutls.settings) resutls.settings.forEach(s => {
            Object.assign(store_settings, { [s.code]: s.value })
        });

        let res = {
            store: resutls.store,
            deliverySlots: resutls.deliverySlots,
            store_settings, //: data.settings,
        }

        this.setState({ slot_data:res, loading:false })

    }

    deselectSlot(){
        this.props.timeslotSelect(null);
        this.stopSlotClock();
    }
    onSlotClick = args => {
        // console.log("onSlotClick: ", args);
        const data = {
            _id: args._id,
            date: args.date,
            day: args.day,
            start_time: args.start_time,
            end_time: args.end_time,
            time_range_utc: args.time_range_utc,
            max_order_limit: args.max_order_limit,
        }
        this.props.timeslotSelect(data);
        // this.startSlotClock()
    }

    onDateChange = new_date => {
        // console.log("onDateChange()", new_date.format("DD-MM-YYYY"));
        this.setState({ currentDate: new_date });
    }

    getWeek = async week => {
        let dataRows = this.createDataRows(week);
        let dataColumns = this.createColumns(week);

        let slotQueries = []
        dataRows = dataRows.map(d_slot=>{

            let raw = d_slot.raw.map(o=>{
                let time_range_utc = JSON.parse(o.time_range_utc);
                let t1 = moment(time_range_utc[0], "DD-MM-YYYYTHH:mm").format('HH:mm');
                let t2 = moment(time_range_utc[1], "DD-MM-YYYYTHH:mm").format('HH:mm');
                let col = dataColumns.find(d => d.dataIndex==o.day);
                let col_date = utcToDate(col.date).format('YYYY-MM-DD ');

                if (slotQueries.indexOf(col_date + t1) == -1)
                    slotQueries.push(col_date + t1);

                return { 
                    ...o, 
                    this_slot_start: col_date+t1,
                    this_slot_end: col_date+t2,
                }
            })

            return { ...d_slot, raw }
        })


        // query Db for orders count
        const qtys = await this.props.client.query({
            query: GET_QTYS,
            variables: { times: slotQueries },
            fetchPolicy: "no-cache",
            // reducer: (previousResult, action, variables) => {
            //     console.log('reducer!!!!!', previousResult, action, variables);
            // }
        }).then(data => {
            // console.log("data: ", data.data.getTotalOrdersForSlots)
            if (data.data.getTotalOrdersForSlots.error){
                console.log(__error(data.data.getTotalOrdersForSlots.error.message));
                message.error(data.data.getTotalOrdersForSlots.error.message);
                return false;
            }
            return data.data.getTotalOrdersForSlots.results;
        }).catch(err => {
            console.log(__error("Error: "), err)
            return false;
        });


        // add counts to records
        dataRows = dataRows.map(row=>{
            let _row = { ...row }

            row.raw.forEach(__r => {
                let this_slot_start =__r.this_slot_start;
                let _day = __r.day
                let thisDay = row[_day]

                let thisQty = qtys.find(o => o.start_time == this_slot_start);
                    Object.assign(thisDay, { totalOrders: thisQty ? thisQty.cnt : 0 })            
                
                _row[_day] = thisDay;
            });

            return _row;

        })

        // console.log("dataRows: ", dataRows)

        return { dataColumns, dataRows };
    }

    createColumns = (week = 1) => { // create columns
        
        let now = utcToDate(); //moment_tz();
        // const dayOfyear = now.dayOfYear()
        // const monthDate = this.state.currentDate.startOf('month');
        // const daysInMonth = monthDate.daysInMonth();
        // const targetWeek = monthDate.week() + (week - 1);
        
        let columns = [{ title: ' ', dataIndex: 'time_range', }];

        now.add((week - 1), 'weeks');

        for (let i = 0; i < 7; i++){
            let nextDate = now.add(i <1 ? 0 : 1, 'days')

            let same_day = moment(nextDate.format("YYYY-MM-DD")).isSame(utcToDate().format("YYYY-MM-DD") )
            let isTomorrow = moment(nextDate.format("YYYY-MM-DD")).isSame(utcToDate().add(i < 1 ? 0 : 1, 'days').format("YYYY-MM-DD") )

            columns.push({
                title: same_day ? `Today ${nextDate.format("DD")}` : isTomorrow ? `Tomorrow ${nextDate.format("DD")}` : nextDate.format("ddd MMM DD"),
                dataIndex: nextDate.format("ddd").toLocaleLowerCase(),
                date: nextDate.format(),
            })
        }

        return columns;
    }

    createDataRows = () => {
        console.log(__yellow("createDataRows()"))
        // timeslot_days_to_show
        const { slot_data } = this.state;
        if (!slot_data || !slot_data.deliverySlots) return [];


        // group by time
        let timeGroups = []
        let _deliverySlots = slot_data.deliverySlots.map(element => {
            let slot = `${element.start_time}${element.end_time}`;
            if (timeGroups.indexOf(slot) < 0) timeGroups.push(slot)
            return { ...element, slot: slot };
        });


        let rows = timeGroups.map((slot, i) => {
            let filteredArray = _deliverySlots.filter(word => word.slot == slot);

            if (filteredArray && filteredArray[0]) {
                let cols = { raw: filteredArray };
                filteredArray.forEach(element => {
                    cols[element.day] = element;
                });

                let time_range_utc = JSON.parse(filteredArray[0].time_range_utc);
                let t1 = moment(time_range_utc[0], "DD-MM-YYYYTHH:mm").format('hh:mm A');
                let t2 = moment(time_range_utc[1], "DD-MM-YYYYTHH:mm").format('hh:mm A');
                return { ...cols, time_range: `${t1} to ${t2}`, key:i};
            }
            
            return filteredArray;
        });

        return rows;
        // const result = arr.filter(word => word[index] == value);

    }
    
    render() {
        const { order } = this.props;
        const { currentDate, selectedSlot, slot_data, loading, busy } = this.state;

        if (loading) return <Loader loading={true} center />
        if (slot_data && !(slot_data.store && slot_data.store.availability_days)) return <Alert message="Sorry we are not taking any orders at the moment" showIcon type='error' />

        const pageConfigs = {
            availability_days: slot_data && slot_data.store && slot_data.store.availability_days,
            selectedSlot: order.timeslot,
            onSlotClick: this.onSlotClick,
            onNextClick: this.onNextClick,
            onPrevClick: this.onPrevClick,
            // onDateChange: this.onDateChange,
            getWeek: this.getWeek,
            store_settings: slot_data && slot_data.store_settings,
            // ...this.getWeek(),
            // dataRows: [{ sat: "yes" }, { sun: "yes" }],
            // dataRows: this.createDataRows(),
            // dataColumns: this.createColumns(),
            // data: this.getSlots(), //this.state.data,
            // columns: this.getDays(), //this.columns,
            // defaultDate: currentDate,
            loading: busy || loading,
        }

        return <>
            {/* <p>{store.availability_days.toString()}</p> */}
            {/* <div>Current Date: {utcToDate().format("ddd MMM DD ~ hh:mm A")}</div> */}
            {/* <h4>{utcToDate().format()}</h4> */}
            <TimeSlotSelectorPage {...pageConfigs} />
        </>
        
    }

}
TimeSlotSelector_Page.propTypes = {
    something: PropTypes.string,
}


const mapStateToProps = (args) => {
    const { grocer_storefront, order, cart } = args;
    return ({ 
        settings: grocer_storefront, 
        order,
        cart
    });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    timeslotSelect: (payload) => dispatch(timeslotSelect(payload)),
})
const WithRedux = connect(mapStateToProps, mapDispatchToProps)(withApollo(TimeSlotSelector_Page));
export default withApollo(WithRedux);
