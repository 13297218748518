import React from 'react'
import PropTypes from 'prop-types';
// import { graphql, withApollo } from '@apollo/client/react/hoc';
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { connect } from "react-redux";
// import { Link, Redirect } from 'react-router-dom';
import { Row, Col, Modal, message, Alert, Steps } from 'antd';
// import { ProductList, RelatedCategoriesMenu } from 'Layout_v1';
import { Icon, Button, Heading } from 'Common/components'
import { FormComponent, FormField, FormFieldGroup, rules, composeValidators } from 'Common/components/Form'
import { genders } from 'configs/constants';
// import { config } from 'process';
import { __error } from 'Common/scripts/consoleHelper'
import { defaultCountryCode } from 'configs';

const REG_CLIENT = loader('src/graphqls/user/registerClient.graphql');


export const RegisterForm = props => {
    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const handelResponse = async (registrationResponse) => {
        const {
            error,
            // user: User
            // address: Address
            autoLoginInfo
        } = registrationResponse;

        if (error) {
            console.log(__error("ERROR: "), registrationResponse);
            setErrorMessage(error.message)
            // message.error(error.message);
            setLoading(false);
            return;
        }

        message.success("Registration successful");

        props.onSuccessCallback(autoLoginInfo ? JSON.parse(autoLoginInfo) : autoLoginInfo);

    }

    const onSubmit = values => {
        // console.log("onSubmit: ", values);
        setErrorMessage(null);
        setLoading(true);

        let filteredValues = {
            fname: values.fname,
            gender: values.gender,
            email: String(values.email).trim(),
            password: String(values.password).trim(),
            phone: String(values.phone).trim(),
            receive_offers: values.receive_offers ? 1 : 0,
            // address: props.locationInfo
        }

        props.registerClient(filteredValues).then(r => {
            handelResponse(r.data.registerClient);
        }).catch(erro => {
            console.log(__error("Request Failed!"));
            setErrorMessage("Request Failed!")
            setLoading(false);
        })

    }

    return (<div align="center">
        <FormComponent onSubmit={onSubmit} id='RegistrationForm' loading={loading} hideDevBlock={true} 
            style={{ maxWidth: "350px", width: "100%", textAlign: "left", marginBottom: "20px" }}
            fields={{ gender: "male", }}><>

            {errorMessage && <Alert message={errorMessage} type={"error"} showIcon />}

            <Row gutter={[10]}>
                <Col><FormField data={genders} type="select" name="gender" label="Gender" validate={rules.required} compact /></Col>
                <Col flex="auto"><FormField type="text" name="fname" label="Name" validate={rules.required} compact /></Col>
                <Col span={24}><FormField type="text" name="email" label="Email Address (Login Use)" validate={composeValidators(rules.required, rules.isEmail)} compact /></Col>
                <Col span={24}><FormField type="password" name="password" label="Password" validate={composeValidators(rules.required, rules.minChar(8))} compact /></Col>
                <Col span={24}>
                    <FormField compact
                        placeholder={`+${defaultCountryCode} XXX XXX XXX`}
                        name="phone"
                        // +260 970 291 010
                        mask={`+\\${defaultCountryCode} 999-999-999`} type="mask"
                        label="Mobile Number (verification will require)"
                        validate={composeValidators(
                            rules.required,
                            rules.minChar(13, (val) => val.replace(/_/, "")),
                            rules.maxChar(13, (val) => val.replace(/_/, "")),
                        )}
                    />
                </Col>
                <Col span={24}><FormField type="checkbox" name="agree" label={<>I've read and accepted the <a href="/page/terms_and_conditions" target='_blank' className="green-link">Terms & Conditions</a></>} validate={rules.required} compact /></Col>
                <Col span={24}><Button type="primary" htmlType="submit" block size="large">Send Email Verification Code</Button></Col>
            </Row>

        </></FormComponent>
    </div>)
}

RegisterForm.propTypes = {
    onSuccessCallback: PropTypes.func.isRequired,
    locationInfo: PropTypes.object.isRequired
}

const WithApollo = compose(

    graphql(REG_CLIENT, {
        props: ({ mutate }) => ({
            registerClient: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),

)(RegisterForm);

const mapStateToProps = state => {
    return ({ 
        user: state?.grocer_storefront?.user,
    });
}
export default connect(mapStateToProps)(WithApollo);

// export default WithApollo;