import React, { Component, Image } from 'react'
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import { Row, Col, Modal, message, Divider, Alert } from 'antd';
import { connect } from "react-redux";
import { Icon, Button, IconButton, Heading, Loader, DevBlock, LocationSelection } from 'Common/components'
import { FormComponent, FormField, FormFieldGroup, rules, composeValidators } from 'Common/components/Form'
import { __error, __blue } from 'Common/scripts/consoleHelper'
import { GoogleMap, useJsApiLoader, Autocomplete, LoadScript, useLoadScript, LoadScriptNext, useGoogleMap, Marker } from '@react-google-maps/api';
import { GOOGLE_API_KEY } from 'configs/constants';

const GET_ZONES = loader('src/graphqls/geo_zone/geoZoneByCity.graphql');

const librariesArray = ["places", "geometry"] //drawing,geometry,localContext,places,visualization

const default_field_values = {
    // center: default_city.coordinates, //serviceCities[0].coordinates, //{ lat: default_map_center.lat, lng: default_map_center.lng },
    // city: default_city._id,
    label: 'home',
}

const MyLocationButton = ({ onClick, loading }) => {
    return (
        <div onClick={onClick} className="bt-my-location" style={{ bottom: 180, right: 10 }}>
            {!loading && <span style={{ border: "0px solid black", paddingTop: "5px" }}><Icon icon="my-location" /></span>}
            {loading && <Loader loading={true} />}
        </div>
    )
}


class LocationForm extends Component {
    theShape = null;
    polyArray = [];

    constructor(props) {
        super(props);
        this.autocomplete = null

        this.onAutoCompleteLoad = this.onAutoCompleteLoad.bind(this)
        this.onPlaceChanged = this.onPlaceChanged.bind(this)
        this.onMapUnmount = this.onMapUnmount.bind(this)
        this.onMapLoad = this.onMapLoad.bind(this)
        this.onCityChange = this.onCityChange.bind(this)
        this.checkLocationBounds = this.checkLocationBounds.bind(this)
        this.getUserGPSLocation = this.getUserGPSLocation.bind(this)
        this.onUserLocationReceived = this.onUserLocationReceived.bind(this)
        this.moveToCity = this.moveToCity.bind(this)
        this.setCenter = this.setCenter.bind(this)

        var city;// = { ...default_city }
        const fields = this.props.userAddress ? { ...this.props.userAddress } : { ...default_field_values }

        if (fields._id) {
            city = {
                ...fields.location,
                // title: fields.location.title,
                _id: fields.location.code,
                coordinates: { lat: fields.location.coordinates[0].lat, lng: fields.location.coordinates[0].lng }
            }
            Object.assign(fields, {
                center: {
                    lat: fields.geo_point.coordinates[0],
                    lng: fields.geo_point.coordinates[1],
                },
            })
        }

        this.state = {
            loading: this.props.loading,
            map: null, google: null,
            isInBounds: false,
            fields,
            curserUpdated: false,
            loadingUserGPS: false,
            city,
            options: {
                zoom: 12,
                fullscreenControl: true,
                // mapContainerStyle: { height:"400px", width:"100%" },
                // clickableIcons: false,
                // mapTypeControl: false,
                // panControl: false,
                // streetViewControl: false,
            },
        }
    }

    componentWillUnmount() {
        this.onMapUnmount()
    }

    onMapUnmount() {
        this.autocomplete = null;
        this.setState({ map: null })
    }

    updateOptions(opts) {
        const { options } = this.state;
        const _options = Object.assign({ ...options }, { ...opts });
        this.setState({ options: _options })
    }

    onAutoCompleteLoad = (_autocomplete) => {
        this.autocomplete = _autocomplete
    }

    onPlaceChanged = () => {
        if (this.autocomplete !== null) {
            const _places = this.autocomplete.getPlace();
            if (!_places || !_places.geometry) return;

            this.setState({ fields: { ...this.state.fields, center: { lat: _places.geometry.location.lat(), lng: _places.geometry.location.lng() }} })
        } else {
            console.log('Autocomplete is not loaded yet!')
        }
    }

    onMapLoad(map) {
        this.setState({ google: window.google, map }, ()=>{
            this.onCityChange(this.state.city).then(r=>{
                if (!this.state?.fields?._id) this.getUserGPSLocation()
                if (this.state.fields.center) this.checkLocationBounds(this.state.fields.center)
            });
        })
    }

    resetPolygon() {
        this.polyArray.forEach(poly => {
            poly.setMap(null);
        })

        this.polyArray = []
    }

    setShape(paths) {
        if (!paths) return;
        const { google, map } = this.state;

        const theShape = new google.maps.Polygon({
            paths,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.05,
        });
        theShape.setMap(map);

        this.polyArray.push(theShape);
    }

    checkLocationBounds(pointCenter) {
        const { google, map, options, fields } = this.state;

        if (!pointCenter.lat || !pointCenter.lng){
            console.log(__error("Center not found"));
            return alert("Center not found!");
        }

        const point = new google.maps.LatLng(pointCenter.lat, pointCenter.lng);
        var isInBounds = false;

        this.polyArray.forEach(poly => {
            var bounds = new google.maps.LatLngBounds();
            var city_bounds = new google.maps.LatLngBounds();
            poly.latLngs.getArray().forEach(function (path) {
                path.getArray().forEach(function (latLng) {
                    bounds.extend(latLng);
                    city_bounds.extend(latLng);
                })
            });

            let _isInBounds = google.maps.geometry.poly.containsLocation(point, poly)
            if (_isInBounds) isInBounds = _isInBounds;
        })

        this.setState({ isInBounds })

    }

    async onCityChange(city) {
        // const { google } = this.state;
        if (!city) return alert("No city provided!")

        this.resetPolygon();
        return this.loadRelatedZones(city)
    }

    async loadRelatedZones(city){
        const city_zones = await this.props.client.query({ query: GET_ZONES,
            variables: { city: String(city.code), filter: JSON.stringify({ status: "published", category: 'service-area' }) },
        }).then(r => (r.data.geoZoneByCity)).catch(err => {
            console.log(__error("ERROR: "), err);
            return { error:{ message:"Query Error!" } }
        });

        // set new city zones
        if (!city_zones || city_zones.error || city_zones.length < 1) {
            // console.log(__error(`Sorry! We are not surving in city (${city._id}) yet`), city_zones);
            return alert(`Sorry! We are not surving in city (${city.title}) yet`);
        }
        
        city_zones.forEach(zone => {
            let coords = zone?.polygon?.coordinates[0];
            let _shape = coords.map(item => {
                return new this.state.google.maps.LatLng(item[0], item[1])
            });
            this.setShape(_shape);
        })

        this.fitBounds();
        return city_zones;
    }

    fitBounds() {
        if (this.state?.fields?._id) return;
        const { google, map, options } = this.state;

        this.polyArray.forEach(poly => {
            var city_bounds = new google.maps.LatLngBounds();

            poly.latLngs.getArray().forEach(function (path) {
                path.getArray().forEach(function (latLng) {
                    city_bounds.extend(latLng);
                })
            });

            map.fitBounds(city_bounds)
        })

    }

    onSubmit = async (values) => {
        const { fields, isInBounds } = this.state;

        if (!isInBounds) return alert("Sorry you are out of service area!")
        
        const input = {
            full_address: values.full_address,
            city: values.city,
            label: values.label,
            // geoCoords: {
            //     lat: center.lat,
            //     lng: center.lng,
            //     latitudeDelta: center.latitudeDelta || 0.001,
            //     longitudeDelta: center.longitudeDelta || 0.001,
            // },
            geo_point: {
                type: 'Point',
                coordinates: [values.center.lat, values.center.lng]
            },
            delivery_instructions: values.delivery_instructions,
            // verified: String 
        }
        if (fields?._id) Object.assign(input, { _id: Number(fields._id) })

        const resutls = this.props.onSubmit(input).then(r => (r)).catch(err => {
            console.log(__error("Unexpected error! "), err)
            return { error: { message:"Unexpected error!" } }
        })

        if (resutls.error){
            message.error(resutls.error.message);
            return false;
        }
        return true;
    }

    // Get User GPS Location
    getUserGPSLocation = async () => {
        // if navigator?.geolocation not available then set the city location
        if (!navigator || !navigator.geolocation) {
            this.onCityChange(this.state.city);
            return;
        }


        this.setState({ loadingUserGPS: true });


        // if user have no permissions to read GPS, load city location
        const havePermisions = await navigator.permissions.query({ name: 'geolocation' })
        // console.log("havePermisions: ", havePermisions)
        if (havePermisions.status == 'denied') {
            this.setState({ loadingUserGPS: false })
            message.error("No permissions to read GPS location")
            this.onCityChange(this.state.city);
            return;
        }

        // if (havePermisions.status == 'prompt') {
        //     alert("GPS promp")
        //     // this.setState({ loadingUserGPS: false })
        //     message.error("gps prompt")
        //     // this.onCityChange(this.state.city);
        //     return;
        // }

        // handel gps request
        navigator.geolocation.getCurrentPosition(
            position => {
                // this.setState({ loadingUserGPS: true });
                this.onUserLocationReceived(position)
            },
            error => {
                this.setState({ loadingUserGPS: false })
                console.log(__error("ERROR: "), error);
                message.error("Unable to read your GPS location!");
                this.onCityChange(this.state.city);
            },
            { maximumAge: Infinity, timeout: 3000 }
        );

    }

    onUserLocationReceived(position) {
        const { city } = this.state;
        const pointCenter = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
        }
        this.setState({ loadingUserGPS: false })

        this.setState({ loading: true, city }, () => {
            this.moveToCity(city, pointCenter);
            this.resetPolygon();
            this.loadRelatedZones(city);

            this.setState({ loading: false });
        });
    }

    moveToCity(_city, center) {
        // console.log(__blue(`moveToCity()`), { _city, center });

        this.setState({
            fields: {
                ...this.state.fields,
                center: center || (this.state?.fields?._id ? this.state.fields.center : _city.coordinates),
                city: _city.code,
            }
        }, () => {
            this.setCenter(this.state.fields.center)
            // this.setCenter(_city.coordinates);
        })

    }

    setCenter(centerPoint) {
        if (this.state.map) this.state.map.setCenter(centerPoint);
        else console.log(__error("map not found"))
    }




    render() {
        const { loading, options, map, fields, loadingUserGPS } = this.state;

        return (<div className="h-center" style={{ width: '100%' }}>
            <div style={{ width: '100%' }}>
                <div style={{ padding:"10px 20px 0 20px" }}>
                    {loading && <div style={{width:"100%", height:"100%", backgroundColor:"rgba(255, 255, 255, 0.5)", position:'absolute', top:0, left:0}}><Loader loading={true} /></div>}
                    <h3 style={{ textAlign: "center" }}>{fields && fields._id ? 'Edit' : 'Select'} your location</h3>
                </div>

                <FormComponent onSubmit={this.onSubmit} id={`UserLocationForm_${fields && fields._id}`} fields={fields} debug={false}
                    mutators={{
                        onCitySelect: (newValue, state, utils) => {
                            // if (!this?.state?.google?.maps) return;
                            let node = {...newValue[0]};

                            this.setState({ city:node }, () => {
                                if (this.state.google) this.onCityChange(node)
                            });

                            utils.changeValue(state, 'city_dd', () => node)
                            utils.changeValue(state, 'center', () => ({ lat: node.coordinates[0].lat, lng: node.coordinates[0].lng }))
                        },
                        onPointerChange: (newValue, state, utils) => {
                            let node = { ...newValue[0] };
                            const pointCenter = { lat: node.latLng.lat(), lng: node.latLng.lng() };
                            this.checkLocationBounds(pointCenter);
                            this.setState({ curserUpdated:true })

                            utils.changeValue(state, 'center', () => pointCenter);
                        },
                    }}
                    form_render={({ values, form, errors, dirty, submitFailed, submitting, valid })=>{
                        return(<>
                            <div style={{ padding: "0 10px" }}>
                                {(values.city && !this.state.isInBounds && this.state.curserUpdated) && <Alert message="Sorry! You are out of service area" type="warning" showIcon />}
                                <LocationSelection
                                    onChange={(v)=>{
                                        form.mutators.onCitySelect(v.raw);
                                        // this.onCityChange({ title: v.raw.title, _id: v.raw.code, coordinates: { lat: v.raw.coordinates[0].lat, lng: v.raw.coordinates[0].lng } })
                                    }}
                                    label={null} placeholder="Select Your City" preload={true} name="city" filter={{ type: 'city' }} validate={composeValidators(rules.required)} />
                            </div>

                            {values.city &&  <>
                                {!this.state.curserUpdated && <Alert type='warning' message="Seems like your delivery location is not accurate, please move the pointer to adjust your location accuracy." showIcon />}

                                <div style={{ border: "1px solid #EEE", height: '400px', position: "relative" }}>
                                    <LoadScript googleMapsApiKey={GOOGLE_API_KEY} libraries={librariesArray}><>
                                        <GoogleMap
                                            zoom={12}
                                            fullscreenControl={true}
                                            mapContainerStyle={{ height:"400px", width:"100%" }}
                                            clickableIcons={false}
                                            mapTypeControl={false}
                                            panControl={false}
                                            streetViewControl={false}
                                            {...options}
                                            // options={{
                                            //     zoom: 12,
                                            //     fullscreenControl: true,
                                            //     mapContainerStyle: { height: "400px", width: "100%" },
                                            //     clickableIcons: false,
                                            //     mapTypeControl: false,
                                            //     panControl: false,
                                            //     streetViewControl: false,
                                            //     ...options
                                            // }}
                                            center={values.center}
                                            mapTypeControl={false}
                                            panControl={false}
                                            streetViewControl={false}
                                            id="address_map"
                                            // onCenterChanged={() => console.log("onCenterChanged()")}
                                            // onClick={(e) => console.log("onClick()", e)}
                                            onDragEnd={this.onDragEnd}
                                            onDragStart={() => console.log("onDragStart()")}
                                            onLoad={this.onMapLoad}
                                            onUnmount={this.onMapUnmount}
                                        ><>
                                            <Marker onDragEnd={(v)=>form.mutators.onPointerChange(v)} position={values.center} draggable={true} />
                                            <MyLocationButton loading={loadingUserGPS} onClick={() => this.getUserGPSLocation()} />
                                        </></GoogleMap>

                                    </></LoadScript>
                                </div>

                                {this.state.isInBounds && 
                                    <div style={{ padding: "0 10px" }}>
                                        <Row>
                                            <Col span={24} md={14}><FormField type="text" name="full_address" placeholder="Full Address" validate={composeValidators(rules.required, rules.minChar(10))} /></Col>
                                            <Col>
                                                <FormField type="radio-button-field" size="large" name="label" data={[
                                                    { title: "Home", _id: 'home' },
                                                    { title: "Office", _id: 'office' },
                                                    { title: "Other", _id: 'other' }
                                                ]}
                                                    validate={composeValidators(rules.required)}
                                                />
                                            </Col>
                                        </Row>
                                        
                                        <FormField type="text" name="delivery_instructions" placeholder="Some extra notes about your address.." />
                                        {/* {errors && errors.label && dirty && submitFailed && <div style={{color:"red"}}>Please select an option from below!</div>} */}
                                        <div style={{ margin: "20px 10px" }}><Button size="large" disabled={!valid || !this.state.isInBounds} loading={submitting} type="primary" htmlType="submit" block>{this.props.buttonLabel || "Save"}</Button></div>
                                    </div>
                                }

                            </>}

                        </>)

                    }}
                />

            </div>
        </div>)

    }


}

const mapStateToAvatarPopProps = state => {
    return ({ 
        user: state?.grocer_storefront?.user,
    });
}
export const WithRedux = connect(mapStateToAvatarPopProps)(LocationForm);
export default withApollo(WithRedux);

// export default WithApollo;
