import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Layout } from 'antd';
// import { useMediaQuery } from 'react-responsive'
// import { Icon, Button, ToggleButton } from 'Common/components';
import { Footer, LayoutHeader, ContentArea } from './';
import { useWindowSize } from 'Common/hooks';
// import LocalStorage from 'Common/scripts/LocalStorage';


export const LayoutWrapper = props => {
    const [pathName, setPathName] = useState('');
    // const [container, setContainer] = useState(null);
    // const isTabletOrMobileDevice = useMediaQuery({ query: '(max-device-width: 1224px)' })
    // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

    const [screenSize] = useWindowSize();
    const isTabletOrMobileDevice = screenSize.width < 1224;


    React.useEffect(() => {
        const { pathname } = window.location;
        setPathName(pathname);
    }, []);

    return (<>
        <LayoutHeader {...props.header} />
        <Layout className={`site-layout ${isTabletOrMobileDevice && 'isMobile'}`}>
            <Layout className="site-layout-content">
 
                {props.skipContentArea && props.children}
                {/* {!props.skipContentArea && <ContentArea>{props.children}</ContentArea>} */}
                <div style={{ display: props?.searchState?.query && !pathName.includes('/search') ? 'none' : '' }}>
                    {!props.skipContentArea && <ContentArea>{props.children}</ContentArea>}
                </div>
            </Layout>
        </Layout>
        <Footer />
    </>)

}

LayoutWrapper.propTypes = {
    menuOverride: PropTypes.array,
    topMenu: PropTypes.array,
    skipContentArea: PropTypes.bool,
    // labelA: PropTypes.oneOfType([
    //             PropTypes.string,
    //             PropTypes.object
    //         ]).isRequired,
}
