// import { persistReducer } from 'redux-persist'
import { __error } from 'Common/scripts/consoleHelper';
// import storage from 'redux-persist/lib/storage'
import { ADD_PRODUCT, REMOVE_PRODUCT, DELETE_PRODUCT, UPDATE_CART, FLUSH_CART } from '../cart.actions';
// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const INITIAL_STATE = JSON.stringify({
            wasTotal: 0,
            total_savings: 0,
            totalPrice: 0,
            grandTotal: 0,
            totalItems: 0,
            totalTax: 0,
            items: []
          })

/*************** Re calculation is required for not logged in user */
export const calculateCart = (cart) => {
 
  const items = (cart && cart.items) ? cart.items.filter(o=>(o._id)) : null;

  let totalItems = (items) ? items.length : 0;
  if (totalItems < 1) {
    return JSON.parse(INITIAL_STATE);
  }

  let data = {
    totalPrice: 0,
    totalTax: 0,
    deliveryCharges: 0,
    grandTotal: 0,
    wasTotal: 0,//redux_cart.items.length;
    total_savings: 0,
    totalItems
  }

  if (totalItems>0){
    items.forEach(item => {
        data.totalPrice += item.price * item.qty;

        if (item.taxable) data.totalTax += item.tax_total * item.qty;

        if (item.price_was && item.price_was>0 && item.price_was > item.price){
          data.total_savings += (item.price_was-item.price) * item.qty;
          data.wasTotal += (item.price_was * item.qty);
        }

      });
  }
  
  data.totalPrice = Number(data.totalPrice).toFixed(2)
  if (data.total_savings > 0) data.total_savings = Number(data.total_savings).toFixed(2)

  data.grandTotal = Number(data.totalPrice) + Number(data.totalTax) + Number(data.deliveryCharges);

  return { ...cart, ...data };
  // return { ...cart, totalItems, data.totalPrice, data.total_savings, data.totalTax, data.deliveryCharges, data.wasTotal }
}


const updateCart = (state, cart) => {
  return calculateCart(cart);
}

const flushCart = (state, nothing_expected) => {
  return JSON.parse(INITIAL_STATE);// { ...INITIAL_STATE }
}

export const cart_reducer = (state = JSON.parse(INITIAL_STATE), {type, payload} ) => {
  switch (type) {
    // case DELETE_PRODUCT:
    //   return deleteProduct(state, payload);
    //   break;
    // case REMOVE_PRODUCT:
    //   return removeProduct(state, payload);
    //   break;
    // case ADD_PRODUCT:
    //   return addProduct(state, payload);
    //   break;
    case UPDATE_CART:
      return updateCart(state, payload);
      break;
    case FLUSH_CART:
      return flushCart(state, payload);
      break;
    default:
      return state;
  }
}

export default {cart:cart_reducer};

// const pConfig = {
//   key: 'cart',
//   storage,
//   blacklist: ['somethingTemporary'],
//   whitelist: ['cart'],
//   // stateReconciler: autoMergeLevel2,
//   // stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
// }
// export default persistReducer(pConfig, cart_reducer);
