import { TIMESLOT_SELECT, ADDRESS_SELECT, COUPON_SELECT, ORDER_NOTE, STATUS_UPDATE, ORDER_FLUSH, SET_DELIVERY_CHARGES } from './actions';
import { __error } from 'Common/scripts/consoleHelper';

const INITIAL_STATE = { timeslot: null, address: null, coupon: null, note:null, status:null };

const timeslotSelect = (state, payload) => {
  return { ...state, timeslot:payload }
}
const addressSelect = (state, payload) => {
  return { ...state, address: payload }
}
const couponSelect = (state, payload) => {
  if (!payload || payload.error) return { ...state, coupon:null };
  return { ...state, coupon: payload }
}
const orderNote = (state, payload) => {
  if (!payload || payload.error) return { ...state, note:null };
  return { ...state, note: payload }
}
const statusUpdate = (state, payload) => {
  return { ...state, status: payload }
}
const flushOrder = (state, payload) => {
  console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>> flushOrder()");
  
  return { ...INITIAL_STATE }
}
export const order_reducer = (state = INITIAL_STATE, {type, payload} ) => {
  switch (type) {
    case TIMESLOT_SELECT:
      return timeslotSelect(state, payload);
      break;
    case ADDRESS_SELECT:
      return addressSelect(state, payload);
      break;
    case COUPON_SELECT:
      return couponSelect(state, payload);
      break;
    case ORDER_NOTE:
      return orderNote(state, payload);
      break;
    case STATUS_UPDATE:
      return statusUpdate(state, payload);
      break;
    case ORDER_FLUSH:
      return flushOrder(state, payload);
      break;
    case SET_DELIVERY_CHARGES:
      return { ...state, deliveryCharges:payload }
      break;
    default:
      return state;
  }
}

export default { order: order_reducer};

// const pConfig = {
//   key: 'cart',
//   storage,
//   blacklist: ['somethingTemporary'],
//   whitelist: ['cart'],
//   // stateReconciler: autoMergeLevel2,
//   // stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
// }
// export default persistReducer(pConfig, user_reducer);
