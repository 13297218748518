import { __error } from 'Common/scripts/consoleHelper';
import { UPDATE_SETTINGS, USER_LOGIN, USER_LOGOUT, USER_UPDATE } from '../grocer_storefront.actions.js';
import moment from 'moment';

const INITIAL_STATE = {
  deliveryCharges: 0,
  currency: '',
  min_order_amount: 0, max_order_amount: 0,

  user: null,
  rights: null 
}


const updateSettings = (state, payload) => {
  // console.log("REDUX > updateSettings()", payload);

  let new_State = { ...state, ...payload }

  if (new_State.default_timezone) moment.tz.setDefault(new_State.default_timezone);

  return new_State
}

const loginUser = (state, { token, user, rights }) => {
  // console.log("REDUX > loginUser()", payload);

  if (!token) {
    alert("No user token found!");
    return { ...INITIAL_STATE };
  }
  if (!user || !user._id) {
    alert("No user found!");
    return { ...INITIAL_STATE };
  }

  return {
    token,
    rights,
    user:{
      email: user.email,
      fname: user.fname,
      phone_confirmed: user.phone_confirmed,
      status: user.status,
      type: user.type,
      _id: user._id,
    }
  }
 
}

const logoutUser = (state, payload) => {
  // console.log("REDUX > logoutUser()", payload);
  // console.trace("logoutUser()")

  return { ...INITIAL_STATE }
}

const updateUser = (state, payload) => {
  // console.log("REDUX > updateUser()", payload);

  let _payload = { ...payload }
  delete _payload._id; // do not update user ID
  // let user = Object.assign({ ...state.user, ..._payload });
  return { ...state, user: { ...state.user, ..._payload } };
}

export const grocer_storefront_reducer = (state = INITIAL_STATE, {type, payload} ) => {
  switch (type) {
    case UPDATE_SETTINGS:
      return updateSettings(state, payload);
      break;
    case USER_LOGIN:
      return loginUser(state, payload);
      break;
    case USER_LOGOUT:
      return logoutUser(state, payload);
      break;
    case USER_UPDATE:
      return updateUser(state, payload);
      break;
    default:
      return state;
  }
}

export default { grocer_storefront:grocer_storefront_reducer };
