/* eslint-disable eqeqeq */
import React from 'react';
import _ from 'lodash';
import base64 from 'base-64';
import moment from 'moment';
import moment_tz from 'moment-timezone';
import { __error, __yellow } from './consoleHelper'
import { loader } from 'graphql.macro';
import { defaultTimeZone } from 'configs';

const MERGE_CART = loader('src/graphqls/cart/mergeCart.graphql');

export const mergeCarts = async (cart, client) => {
  if (!cart.items || cart.items.length < 1) return cart;

  let items = cart.items.map(item => ({
    // customer_id: customer_id,
    product_id: item._id,
    qty: item.qty
  }));


  let mearged = await client.mutation({ mutate: MERGE_CART, variables: { items } })
    .then(r => {
      if (r.data.mergeCart.error) console.log(__error("Error in mearning redux cart"), r.data.mergeCart);
      return r.data.mergeCart;
    }).catch(err => {
      console.log(__error("Unable to merge carts."), err);
      return { error: { message: "Unable to merge carts" } };
    });

  return mearged;
}

// export const mergeCarts = async (cart, customer_id, gql_mergeCart) => {
//   if (!cart.items || cart.items.length < 1) return cart;

//   let items = cart.items.map(item => ({
//     customer_id: customer_id,
//     product_id: item._id,
//     qty: item.qty
//   }));

//   let mearged = await gql_mergeCart({ items, customer_id })
//     .then(r => {
//       if (r.data.mergeCart.error) console.log(__error("Error in mearning redux cart"), r.data.mergeCart);
//       return r.data.mergeCart;
//     }).catch(err => {
//       console.log(__error("Unable to merge carts."));
//       return { error: { message: "Unable to merge carts" } };
//     });

//   console.log("mearged: ", mearged);
  
//   return mearged;
// }


export const formToFilter = (fields, {tz}) => {
  let temp;
  let filter = {}
  let keys = Object.keys(fields);

  const translateFilterValue = (val) => {
    if (val instanceof moment) return dateToUtc(val, {tz});
    if (_.isString(val)) return String(val);
    if (_.isInteger(val)) return Number(val);
    if (val.keywords) return val;
    return false;
  }

  keys.forEach(element => {
    let val = fields[element];

    if (!val) { }
    // else if (val.keywords) filter[element] = val
    else if (translateFilterValue(val)) filter[element] = translateFilterValue(val);
    else {
      temp = {};
      if (val.gt) temp = Object.assign(temp, { "$gt": translateFilterValue(val.gt) });
      else if (val.gte) temp = Object.assign(temp, { "$gte": translateFilterValue(val.gte) });
      else if (val.lt) temp = Object.assign(temp, { "$lt": translateFilterValue(val.lt) });
      else if (val.lte) temp = Object.assign(temp, { "$lte": translateFilterValue(val.lte) });
      else {
        console.log(__error("Invalid filter element"), val);
      }
      if (Object.entries(temp).length > 0) filter[element] = temp;
    }
  });
  // console.log("filter: ", filter);
  return filter;
}



/*************************** GENERAL FUNCTIONS *******************
 *
*/
export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const string_to_slug = str => {
  if (!str || str.length < 1) return "";

  //# Ver 1
  // return String(str).replace(/[^a-z0-9]/gi, '-').replace(/-+/g, '-').replace(/^-|-$/g, '').toLowerCase();

  //# Ver 2
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaaaeeeeiiiioooouuuunc------";

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .toString()
    .normalize('NFD')
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // replace whitespace by -
    .replace(/-+/g, '-') // replace dashes
    .replace(/&/g, '-y-') // Replace & with 'and'
    // .replace(/\-\-+/g, '-') // Replace multiple - with single -
    // .replace(/^-+/, "") // trim - from start of text
    // .replace(/-+$/, "") // trim - from end of text
    .trim() // Remove whitespace from both sides of a string

  return str;
}



/*************************** ARRAY FUNCTIONS *******************
 *
*/
export const sortArrayByVal = (arr, name, _order) => {
  let order = _order ? String(_order).toLowerCase() : "asc";
  if (order == 'asc') return arr.sort((a, b) => (a[name] < b[name]) ? 1 : -1)
  return arr.sort((a, b) => (a[name] > b[name]) ? 1 : -1)
}

/*************************** JSON FUNCTIONS *******************
 *
*/
export const jsonStringify = (jsonObj, a=0, b=0) => {
  if(!jsonObj) return jsonObj;
  let result = false;

  try{
            if (typeof jsonObj!=="string"){
              result = JSON.stringify(jsonObj, a, b);
            }else{
                result = false;
            }
  }
  catch (error){
      console.error("ERROR : jsonStringify()", error);
  }

  return result;
}

export const parseJson = (jsonString) => {
  let jsonObject = null;

  if(!jsonString) return jsonObject;

  try{
            if (typeof jsonString!=="string"){
                // return false;
            }else{
                jsonObject = JSON.parse(jsonString);
                // return jsonObject;
            }
  }
  catch (error){
            console.log("ERROR : parseJson()", error);
  }

  return jsonObject;
}

export const decode64 = (str) => {
  let _str = "";
  if(!str) return _str;
  try{
            if (typeof str!=="string"){
                // return false;
            }else{
                _str = base64.decode(str);
                // return jsonObject;
            }
  }
  catch (error){   console.log("ERROR : decode64()", error);   }
  return _str;
}



/**** Image functions ************/
/*
getBase64(File Field Object)
*/
export const getBase64 = (file, as='DataURL') => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // reader.onload = () => resolve(reader);
    reader.onload = () => {
      // console.log("FileReader: ", reader);
      resolve(reader.result)
    }
    reader.onerror = error => reject(error);
  });
}

export const resizeImage = (_image, maxWidth=300, maxHeight=300) => {

    return new Promise(async function(resolve,reject){
      var thumbnailWidth, thumbnailHeight;
      var image = new Image();
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');

      image.onload = function(){
        //Calculate the size of the thumbnail, to best fit within max/width (cropspadding)
        var thumbnailScale = (image.width / image.height) > (maxWidth / maxHeight) ?
        maxWidth / image.width : maxHeight / image.height;
        thumbnailWidth = image.width * thumbnailScale;
        thumbnailHeight = image.height * thumbnailScale;

        // set its dimension to target size
        canvas.width = thumbnailWidth;
        canvas.height = thumbnailHeight;

        // draw source image into the off-screen canvas:
        ctx.drawImage(image, 0, 0, thumbnailWidth, thumbnailHeight);

        //Draw border (optional)
        // ctx.rect(0, 0, thumbnailWidth, thumbnailHeight - 1);
        // ctx.strokeStyle = "#555555";
        // ctx.stroke();

        resolve(canvas.toDataURL('image/png', 70));
        // resolve(canvas.toDataURL('image/jpeg', 70));
        // return canvas.toDataURL('image/jpeg', 70);
      }

      image.src = _image;

    });

}



/*************************** DATE FUNCTIONS ******************* 
 * 
*/

// Asia/Karachi	

// export const dateToMongo = (_date) => {
//   return moment_tz.utc(_date).format();
// }
// export const mongoToDate = (_date, timezone) => {
//   return moment_tz.utc(_date).tz("Asia/Karachi");
// }

export const dateToUtc = (_t, new_options) => {
  let offset1 = _t.utcOffset();
  let offset2 = moment().utcOffset();

  let tz = (new_options && new_options.tz) || defaultTimeZone;

  if (!new_options || !new_options.tz) {
    console.log(__error("Missing target timezone"));
    alert("Timezone not provided")
  }

  let options = {
    returnAs: "string",
    ...new_options,
    tz
  }

  // let selectedTzOffset = moment.tz(tz).utcOffset();
  // let currentTzOffset = moment.tz(moment.tz.guess()).utcOffset();

  // Replace timezone to CA
  var updatedTimezone = moment.tz(_t.format("DD-MM-YYYY HH:mm"), "DD-MM-YYYY HH:mm", options.tz); // change timezone
  // convert to UTC
  let _utc = updatedTimezone.utc(false); // false = convert time

  if (offset1 !== offset2) {
    console.log(__yellow("dateToUtc > timezone difference found"), { offset1, offset2 })
  }

  return options.returnAs == "string" ? _utc.format() : _utc;
}

export const utcToDate = (utc_string, tz) => {
  // let _t = moment(utc_string);
  // var updatedTimezone = moment.tz(_t.format("DD-MM-YYYY HH:mm"), "DD-MM-YYYY HH:mm", tz || defaultTimeZone); // change timezone
  // return updatedTimezone;

  return utc_string ? moment(utc_string) : moment();
}



export const getTimeDifference = _date => {
  var now = moment(new Date()); //todays date
  var end = moment(_date); // another date
  var duration = moment.duration(now.diff(end));

  var years = duration.asYears();
  
  if(years >= 1) return `${Math.floor(years)} Years ago`;

  var months = duration.asMonths();
  if (months >= 1) return `${Math.floor(months)} Months ago`;

  var weeks = duration.asWeeks();
  if (weeks >= 1) return `${Math.floor(weeks)} Weeks ago`;

  var days = duration.asDays();
  if (days >= 1) return `${Math.floor(days)} Days ago`;

  var hours = duration.asHours();
  if (hours >= 1) return `${Math.floor(hours)} Hours ago`;

  var minutes = duration.asMinutes();
  if (minutes >= 1) return `${Math.floor(minutes)} Minutes ago`;

  return `Less than a minute ago`;

}

export const convertTo24Hrs = timeString => {
  let newStr = String(timeString);
  if (newStr.length < 4)
    for (let a = 0; a < (4 - newStr.length); a++)
      newStr = "0" + newStr;
  return newStr;
}
export const convertTo12Hrs = timeString => {
  let _timeString = convertTo24Hrs(timeString);
  // return moment(_timeString, ["HH"]).format("hh A");
  return moment(_timeString, ["HHmm"]).format("hh:mm A");
}




/*************************** MOngo Record filters FUNCTIONS *******************
 *
*/
export const omitTypeName = (obj) => {
  // console.log("omitTypeName()", obj);
  let o = {};
  for (let b in obj) {
    if (b != '__typename')
      o[b] = obj[b];
  }
  // console.log("omitTypeName()", o);
  return o;
}

export const omitAllTypeName = (obj) => {

  if (_.isArray(obj) || _.isObject(obj)) {
    let o = _.isArray(obj) ? [] : {};
    for (let b in obj) {
      if (b != '__typename') {
        o[b] = _.isString(obj[b]) ? obj[b] : omitAllTypeName(obj[b]); //_.isObject(obj) ? omitAllTypeName(obj[b]) : obj[b];
      }
    }
    obj = o;
  }

  return obj;

  // _.isArray()
  // _.isString()
}


// export const parseDeliverySlotData = (delivery_slot, {tz}) => {
//   let _date = dateToUtc(delivery_slot.date, {tz});
//   let time_range_utc = _.isArray(delivery_slot.time_range_utc) ? delivery_slot.time_range_utc : JSON.parse(delivery_slot.time_range_utc);
//   let t1 = dateToUtc(time_range_utc[0], {tz});
//   let t2 = dateToUtc(time_range_utc[1], {tz});

//   // let str = _date.format('YYYY-MM-DD ') + t1.format("HH:mm");
//   // let deliveryTime = moment(str, 'YYYY-MM-DD HH:mm');

//   return { 
//     time_range_utc: [t1, t2], 
//     start_date: moment(_date.format('YYYY-MM-DD ') + t1.format("HH:mm"), 'YYYY-MM-DD HH:mm'),
//     end_date: moment(_date.format('YYYY-MM-DD ') + t2.format("HH:mm"), 'YYYY-MM-DD HH:mm'),
//   }
// }



export const parseNewLine = (txt) => (txt.split("\n")?.map((o, i) => (<div key={i}>{o}</div>)))
