import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
import Logout from './Logout';
import { topMenu, ROOT, MODULE } from './constants';

// addReducers(reducers, { whitelist: ['user'] });
addModule(
    { path: `${ROOT}`, component: Home, title: "User", exact: 0, hideNav: 1 },
)
addModule(
    { path: `/logout`, component: Logout, title: "Logout", exact: 1, hideNav: 0 },
)

// addModule([
//     { path: `${ROOT}`, component: Home, title: "User", exact: 0, hideNav: 1 },
//     { path: `/profile`, component: UserProfile, title: "UserProfile", exact: 1, hideNav: 1 },
//     ]
// )
