import React, { useState, useEffect } from 'react'
import { DevBlock, Loader } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper';
import { withApollo } from 'react-apollo';
import { Alert } from 'antd';
import { loader } from 'graphql.macro';
// import CheckoutSuccess from 'Modules/Checkout/containers/CheckoutSuccess';
// import { Redirect } from 'react-router-dom';

const CONFIRM_PAYMENT = loader('src/graphqls/order/confirmOrderPayment.graphql');

// const KzSuccess = () => {
//     return(<>
//         <CheckoutSuccess />
//     </>)
// }

const KzSuccess = ({ client, location }) => {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [busy, setBusy] = useState(true);
    const [called, setCalled] = useState(false);
    const searchParams = new URLSearchParams(location.search);
    

    let params = {}
    for (const p of searchParams) {
        Object.assign(params, {[p[0]]:p[1]})
        console.log(p);
    }

    useEffect(() => {
        if (called) return;
        setCalled(true);
        confirmOrderPayment()
    }, [location])

    const confirmOrderPayment = async() => {
        let variables = {
            transaction_id: params.id, 
            success_data: JSON.stringify(params),
        }
        const results = await client.mutate({ mutation: CONFIRM_PAYMENT, variables })
            .then(({ data }) => (data.confirmOrderPayment))
            .catch(err => {
                console.log(__error("Query Error: "), err)
                return { error: { message: "Query Error" } }
            });
        console.log("results: ", results)

        setBusy(false);
        if (!results || results.error){
            let error = results ? results.error.message : "Payment not confirmed";
            console.log(__error(error))
            setError(error);
            return;
        }

        setSuccess(results.success)
    }

    if(busy) return (<>
        <p align="center" style={{ marginTop:"20px" }}>Confirming your payment.. please hold on..</p>
        <Loader loading={true}>.</Loader>
    </>)

    if (!called) return null;
    
    if (error) return <Alert message={error} type='error' showIcon />

    if (success) {
        window.location.href = `/checkout/success?message=${success.message}`; 
        return (<>
            {/* <Redirect to={`/checkout/success?message=${success.message}`} /> */}
            <p>redirecting...</p>
        </>)
    }
    return <p>Opps! impossible happened</p>

}
export default withApollo(KzSuccess);
