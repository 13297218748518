import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { __error } from 'Common/scripts/consoleHelper';
import { Alert, message } from 'antd';
import { Button, DevBlock, Loader } from 'Common/components';
import { loader } from 'graphql.macro';
import { withApollo } from 'react-apollo';

// const ADD_TRANSACTION_TO_CART = loader('src/graphqls/cart/addTransactionToCart.graphql');
const INIT_PAYMENT = loader('src/graphqls/payment_methods/initPaymentProcess.graphql');

class KazangPayment extends Component {
    state = { 
        busy: false, processingPayment:false, transactionData:null, error:null, fatelError:null, debugData:null,
        initData:null,
    }
    
    componentDidMount(){
        if (this.props.orderData) this.createTransaction();
    }

    async createTransaction(){
        // console.log("createTransaction()")
        const { orderData, initialValues } = this.props;

        this.setState({ busy: true });

        let input = {
            service_key: initialValues.ref_key,
            shipping_address: orderData.shipping_address,
            note: orderData.note,
            delivery_slot: orderData.delivery_slot,
            discount_voucher_code: orderData.discount_voucher_code && String(orderData.discount_voucher_code).trim() || undefined,
        }
        const results = await this.props.client.mutate({ mutation: INIT_PAYMENT, variables: { input } }).then(({ data }) => (data.initPaymentProcess))
            .catch(err => {
                console.log(__error("Query Error: "), err)
                return { error: { message: "Query Error" } }
            });
        if (results.error){
            this.setState({ busy: false, fatelError: results.error.message });
            return;
        }

        let json_response = JSON.parse(results.json_response)
        window.location.href = json_response.url;
        // return this.setState({
        //     busy: false, 
        //     initData: {
        //         ...results,
        //         json_response
        //     }
        // });

    }

    // async addTransactionToCart(input){
    //     // this.props.cart
    //     const results = await this.props.client.mutate({ mutation: ADD_TRANSACTION_TO_CART, variables: { input } }).then(({ data }) => (data.addTransactionToCart))
    //         .catch(err => {
    //             console.log(__error("Query Error: "), err)
    //             return { error: { message:"Query Error" } }
    //         });

    //     return results;
    // }


    render() {
        const { busy, error, fatelError, initData } = this.state;

        if (error) return <Alert type="error" message={error} showIcon />
        if (fatelError) return <Alert type="error" message={fatelError} showIcon />
       
        return (<Loader loading={busy}>
            <p>Proceeding with Kazang Mobile Payment . . .</p>
            {/* <DevBlock obj={this.state.initData} /> */}
        </Loader>)
    }
}
KazangPayment.propTypes = {
    orderData: PropTypes.object.isRequired,
    initialValues: PropTypes.object.isRequired,
}


const mapStateToProps = state => {
    return ({
        settings: state.grocer_storefront,
        order: state.order,
        cart: state.cart,
        user: state?.grocer_storefront?.user,
    });
}
const WithApollo = connect(mapStateToProps)(KazangPayment);
export default withApollo(WithApollo)



