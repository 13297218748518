import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Alert, Row, Col, Divider } from 'antd'
// import { graphql, withApollo } from '@apollo/client/react/hoc';
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
// import moment from 'moment'
import _ from 'lodash'
import { connect } from "react-redux";
import { __error } from 'Common/scripts/consoleHelper'
import { DevBlock, Loader, Heading, Button, Icon } from 'Common/components'
import RenderInvoice from './RenderInvoice'
import RenderHistory from './RenderHistory'
import { utcToDate, parseJson } from 'Common/scripts/Functions'
import { E404 } from 'Layout_v1';
import moment from 'moment';


const GET_PROD = loader('src/graphqls/order/orderBySerial.graphql');


class UserOrderDetails extends Component {

    render() {
        const { loading, orderBySerial } = this.props;
        
        if (!this.props.match.params.serial) return <Alert message="Error" description="Missing Serial" type="error" showIcon />
        if (loading) return <Loader loading={true} center />
        if (!orderBySerial) return <Alert message="Error" description="No record found" type="error" showIcon />
        if (orderBySerial.error) return <Alert message="Error" description={orderBySerial.error.message} type="error" showIcon />

        const { delivery_slot, shipping_address } = orderBySerial;
        const delivery_slots_time = JSON.parse(delivery_slot.time_range_utc);


        return (<>
            <Row>
                <Col><Button type="default" className="back-button" size="large" onClick={()=>this.props.history.goBack()}><Icon icon="angle-left" style={{marginRight:"5px"}} /> Back</Button></Col>
                <Col flex="auto" />
            </Row>

            <hr />
            <Row style={{fontSize:"16px"}}>
                <Col flex="auto">Order Date: {utcToDate(orderBySerial.created_at).format('YYYY-MM-DD HH:mm')}</Col>
                {/* <Col flex="auto">Order Date: {utcToDate(delivery_slot.date).format('YYYY-MM-DD HH:mm')}</Col> */}
                <Col>Delivery Schedule: {utcToDate(delivery_slot.date).format('YYYY-MM-DD')} : 
                    <b> {moment(delivery_slots_time[0], "DD-MM-YYYYTHH:mm").format('HH:mm')}  to {moment(delivery_slots_time[1], "DD-MM-YYYYTHH:mm").format('HH:mm')}</b>
                </Col>
            </Row>
            <hr />
            

            <Row gutter={[12, 12]}>
                <Col flex="auto">
                    <RenderInvoice order={orderBySerial} />
                    <Heading>Delivery Information</Heading>
                    <div>
                        <div><b>{orderBySerial.customer.fname}</b></div>
                        <div>{orderBySerial.customer.email} - {orderBySerial.customer.phone}</div>
                        <Divider style={{margin:"5px 0"}} />
                        <div>
                            <div>{shipping_address.full_address}, {shipping_address.city}</div>
                            {shipping_address.delivery_instructions && <div style={{ marginTop: "20px" }}><i><pre>{shipping_address.delivery_instructions}</pre></i></div>}
                        </div>
                        <Divider style={{ margin: "5px 0" }} />
                        <div>
                            Scheduled for <b>{utcToDate(delivery_slot.date).format("ddd, MMM Do YYYY")} - {utcToDate(delivery_slots_time[0]).format("hh:mm A")} to {utcToDate(delivery_slots_time[1]).format("hh:mm A")}</b>
                        </div>
                        {orderBySerial.note && orderBySerial.note.length > 1 &&
                            <Alert message="Client Notes" description={orderBySerial.note} type="info" showIcon />
                        }
                    </div>
                </Col>

                {orderBySerial.status != 'completed' &&
                    <Col flex="30%">
                        <RenderHistory order={orderBySerial} />
                    </Col>
                }
            </Row>
            
            <p>&nbsp;</p>
            <DevBlock obj={orderBySerial} />
        </>)
      }
}

UserOrderDetails.propTypes = {
    // toggleDrawer: PropTypes.func.isRequired
    // prop: PropTypes.type.isRequired
    // onEditRecord: PropTypes.func.isRequired
}
const WithApollo = compose(

    graphql(GET_PROD, {
        options: (props) => {
            return {
                variables: { serial: props.match.params.serial },
                fetchPolicy: "no-cache", //no-cache, cache-first, network-only, cache-only, cache-and-network
            };
        },
        props: ({ ownProps, data }) => {
            const { loading, orderBySerial, error } = data;
            if (error)  console.log(__error("error"), error);
            return { loading, orderBySerial, queryErrors: error, }
        },
    }),

)(UserOrderDetails);


// if (!this.props.user || !this.props.user._id) return <Redirect to="/" />
const ModuleCheck = (props) => {
    if(!props.user || !props.user._id) return <E404 />
    return <WithApollo {...props} />
}
const mapStateToAvatarPopProps = state => {
    return ({ 
        user: state?.grocer_storefront?.user,
    });
}
const WithRedux = connect(mapStateToAvatarPopProps)(ModuleCheck);
export default WithRedux;
