import React from 'react'
import { Row, Col, Space } from 'antd';
// import { useMediaQuery } from 'react-responsive'
import { ProductList, ProductListScroller, BackToTopButton } from 'Layout_v1';
import { Carousel, CarouselItem, Avatar, Loader } from 'Common/components'
import { useWindowSize } from 'Common/hooks';



export const Home = props => {
    const { loading, banners, products } = props;
    // const isTabletOrMobileDevice = useMediaQuery({ query: '(max-device-width: 1224px)' })

    const [screenSize] = useWindowSize();
    const isTabletOrMobileDevice = screenSize.width < 1224;


    if (loading) return <Loader type="home" loading={true} />

    var top_slider;
    var top_banner;
    var mid_banner;
    var bottom_banner;

    if (!loading && banners) {
        top_slider = banners.find(o => o.key == "top-slider")?.banner; //?.items;        
        top_banner = banners.find(o => o.key == "top-banner")?.banner; //?.items;
        mid_banner = banners.find(o => o.key == "mid-banner")?.banner; //?.items;
        bottom_banner = banners.find(o => o.key == "bottom-banner")?.banner; //?.items;
    }

    var featured_products;
    var top_products;

    if (!loading && products) {
        featured_products = products.find(o => (o.key =="featured-products"))
        top_products = products.find(o => (o.key == "top-products")) 
    }

    return (<>
        <Space direction='vertical' size={20}>
            {top_slider &&
                <Carousel>
                {top_slider?.items.map((item, i) => {
                    return <CarouselItem style={{ height: top_slider.height ? `${top_slider.height}px` : "auto"}} url={item.url} img={`${process.env.REACT_APP_DATA_URL}/${item.img}`} key={`${i}_${item._id}`} />
                    })}
                </Carousel>
            }

            {top_banner && <>
                <Carousel>
                    {top_banner?.items.map((item, i) => {
                        return <CarouselItem style={{ height: top_banner.height ? `${top_banner.height}px` : "auto" }} url={item.url} img={`${process.env.REACT_APP_DATA_URL}/${item.img}`} key={`${i}_${item._id}`} />
                    })}
                </Carousel>
            </>}

            {featured_products &&
                <div style={{ padding: "0 10px 0 10px" }}>
                    <h3>{featured_products.title}</h3>
                    <ProductList data={featured_products.products} total={featured_products.products.length} />
                </div>
            }

            {mid_banner &&
                <Carousel>
                    {mid_banner?.items.map((item, i) => {
                        return <CarouselItem style={{ height: mid_banner.height ? `${mid_banner.height}px` : "auto" }} url={item.url} img={`${process.env.REACT_APP_DATA_URL}/${item.img}`} key={`${i}_${item._id}`} />
                    })}
                </Carousel>
            }

            {top_products &&
                <div style={{ padding: "0 10px 0 10px" }}>
                    <h3>{top_products.title}</h3>
                    <ProductList data={top_products.products} total={top_products.products.length} />
                </div>
            }

            {bottom_banner &&
                <div>
                    <Row gutter={[12, 0]}>
                        {bottom_banner && bottom_banner[0] &&
                            <Col span={isTabletOrMobileDevice ? 24 : 12} flex="auto"><div style={{ backgroundColor: "green", margin: "20px 0", minHeight: "50px" }}>
                                <a href={bottom_banner[0].url}><img src={`${process.env.REACT_APP_DATA_URL}/${bottom_banner[0].img}`} width="100%" /></a>
                            </div></Col>
                        }
                        {bottom_banner && bottom_banner[1] &&
                            <Col flex="auto"><div style={{ backgroundColor: "green", margin: "20px 0", minHeight: "50px" }}>
                                <a href={bottom_banner[1].url}><img src={`${process.env.REACT_APP_DATA_URL}/${bottom_banner[1].img}`} width="100%" /></a>
                            </div></Col>
                        }
                    </Row>
                </div>
            }

        </Space>

        {/* <BackToTopButton /> */}

    </>)
}

export default Home;