import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { Row, Col, Modal, message, Steps } from 'antd';
import { connect } from "react-redux";
import { __error } from 'Common/scripts/consoleHelper'
import { loginUser, logoutUser } from 'Store/grocer_storefront.actions'
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import RegisterForm from '../components/RegisterForm';
import SignInForm from '../components/SignInForm';
import EmailVerification from './EmailVerification'
import LocationForm from '../components/LocationForm';
import { Button } from 'Common/components';


export const RegisterPage = props => {
    const [doAutologin, setDoAutologin] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState(false);
    const [stepNum, setStepNum] = useState(0);
    const [locationInfo, setLocationInfo] = useState(null);
    const [permissionsAlert, set_permissionsAlert] = useState(true);

    let history = useHistory();

    const onRegisterCallback = async(autoLoginInfo) => {
        if (autoLoginInfo){
            //encodeURI()
            let encoded = base64_encode(JSON.stringify(autoLoginInfo));

            history.push(`/user/verify-email/${encoded}`);
            return true;
        }
        history.push('/user/sign-in');
    }

    const onSigninCallback = args => setLoggedInUser(args);

    const onVerificationCallback = args => {
        props.loginUser({ ...loggedInUser, user:args});
        if (props.onCancel) props.onCancel();
    }

    const onStepSubmit = async (_values, _stepNum) => {
        if (_stepNum < 2) setStepNum(_stepNum + 1);
        setLocationInfo(_values)
        return true;
    }

    if (props.user && props.user._id) history.push('/')

    if (loggedInUser) return <EmailVerification user={loggedInUser.user} onSuccessCallback={onVerificationCallback} />
    if (doAutologin) return <SignInForm {...props} autoLoginInfo={doAutologin} onSuccessCallback={onSigninCallback} />
    

    return (<>
        <div style={{ margin: "20px 0 50px 0" }}>

            <Row style={{ justifyContent: "center" }}>
                <Col flex="900px" style={{ margin: "0 25px" }}>
                    <div style={{ textAlign: "right", marginBottom: "20px" }}>Already a member? &nbsp;
                        {props.onLoginClick && <span onClick={props.onLoginClick} className="green-link">Login</span>}
                        {!props.onLoginClick && <Link to="/user/sign-in" className="green-link">Login</Link>}
                        &nbsp; here.
                    </div>
                </Col>
            </Row>

            <RegisterForm {...props} onSuccessCallback={onRegisterCallback} />

            {/* {stepNum === 0 && <><LocationForm buttonLabel="Next" onSubmit={(args) => onStepSubmit(args, stepNum)} /></>}
            {stepNum === 1 && <><RegisterForm {...props} locationInfo={locationInfo} onSuccessCallback={onRegisterCallback} /></>} */}

        </div>

        <Modal visible={permissionsAlert} footer={<><Button onClick={() => set_permissionsAlert(false)}>Close</Button></>} closable={false} title="Permissions required" centered>
            <p><b>Grocer Zambia</b> may request access to your device's location services to provide you with accurate delivery services.</p>
            <p>By granting location permissions, you allow us to determine your current location and ensure that your deliveries are efficiently routed to the correct address. Rest assured that we prioritize the privacy and security of your data, and we only use your location information for the sole purpose of enhancing your user experience within the application. You have the option to manage and disable location services at any time through your device settings. Please note that disabling location services may impact the accuracy of delivery information.</p>
            <p>We appreciate your trust in <b>Grocer Zambia</b> and are committed to safeguarding your privacy. For more information, please refer to our <a href="https://grocerzm.com/page/privacy-policy" target='_blank'>Privacy Policy</a>.</p>
        </Modal>
    </>)
}

RegisterPage.propTypes = {
    isPopup: PropTypes.bool
}

const mapStateToProps = state => {
    return ({ 
        user: state?.grocer_storefront?.user,
    });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    loginUser: (payload) => dispatch(loginUser(payload)),
    logoutUser: (payload) => dispatch(logoutUser(payload)),
})
export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
