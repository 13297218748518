import React, { useState } from 'react'
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
// import compose from 'lodash.flowright';
import { Alert } from 'antd';
// import { connect } from "react-redux";
import { Button } from 'Common/components'
import { FormComponent, FormField, FormFieldGroup, rules, composeValidators } from 'Common/components/Form'
import { __error } from 'Common/scripts/consoleHelper'
// import { Redirect } from 'react-router-dom';
import { logoutUser, updateUser, updateSettings } from 'Store/grocer_storefront.actions';
import { flushCart } from 'Store/cart.actions'
import LocalStorage from 'Common/scripts/LocalStorage';
import { connect } from "react-redux";

const VERIFY_RECORD = loader('src/graphqls/userDeletionRequests/verifyUserDeletionRequests.graphql');

const VerifyAccountDeletionRequest = ({ client, on_flushCart, on_logoutUser }) => {
    const [errorMessage, set_errorMessage] = useState(null);
    const [success, set_success] = useState(false);

    const onSubmit = async(values) => {
        const input = {
            code: values.code,
            email: values.email,
            password: values.password,
        }

        let results = await client.mutate({ mutation: VERIFY_RECORD, variables: { input } })
            .then(r => (r?.data?.verifyUserDeletionRequests)).catch(err => {
                console.log(__error("Query error!"), err);
                return { error: { message: "Query Error!" } };
            });
        
        if (!results || results?.error?.message){
            set_errorMessage((results && results?.error?.message) || "Invalid response!");
            return false;
        }

        if (results.success){
            set_success(true)
            alert("Request verified");
            await LocalStorage.logout();
            on_flushCart();
            on_logoutUser();

            window.location = "/logout";
        }

        return false;

    }

    if (success) return (<><p>Redirecting...</p></>)

    return (<>
        <div className="h-center" style={{ marginBottom: "50px" }}>
            <div style={{ width: "100%", maxWidth: "350px" }}>
                <h3 style={{ textAlign: "center", marginBottom:"20px" }}>Sorry to see you go<br />Please verify your account deletion request</h3>

                <FormComponent onSubmit={onSubmit} id='AccountDeletionRequestForm' style={{ width: "100%", maxWidth: "350px" }}
                    form_render={formProps => {
                        const { values, dirty, errors, submitting, invalid } = formProps;

                        return (<>
                            {errorMessage && <Alert showIcon type="error" message={errorMessage} description={null} />}

                            <FormField type="text" name="email" label="Email Address (Login Use)" validate={composeValidators(rules.required, rules.isEmail)} compact />
                            <FormField type="password" name="password" label="Password" validate={composeValidators(rules.required, rules.minChar(3))} compact />
                            <FormField type="text" name="code" label="Verification Code" validate={rules.required} compact />

                            <div style={{ height:"20px" }} />

                            <Button type="primary" htmlType="submit" loading={submitting} disabled={!dirty || invalid} block size="large">Send Deletion Request</Button>
                        </>)
                    }}
                />

            </div>
        </div>

    </>)
}
const WithApollo = withApollo(VerifyAccountDeletionRequest);

const _funcs = (dispatch, ownProps) => ({
    on_flushCart: () => dispatch(flushCart()),
    on_logoutUser: () => dispatch(logoutUser()),
})
export default connect(undefined, _funcs)(WithApollo);
