import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { routes } from 'Modules';
import { Wrapper, E404 } from "Layout_v1";

// import { PersistGate } from 'redux-persist/integration/react';
// import { persistor } from 'Store';
// import Store from 'Store';


export const App = () => (<>
  <BrowserRouter>
    <Switch>
      {/* <Route path={'/template'} exact component={Template} key={1} /> */}
      <Wrapper>
        <Switch>
          {routes}
          <Route component={E404} />
        </Switch>
      </Wrapper>
    </Switch>
  </BrowserRouter>
</>)

export default App;

