// export const ADD_PRODUCT = 'ADD_PRODUCT';
// export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
// export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const UPDATE_CART = 'UPDATE_CART';
export const FLUSH_CART = 'FLUSH_CART';

// export const addProduct = prod => ({ type: ADD_PRODUCT, payload:prod });
// export const removeProduct = prod => ({ type: REMOVE_PRODUCT, payload: prod });
// export const deleteProduct = prod => ({ type: DELETE_PRODUCT, payload: prod });
export const updateCart = prod => ({ type: UPDATE_CART, payload: prod });
export const flushCart = prod => ({ type: FLUSH_CART, payload: prod });
