import React from 'react';
import styled from 'styled-components'
import Icon from 'Common/components/Icon'

export const LeftArrow = () => <Icon size="lg" icon="angle-left" />

export const ArrowLeftButtonHolder = styled.div`
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -22px 0 0 0;
    padding-right: 5px;
    height: 45px;
    width: 25px;
    border-top-right-radius: 45px;
    border-bottom-right-radius: 45px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    cursor:pointer;
    &:hover{
        background: #EEE;
    }
    &[disabled]{
        cursor:default;
        opacity: 0.2;
    }
`
// export const LeftArrowButton = ({ disabled, onClick }) => <ArrowLeftButtonHolder className={disabled ? "disabled" : ""}><LeftArrow /></ArrowLeftButtonHolder>
export const LeftArrowButton = (props) => <ArrowLeftButtonHolder {...props}><LeftArrow /></ArrowLeftButtonHolder>



export const RightArrow = () => <Icon size="lg" icon="angle-right" />

export const ArrowRightButtonHolder = styled.div`
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -22px 0 0 0;
    padding-left: 5px;
    height: 45px;
    width: 25px;
    border-top-left-radius: 45px;
    border-bottom-left-radius: 45px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    cursor:pointer;
    &:hover{
        background: #EEE;
    }
    &[disabled]{
        cursor:default;
        opacity: 0.2;
    }
`
// export const RightArrowButton = ({ disabled, onClick }) => <ArrowRightButtonHolder className={disabled ? "disabled" : ""}><RightArrow /></ArrowRightButtonHolder>
export const RightArrowButton = (props) => <ArrowRightButtonHolder {...props}><RightArrow /></ArrowRightButtonHolder>