import React, { useState } from 'react'
import { message } from 'antd';
import { __error } from 'Common/scripts/consoleHelper';
import { loader } from 'graphql.macro';
import { Button } from 'Common/components/Button'
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';

const ADD_PROD = loader('src/graphqls/product_alert/addProductAlert.graphql');

const NotifyMeButtonComp = ({ prod, user, client }) => {
    // console.log(`prod: ${prod._id} : `, prod.already_in_alert)

    const [busy, setBusy] = useState(false);
    // const [isNotified, setIsNotified] = useState(user?._id && prod.alerts && prod.alerts.indexOf(user._id) > -1);
    const [isNotified, setIsNotified] = useState(prod.already_in_alert == 'yes');

    if(!user || !user._id || !prod || !prod._id) return null;

    const addNotification = args => {
        console.log("addNotification()")
        setBusy(true);

        client.mutate({
            mutation: ADD_PROD,
            variables: { input: { customer_id: user._id, prod_id: prod._id } },
        }).then(({ data }) => {
            setBusy(false);
            if (data.addProductAlert.error) {
                message.error(data.addProductAlert.error.message);
                return;
            }
            message.success("Your reminder has been created! You will be notified as soon as the product is back in stock!");
            setIsNotified(true);
            // return data.data.addProductAlert;
        }).catch(err => {
            setBusy(false);
            console.log(__error("ERROR: "), err)
            message.error("Unable to complete your request at the moment!");
            // return false;
        });

    }

    if (prod.available_qty < 1) return <Button disabled={isNotified} onClick={addNotification} theme="blue" loading={busy}>{isNotified ? "Requested" : "Notify Me"}</Button>;

    return null;
}
NotifyMeButtonComp.propTypes = {
    prod: PropTypes.object.isRequired,
    user: PropTypes.object,
}

export const NotifyMeButton = withApollo(NotifyMeButtonComp)
