import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { message, Tooltip, Modal, Row, Col } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
// import { graphql, withApollo } from '@apollo/client/react/hoc';
import { graphql } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { Icon, Button, IconButton, Loader } from 'Common/components';
import { __error } from 'Common/scripts/consoleHelper';
import { updateSettings } from 'Store/grocer_storefront.actions';

const ADD_TO_CART = loader('src/graphqls/cart/addCartItem.graphql');
const REMOVE_FROM_CART = loader('src/graphqls/cart/deleteCartItem.graphql');


const _AddToCartButton = props => {
    const [loading, setLoading] = useState(false);
    const [showHaltModal, setShowHaltModal] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const { prod, cart } = props;
    var disabled = loading;

    if (!prod) return <Button className="btn" disabled>No Product</Button>
    let cart_item = cart.items.find(o=>o._id==prod._id);

    let anotherStore = (cart.items && cart.items.find(o => o.store_id != prod.store_id)) || false;

    const proceedAddTocart = async () => {
        // update db records if user is logged in
        if (props.user && props.user._id) {
            setLoading(true);

            let data = {
                customer_id: props.user._id,
                product_id: prod._id,
                qty: 1
            }

            let added = await props.addCartItem(data)
                .then(r => (r))
                .catch(err => {
                    console.log(__error("ERROR"), err);
                    message.error("Unable to complete your request at the moment");
                    return false;
                })

            if (added.data.addCartItem.error) {
                setLoading(false);
                message.error(added.data.addCartItem.error.message);
            } else if (!added || !added.data || !added.data.addCartItem || !added.data.addCartItem._id) {
                setLoading(false);
                message.error("Unable to process your request at the moment.");
                return;
            } else {
                setLoading(false);
                // props.updateCart(added.data.addCartItem);
                // props.addProduct(added.data.addCartItem);
            }
        }


    }
    
    const onAddClick = async (args) => {
        // update redux only if user is not logged in 
        if (!props.user || !props.user._id) { 
            document.dispatchEvent(global.SigninPopEvent);
            return;
        }
        
        // verify if there is another store item in the cart already/
        if (!anotherStore) return proceedAddTocart();

        setShowConfirmation(true);

    }

    const onRemoveClick = async (args) => {
        // update redux only if user is not logged in 
        if (!props.user || !props.user._id) {
            // message.error("Please log in first!")
            props.updateSettings({ showSigninPop: true, showRegistrationPop: false })
            // props.removeProduct(prod);
            return;
        }

        setLoading(true);

        if (props.user && props.user._id) {
            let data = { customer_id: props.user._id, product_id: prod._id };
            // props.deleteCartItem(customer_id: ID!, prod_id: ID!, delete_all: String)
            let removed = await props.deleteCartItem(data)
                .then(r => (r) )
                .catch(err => {
                    console.log(__error("ERROR"), err);
                    message.error("Unable to complete your request at the moment")
                    // return { error: { message:"Unable to complete your request at the moment"}};
                })

            if (removed.data.deleteCartItem.error) {
                setLoading(false);
                message.error(removed.data.deleteCartItem.error.message);
            }
            else {
                setLoading(false);
            }
        }

    }

    if (!disabled && cart_item) {
        disabled = prod.available_qty <= cart_item.qty;// console.log("OUT OF STOCK");
        if (!disabled && prod.limit_max_qty_in_cart>0) disabled = (cart_item.qty >= prod.limit_max_qty_in_cart);
    }
    if (props.disabled || prod.not_serving=="yes") disabled = true;

    const hasInCart = cart_item ? true : false;
    const size = props.size || "medium";

    if (prod.available_qty < 1 || prod.store_status == 'inactive') {
        return (<div className="cart-button">
            <div className="add-button">
                <Button size={size} disabled={true} theme="add-to-cart" className={`btn disabled`}>Out of Stock</Button>
            </div>
        </div>)
    }

    // return (<>
    //     <span style={{ display: "inline-flex", ...props.style }}><Loader loading={loading} size="20"><div className="cart-button">
    //         <div className="add-button">
    //             <Button size={size} theme="add-to-cart" className={`btn add ${props.className}`} onClick={() => setShowHaltModal(true)}>Add</Button>
    //         </div>
    //     </div></Loader></span>

    //     <Modal width={500} title={<div style={{ fontWeight:"bold" }}>Starting Soon</div>} visible={showHaltModal}
    //         footer={<Row>
    //             <Col flex="auto" />
    //             <Col><Button type="default" onClick={() => setShowHaltModal(false)} style={{ width: "180px" }}>Close</Button></Col>
    //         </Row>}
    //         onCancel={() => setShowHaltModal(false)}><>
    //             <p style={{ fontSize:"16px" }}>Get ready for a new way to grocery shop! Grocer Zambia's launch is right around the corner on October 24th.</p>
    //             <p style={{ fontSize: "16px" }} onClick={()=>{
    //                 setShowHaltModal(false);
    //                 window.location.href = "/user/register";
    //             }} className='a'>Pre-sign in to avoid the rush and enjoy exclusive benefits.</p>
    //         </>
    //     </Modal>
    // </>)

    return (<>
        <span style={{ display: "inline-flex", ...props.style }}><Loader loading={loading} size="20"><div className="cart-button">

            {!hasInCart && 
                <div className="add-button">
                    <Button size={size} disabled={disabled} theme="add-to-cart" className={`btn add ${props.className}`} onClick={onAddClick}>Add</Button>
                </div>
            }

            {hasInCart && <div className="add-button">
                <Row align="middle" style={{ flexWrap:"nowrap" }}>
                    <Col><Button size={size} onClick={onRemoveClick} className="btn minus" shape='circle' theme="add-to-cart" disabled={loading}><Icon className="icon" icon="minus" /></Button></Col>
                    <Col><div className="qty-holder">{cart_item && cart_item.qty || 0}</div></Col>
                    <Col><Button size={size} onClick={onAddClick} className="btn plus" shape='circle' theme="add-to-cart" disabled={loading || disabled}><Icon className="icon" icon="plus" /></Button></Col>
                </Row>
            </div>}

        </div></Loader></span>

        <Modal 
            width={400}
            title={<div style={{fontSize:"24px", color:"red"}}><Icon icon="exclamation-triangle" color="orange" /> Changing Store</div>} 
            visible={showConfirmation} 
            footer={<Row>
                <Col><Button type="default" onClick={() => setShowConfirmation(false)} style={{ width: "180px" }}>Keep The Existing Cart</Button></Col>
                <Col flex="auto" />
                <Col><Button onClick={() => {
                    setShowConfirmation(false);
                    proceedAddTocart();
                }} style={{ width:"180px" }} theme="orange">Empty Cart & Proceed</Button></Col>
            </Row>}
            onCancel={() => setShowConfirmation(false)}>
            {showConfirmation && <div style={{fontSize:"16px"}}>
                {/* <Row gutter={[5]} style={{ flexWrap: "nowrap", border: "0px solid #EEE", marginBottom:"20px" }} align="middle">
                    <Col span={11} align="right" style={{ borderRadius:"5px", color:"red" }}>{anotherStore.store.title}</Col>
                    <Col span={2} align="center"><Icon icon="angle-double-right" size="1x" /></Col>
                    <Col span={11} align="left" style={{ borderRadius: "5px", color:"green" }}>{prod.store.title}</Col>
                </Row> */}

                <p style={{ fontSize:"16px", textAlign:"center", color:"red" }}>
                    <b>You are switching store!</b>
                    <br />
                    Changing to a differnt store will remove all existing items from your cart!
                </p>
            </div>}
        </Modal>
    </>)
}

_AddToCartButton.propTypes = {
    disabled: PropTypes.bool,
    size: PropTypes.string,
    prod: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        price_was: PropTypes.number,
        tax_total: PropTypes.number,
        limit_max_qty_in_cart: PropTypes.number,
        picture_thumb: PropTypes.string,
        qty: PropTypes.number,

        attributes: PropTypes.arrayOf(
            PropTypes.shape({
                _id: PropTypes.string,
                title: PropTypes.string,
                show_in_store: PropTypes.number,
                value: PropTypes.string,
                displayAs: PropTypes.string,
            })
        ),


    }).isRequired,
}



const mapStateToProps = state => {
    return ({ 
        cart: state.cart, 
        user: state?.grocer_storefront?.user,
    });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    updateSettings: (payload) => dispatch(updateSettings(payload)),
})
const __AddToCartButton = connect(mapStateToProps, mapDispatchToProps)(_AddToCartButton);


const AddToCartButton = compose(

    graphql(ADD_TO_CART, {
        props: ({ mutate }) => ({
            addCartItem: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),

    graphql(REMOVE_FROM_CART, {
        props: ({ mutate }) => ({
            deleteCartItem: (args) => mutate({
                variables: { input:{...args} }
            }),
        })
    }),

)(__AddToCartButton);

export default AddToCartButton;