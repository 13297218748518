import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ApolloWrapper from './apollo/aClient';
import { Provider } from 'react-redux';
import Store from 'Store';
import moment from 'moment'
import { PersistGate } from 'redux-persist/integration/react';

import './fonts/SourceSansPro-Bold.ttf'
import './fonts/SourceSansPro-Regular.ttf'
// import Testing from 'Modules/Testing/containers/orders_list';

moment.tz.setDefault("Asia/Karachi");



ReactDOM.render(
  <PersistGate persistor={Store.persistor} loading={<div>Initilizing data...</div>} onBeforeLift={() => { /* do soemting */ }}>
    <Provider store={Store.store}>
      <ApolloWrapper>
        <App />
      </ApolloWrapper>
      {/* <ApolloProvider client={client}>
        <App />
      </ApolloProvider> */}
    </Provider>
  </PersistGate>,
  document.getElementById('root')
);

// ReactDOM.render(
//   <Provider store={Store.store}>
//     <ApolloProvider client={client}>
//       <App />
//       {/* <Testing /> */}
//     </ApolloProvider>
//   </Provider>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
