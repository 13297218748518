import React from 'react';
import { addModule, addReducers } from '../connector';
import CategoryHome from './CategoryHome';
import StoreHome from './StoreHome';
// import {Icon} from 'common/components'
export const ROOT = '/category';
export const MODULE = 'CATEGORY';


// addReducers({ admins: reducers });
addModule(
    // { path: `${ROOT}`, component: Home, icon: 'laptop-code', title: "Category", exact: 0, hideNav: 0 },
    { path: `/category`, component: CategoryHome, icon: 'laptop-code', title: "Category", exact: 0, hideNav: 0 },
)

addModule(
    { path: `/store`, component: StoreHome, icon: 'laptop-code', title: "Store", exact: 0, hideNav: 0 },
)
