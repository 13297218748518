// import React from 'react';
import ls from 'local-storage';
import { __error, __warning, __yellow, __success, __green } from './consoleHelper';
// const user = ls('user');
import LocalStorage from './LocalStorage';
// import { store, persistor } from 'Store';
import { appVer } from 'configs';
import axios from 'axios';
import { mergeCarts } from './Functions';
// import { loader } from 'graphql.macro';

const env = process.env;
// const MERGE_CART = loader('src/graphqls/cart/mergeCart.graphql');

export function verifyRole(_roleString){
  let roleString = _roleString.toLowerCase();

  const user = LocalStorage.getJson('user');
  if(!user){
    console.log(`${__error('')} verifyRole( ${roleString} ) ${__yellow('FAIL')}`);
    return false;
  }
  if(user && user.type=='super-admin'){
    console.log(`${__success('')} verifyRole( ${roleString} ) ${__green('PASS')}`);
    return true;
  }

  if(user && user.rights && user.rights.indexOf(roleString) > -1){
    console.log(`${__success('')} verifyRole( ${roleString} ) ${__green('PASS')}`);
    return true;
  }

  // console.log(__warning(`You are not allowed to access (${roleString}) => ${user.type}@${user.name}`));
  console.log(`${__error('')} verifyRole( ${roleString} ) ${__yellow('FAIL')}`);
  return false;
}

export const verifyModule = (_moduleName) => {
  let moduleName = _moduleName.toLowerCase();
  console.log(__warning(`verifyModule(${moduleName}) >> Incomplete function`));
  console.trace(">>>");
  return false;

  let verified = verifyRole(moduleName);
  // if(!verified){
  //   console.log(`${__warning(' >> ')} verifyModule( ${moduleName} ) ${__yellow('FAIL')}`);
  //   // console.trace(">>>>>");
  // }
  // else console.log(`${__success(' >> ')} verifyModule( ${moduleName} ${__green('PASS')}`);

  // console.log(`verifyModule(${moduleName}): `, verified?'YES':'NO');
  return verified;

  // const user = LocalStorage.getJson('user');
  // if(!user) return false;
  // // console.log("user.rights: ", user.rights);
  // if(user && user.type=='super-admin') return true;
  //
  // if(user && user.rights && user.rights.indexOf(moduleName) > -1) return true;
  //
  // console.log(__warning(`You are not allowed to access (${moduleName}) => ${user.type}@${user.name}`));
  // // .then(user=>{
  // //   console.log("verifyModule: ", user.type);
  // // });
  // // // return <div>Restricted Area</div>
  // return false;
}

export const checkLoggedIn = async args => {
  const user = await LocalStorage.getJsonAsync('user')
  const token = await LocalStorage.getJsonAsync('token')
  const refreshToken = await LocalStorage.getJsonAsync('refreshToken')

  return (user && user._id && token);
}
// export const checkLoggedIn = login => {
//   // console.log("checkLoggedIn: ", login);
//   return (login && login._id && login._id>0 && login.TokenData);
// }



export const processLoginRequest = async ({ username, pwd, cart, client, onSuccessCallback }) => {
  console.log("processLoginRequest()")

  if (!username){
    alert("Missing user name!")
    return { error: { message:"Missing user name!"}}
  }
  if (!pwd){
    alert("Missing user password!")
    return { error: { message:"Missing user password!" }}
  } 
  
  // const mergeCart = async (variables) => client.mutation({ mutate: MERGE_CART, variables });

  let data = {
    username: username,
    pwd: pwd,
    app: "client-web",
    ver: appVer
  };

  const results = await axios({ method: 'put', url: `${env.REACT_APP_API_URL}/user/login`, data })
    .then((response) => (response.data))
    .catch((error) => {
      console.log(__error("ERROR: "), error);
      return { error: { message: 'Netowrk Error' } }
    });

  if (!results) return { error: { message: "No response Data available" } };


  const { error, warning, status, TokenData, LoggedUser } = results;
  if (error) return results;
  if (warning) return results;
  if (status == 'fail') return { error: { message: "Login attempt failed!" } };
  if (!TokenData || !LoggedUser) return { error: { message: "No login information found!" } };

  // mearge existing cart
  if (client && cart && cart.items && cart?.items?.length > 0) {
    // let mearged = await mergeCarts(cart, LoggedUser.user._id, mergeCart);
    let mearged = await mergeCarts(cart, client);
    if (!mearged || mearged.error) {
      return { error: { message: mearged ? mearged.error.message : "Unable to merge carts." } }
    }
  }

  const output = {
    ...LoggedUser,
    token: TokenData,
    // refreshToken: LoggedUser?.refreshToken,
    // user: { ...LoggedUser?.user?.user, error: undefined, autoLoginInfo: undefined },
    // rights: LoggedUser?.user?.rights,
  }

  if (onSuccessCallback) onSuccessCallback(output)

  return output;
}