import React from 'react'
import { Avatar as _Avatar } from 'antd';
// import { noImage } from 'configs/constants';


export const Avatar = props => {
    let src = '/assets/no-image-available.png';

    if (props.type == 'user') src = props.src ? props.src : '/assets/no-avatar-available.png';
    else src = props.src ? props.src : '/assets/no-image-available.png';

    return (<_Avatar {...props} src={src} />)
    // return (<_Avatar {...props} src={props.src}>{props.src ? null : props.children}</_Avatar>)
}
