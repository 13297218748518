import React from 'react'
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { connect } from "react-redux";
import RenderCart from './RenderCart'
import { Table, Heading, Button, DevBlock } from 'Common/components'

const RenderVoucher = ({ discount, currency }) => {
    // discount.voucher.title

    var message = false

    if (discount.voucher.voucher_type.key == 'discount') {
        if (discount.amount > 0) message = <>{currency} {discount.amount}</>
    }
    if (discount.voucher.voucher_type.key == 'delivery') {
        if (discount.amount > 0) message = <>{currency} {discount.amount}</>
    }
    if (discount.voucher.voucher_type.key == 'gift') {
        
    }
    // if (discount.voucher.loyalty_plan){}


    return (<>
        <Row gutter={[0, 12]} style={{ borderBottom: "1px solid #EEE", padding: "0px 10px", color: "green" }}>
            <Col flex="auto">
                <b>Reward {discount.voucher.loyalty_plan && " (Loyalty Plan)"}</b>
                <div>{discount.voucher.title}</div>
            </Col>
            {/* <Col flex="auto">({discount.discount_value} {discount.discount_type == "fix" ? currency : discount.discount_type})</Col> */}
            {message && <Col span={8}>{message}</Col>}
        </Row>
    </>)
}

const RenderInvoice = ({ order, settings: { currency }, hideTotal }) => {
    const { items, totalTax, invoice, paymentMethod, serial } = order;

    return (<>
        <div className="grid-block">
            <Heading>Order - {serial} - ({order.status})</Heading>

            <div style={{ padding: "0 10px", marginTop: "-10px" }}>
                <RenderCart items={items} filter={(item) => item.delivery_status != 'canceled'} />
            </div>

            {!hideTotal && <>
                <div style={{ padding: "10px 0px", margin:"0 10px", marginTop: "0px", border: "1px solid #EEE" }}>
                    <Row gutter={[0, 12]} style={{ borderBottom: "1px solid #EEE", padding: "0px 10px" }}>
                        <Col span={8}>Total Savings</Col>
                        <Col flex="auto" />
                        <Col span={8}>{currency} {order.total_savings || "0"} ({Number((order.total_savings / invoice.total) * 100).toFixed(2)}%)</Col>
                    </Row>

                    <Row gutter={[0, 12]} style={{ borderBottom: "1px solid #EEE", padding: "0px 10px" }}>
                        <Col span={8}>Subtotal</Col>
                        <Col flex="auto">{items.length} items</Col>
                        <Col span={8}>{currency} {invoice.total}</Col>
                    </Row>

                    <div>
                        <div style={{ margin: "0 10px" }}><b>Service Charges:</b></div>

                        {invoice?.service_charges?.map((o, i) => (<Row gutter={[0, 12]} style={{ borderBottom: "1px solid #EEE", padding: "0px 10px" }} key={i}>
                            <Col flex={"auto"}><h1>- {o.type}</h1></Col>
                            <Col span={8}>{currency} {o.amount}</Col>
                        </Row>))}
                    </div>

                    <Row gutter={[0, 12]} style={{ borderBottom: "1px solid #EEE", padding: "0px 10px" }}>
                        <Col span={8}>Payment Method</Col>
                        <Col flex="auto" />
                        <Col span={8}>{paymentMethod.title}</Col>
                    </Row>

                    <Row gutter={[0, 12]} style={{ borderBottom: "1px solid #EEE", padding: "0px 10px", fontWeight:"bold" }}>
                        <Col span={8}>Total</Col>
                        <Col flex="auto" />
                        <Col span={8}>{invoice.total}</Col>
                    </Row>

                    {(invoice?.discount?.voucher?._id) && <RenderVoucher discount={invoice?.discount} currency={currency} />}

                    {/* {invoice?.discount?.voucher?._id &&
                        <Row gutter={[0, 12]} style={{ borderBottom: "1px solid #EEE", padding: "0px 10px", color:"green" }}>
                            <Col span={8}>Reward applied</Col>
                            <Col flex="auto">({invoice.discount.discount_value} {invoice.discount.discount_type == "fix" ? currency : invoice.discount.discount_type})</Col>
                            <Col span={8}>{currency} {invoice.discount.amount}</Col>
                        </Row>
                    } */}

                    {/* <DevBlock obj={invoice?.discount} /> */}

                </div>
                <Row gutter={[0, 12]} style={{ backgroundColor:"#f0f2f5", border: "1px solid #EEE", padding: "0px 10px", fontWeight:"bold" }}>
                    <Col span={8}>Grand Total</Col>
                    <Col flex="auto" />
                    <Col span={8}>{currency} {Number(invoice.grand_total).toFixed(2)}</Col>
                </Row>
            </>}

            {/* {items.filter(o => o.delivery_status == 'canceled').length>0 &&
                <div style={{ padding: "0 10px", marginTop: "20px" }}>
                    <div style={{ backgroundColor: "#EEE", textAlign: "center", fontSize: "15px", fontWeight: "bold", padding: "5px", color: "#888" }}>Unavailable Items</div>
                    <RenderCart items={items} filter={(item) => item.delivery_status == 'canceled'} />
                </div>
            } */}


        </div>

    </>)
}

RenderInvoice.propTypes = {
    // onClose: PropTypes.func.isRequired,
    order: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
    return ({ settings: state.grocer_storefront });
}
export default connect(mapStateToProps)(RenderInvoice);
// export default RenderInvoice;




