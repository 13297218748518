export const phoneCodes = [
  { title: "0300", _id: "0300" },
  { title: "0321", _id: "0321" },
]

export const genders = [
  { title: "Mr.", _id: "male" },
  { title: "Miss.", _id: "female" },
]

export const months = String("January,February,March,April,May,June,July,August,September,October,November,December")
  .split(",").map((item, i) => ({title:item, _id:i+1}))

export const noImage = `${process.env.REACT_APP_TEMPLATE_ASSETS}/no-img-200.png`;


export const serviceCities = [
  // { title: 'Lahore', _id: "lahore", coordinates: { lat: 31.52443022759592, lng: 74.35772741448616 } },
  { title: 'Sahiwal', _id: 'sahiwal', coordinates: { lat: 30.669168798330947, lng: 73.1108723773994 } },
]
export const default_map_center = {
  lat: serviceCities[0].coordinates.lat,
  lng: serviceCities[0].coordinates.lng
};


export const GOOGLE_API_KEY = 'AIzaSyAXROby7PEree2w4gB9UkNOspY2y2vjclo';