import React from 'react'
import PropTypes from 'prop-types';
import { Col, Row, message } from 'antd';
import _ from 'lodash'
import { connect } from "react-redux";

import { __error } from 'Common/scripts/consoleHelper';
import { Icon, Button, IconButton, Drawer, Avatar, Loader } from 'Common/components';
// import AddToCartButton from '../AddToCartButton'
import { ProductList } from 'Layout_v1'


const LastMinBuy = props => {
    const [showProds, setShowProds] = React.useState(false);
    const { offers, settings } = props;
    // console.log("offers: ", offers)
    if (!offers || offers.length < 1) return null;

    return (<div className={`cart-last-min-offer ${showProds ? "showProds" : ""}`}>
        {/* <img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/cart-last-min-offer-green.png`} /> */}
        <div className="lmb-bar" onClick={() => setShowProds(!showProds)}>
            {/* <div className="strip" /> */}
            <div className="bar-contents">
                <Row className="strip">
                    <Col className="offer-icon"><img src={`${process.env.REACT_APP_TEMPLATE_ASSETS}/offer-bag@2x.png`} /></Col>
                    <Col flex="auto"><div>
                        <div style={{fontSize:"1.3em"}}>Last Minute Buys</div>
                        <div style={{ fontSize: "1em" }}>Get special deiscount here!</div>
                    </div></Col>
                    <Col className="icon-holder" align="center"><Icon icon="chevron-up" className={`up-icon ${showProds ? "" : "bounceVertical"} `} /></Col>
                </Row>
            </div>
        </div>
        {showProds && 
            <div className="details">
                <ProductList loading={false} data={offers?.map(o=>(o.prod))} total={offers.length} itemClassName="last-min-offer-item" />
                {/* {offers.map((item, i) => {
                    return <CartItem settings={settings} key={i} prod={item} />
                })} */}
            </div>
        }
        {/* {showProds && <Loader loading={true} />} */}
        {/* <IconButton size="large" icon="angle-up" onClick={() => setShowProds(!showProds)} ghost className="up-icon" /> */}
    </div>)
}

LastMinBuy.propTypes = {
    offers: PropTypes.array,
}
const mapStateToProps = state => {
    return ({ settings: state.grocer_storefront });
}
export default connect(mapStateToProps)(LastMinBuy);
