import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Avatar, Button, IconButton, NotifyMeButton } from 'Common/components'
import AddToCartButton from './AddToCartButton'
import { connect } from "react-redux";
import { useMediaQuery } from 'react-responsive'
import { isMobile, isTablet, withOrientationChange } from 'react-device-detect';
import { __error } from 'Common/scripts/consoleHelper';
import { Modal, Row, Col, Space } from 'antd';
import { ProductDetailsPage, ProductQuickPreview } from 'Modules/Product/containers';
import { useWindowSize } from 'Common/hooks';


export const ProductListItem = ({ prod, settings, className, style }) => {
    const [showQuickPreview, set_showQuickPreview] = useState(false);

    // const isSmallDevice = useMediaQuery({ query: '(max-device-width: 365px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    // const _isMobile = (isMobile || isSmallDevice) && !isTablet;
    
    const [screenSize] = useWindowSize();
    const _isMobile = screenSize.width < 850;

    const attributes = prod.attributes ? prod.attributes.filter(o => o.show_in_store>0) : [];
    const { currency } = settings;

    const productDisabled = () =>{
        if (!prod) return null;

        if (prod.not_serving == "yes") return <div className='not-serving' />
        if (prod.available_qty < 1) return <div className='sold-out' />

        return null;
    }


    if (!prod || !prod._id) {
        return (<div className={`product-list-item-wrapper ${className}`} style={style}>
            <div className="product-list-item">Invalid Product</div>
        </div>)
    }

    let was = false; //(prod.price_was && prod.price_was > prod.price) ? <div className="price-was-">{((prod.price/prod.price_was) * 100)}</div> : null;
    if (prod.price_was && prod.price_was > prod.price){
        was = Number(((prod.price_was - prod.price) / prod.price_was) * 100).toFixed(2);
    }
    let link = "/product/" + prod.slug;
    let thumb = prod.picture_thumb ? `${process.env.REACT_APP_DATA_URL}/${prod.picture_thumb}` : undefined;


    return (<>
        <div className={`product-list-item-wrapper ${className || ""} ${_isMobile ? "isMobile" : ""} ${_isMobile && isPortrait ? "isPortrait" : ""}`} style={style}>
            <div className='prod-body'>
                
                {/* {prod?.store?._id && <div className='prod-store-title'>
                    <Link to={`/store/${prod.store.slug}`}>{prod.store.title}</Link>
                </div>} */}

                <div className='prod-img'>
                    <Link to={link}><Avatar type="product" shape="square" size={130} src={thumb} style={{ zIndex: 0 }} /></Link>
                    
                    {productDisabled()}

                    <div className='quick-hover'><div className='container'>
                        <Button onClick={(e => {
                            e.preventDefault();
                            set_showQuickPreview(true);
                        })} theme="orange" shape="round" size="large">Quick View</Button>
                    </div></div>
                </div>

                <Space direction='vertical' size={[5]}>
                    {prod?.category?.slug && <div className='prod-cat'><Link to={`/category/${prod.category.slug}`}>{prod.category.title}</Link></div>}
                    <div style={{ lineHeight:"16px" }}>
                        <div className='prod-title'><Link to={link}>{prod.title}</Link></div>
                        <div className='prod-attributes'>{attributes && attributes.map((o, i) => (<span key={i}>{i > 0 && ', '}{o.value}{o.code}</span>))}</div>
                    </div>

                    <div align="center">{prod.price_was > 0 && <span className='price-was'>{currency}{prod.price_was}</span>} <span className='price-is'>{currency}{prod.price}</span></div>
                    {/* <Row>
                        <Col className='price-was'>{currency}{prod.price_was}</Col>
                        <Col className='price-is'>{currency}{prod.price}</Col>
                    </Row> */}
                </Space>

                {was && <div className='prod-discount'>{was}% off</div>}
            </div>

            <div className='prod-footer'>
                <AddToCartButton prod={prod} />
            </div>
        </div>

        <Modal width={'100%'} style={{ maxWidth: "1280px" }} visible={showQuickPreview} 
            closable={false}
            onCancel={() => set_showQuickPreview(false)} 
            // cancelButtonProps={{ shape: "circle", type:"primary" }}
            title={false} footer={false}><>
            {showQuickPreview && <>
                <div style={{ position:"absolute", top:5, right:5, zIndex:999 }}><IconButton size="large" onClick={() => set_showQuickPreview(false)} icon="times" /></div>
                <ProductQuickPreview prod={prod} />
            </>}
        </></Modal>

    </>)
   
}

ProductListItem.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
}

const mapStateToProps = state => {
    return ({ 
        settings: state.grocer_storefront, 
        // user: state?.grocer_storefront?.user,
    });
}
export default connect(mapStateToProps)(ProductListItem);

// export default ProductListItem;
