export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';
export const updateCategories = (payload) => ({ type: UPDATE_CATEGORIES, payload });

const default_state = { 
  categories:null
};

export const reducer = ( state=default_state, {type, payload} ) => {
  switch (type) {
    case UPDATE_CATEGORIES:
      return { ...state, categories: payload }
      break;
    default:
      return state;
  }
}

export default { nav: reducer };

// const authPersistConfig = {
//   key: 'redux_nav',
//   storage: storage,
//   blacklist: ['somethingTemporary'],
//   // whitelist: [],
// }
// export default persistReducer(authPersistConfig, reducer);
