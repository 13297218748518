import React from 'react'
import { Route, Switch } from 'react-router-dom';
import { E404, LayoutWrapper } from "Layout_v1";
// import { CheckoutSuccess } from "Layout_v1/Pages";
import MainPage from './containers';
import CheckoutSuccess from './containers/CheckoutSuccess';
import CheckoutFail from './containers/CheckoutFail';
import 'Layout_v1/styles/home.scss'

import Kz_orderCanceled from './PaymentMethods/kazang/canceled';
import Kz_orderSuccess from './PaymentMethods/kazang/success';

import { ROOT, MODULE } from './constants'


const menuArray = props => ([
    { path: `${ROOT}`, exact: true, title: 'Checkout', component: MainPage},
    { path: `${ROOT}/success`, exact: true, title: 'Checkout Success', component: CheckoutSuccess},
    { path: `${ROOT}/no-success`, exact: true, title: 'Checkout Fail', component: CheckoutFail},
    { path: `${ROOT}/kz_canceled`, exact: false, title: 'Order Canceled', component: Kz_orderCanceled },
    { path: `${ROOT}/kz_success`, exact: false, title: 'Order Success', component: Kz_orderSuccess },
    // { path: `${ROOT}/sign-in`, exact: true, title: 'Register', component: SignInPage},
]);

export const Home = props => {
    let routs = menuArray();
    return (
        <LayoutWrapper topMenu={null} menuOverride={null} className={String(MODULE).toLowerCase()}>
            <Switch>
                {routs.map((item, i) => {
                    if (!item.component) return null;
                    return <Route exact={item.exact} path={item.path} component={item.component} key={i} />
                })}
                <Route key={999} component={E404} />
            </Switch>
        </LayoutWrapper>
    )
}

export default Home;
