import React from 'react'
// import PropTypes from 'prop-types';
import { Layout, Menu } from 'antd';
import { Icon } from 'Common/components';
import { Link } from 'react-router-dom';
import { navItems } from 'Modules';

const { Sider } = Layout;
const { SubMenu } = Menu;

const RenderFullMenu = ({ menu }) => {
    console.log("RenderFullMenu()");
    
    if (!menu) return <div>Empty Menu</div>
    // var patt = new RegExp(`.*${window.location.pathname}*.`, 'i'); // patt.constructor; // >> /.*pro,*./i
    let activeMenuItem = "";
    // setting up menu selection array
    menu.forEach((element, i) => {
        if (!element.exact && element.exact < 1) {
            let patt = new RegExp(`.*${element.path}*.`, 'i');
            let result = String(window.location.pathname).search(patt);
            if (result > -1) {
                activeMenuItem += `,${i}`;
            }
        }
    });


    return (
        // <Menu theme="dark" mode="inline" defaultSelectedKeys={[`${activeMenuItem}`]}>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={activeMenuItem.split(",")}>
            {menu.map((item, i) => {
                if (item.hideNav > 0) return null;

                if (!item.subMenu) {
                    let itemProps = { title: item.title }
                    if (item.icon) itemProps = Object.assign(itemProps, { icon: <Icon anticon icon={item.icon} /> })

                    return <Menu.Item {...itemProps} key={i}>
                        {item.path && <Link to={item.path}>{item.title}</Link>}
                        {!item.path && item.title}
                    </Menu.Item>
                }

                else {
                    let menuProps = { title: item.title }
                    if (item.icon) menuProps = Object.assign(menuProps, { icon: <Icon anticon icon={item.icon} /> })

                    return (
                        <SubMenu {...menuProps} key={i}>
                            {item.subMenu.map((item, ii) => {
                                let itemProps = { title: item.title }
                                if (item.icon) itemProps = Object.assign(itemProps, { icon: <Icon anticon icon={item.icon} /> })

                                return <Menu.Item {...itemProps} key={ii}>
                                    {item.path && <Link to={item.path}>{item.title}</Link>}
                                    {!item.path && item.title}
                                </Menu.Item>
                            })}
                        </SubMenu>
                    )
                }

            })}
        </Menu>
    )
}


// const defaultMenu = [
//     { path: `/`, exact: true, icon: 'caret-right', title: 'Home', component: false },
//     { path: `/template`, exact: false, icon: 'caret-right', title: 'Template', component: false },
//     { path: `/bugs`, exact: true, icon: 'caret-right', title: 'Bugs', component: false },
// ]

export const SideMenu = props => {
    // const [collapsed, setNavCollaps] = React.useState(false);
    // const [collapsedWidth, setCollapsedWidth] = React.useState(undefined);

    const renderMenu = menu => {
        return <RenderFullMenu menu={menu || navItems} />
    }

    return (
        <Sider trigger={undefined} collapsible breakpoint="lg"
            onBreakpoint={broken => {
                props.setCollapsedWidth(broken ? 0 : undefined)
                props.setNavCollaps(broken);
            }}
            collapsedWidth={props.collapsedWidth}
            onCollapse={() => props.setNavCollaps(!props.collapsed)} // required for footer collaps button/
            collapsed={props.collapsed} className="site-sidebar">
            <div className="logo">Logo</div>
            {renderMenu(props.menuOverride)}
        </Sider>
    )
}

export default SideMenu;