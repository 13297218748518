import React from 'react'
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { connect } from "react-redux";
import { Table, Avatar, Icon } from 'Common/components'
import { Link } from 'react-router-dom';

const renderTitle = (text, record) => {
    const thumb = record.picture_thumb ? `${process.env.REACT_APP_DATA_URL}/${record.picture_thumb}` : undefined;

    return (
        <Row gutter={[12, 0]}>
            <Col flex="50px">
                <Link to={`/product/${record.slug}`}>
                    <Avatar type="product" size={40} shape="square" src={thumb} icon={<Icon icon="image" />} />
                </Link>
            </Col>
            <Col>{record.title}</Col>
        </Row>
    )
}

const RenderCart = props => {
    const { settings: { currency }, items, filter } = props;

    const columns = [
        { title: 'Product', dataIndex: 'title', render:renderTitle },
        { title: 'Price', dataIndex: 'price', align: "center", width: "60px", render:(__, rec)=>{
            return <>
                {rec.price_was > 0 && <div className='price-was'>{rec.price_was}</div>}
                <div>{rec.price}</div>
            </>
        } },
        // { title: 'Price Was', dataIndex: 'price_was', align: "center", width: '60px' },
        { title: 'Qty', dataIndex: 'cart_qty', align: "center", width: '50px' },
        { title: 'Total', dataIndex: 'total', width: '100px', align: "center", render: (text, record) => `${currency} ${record.cart_qty * record.price}` }
    ];

    let itemstoShow = [];
    if (filter) itemstoShow = items.filter(o => filter(o))
    else itemstoShow = items;

    // let canceled_items = items.filter(o => o.delivery_status == 'canceled')
    // let regular_items = items.filter(o => o.delivery_status != 'canceled')

    return (<>
        <Table
            columns={columns}
            dataSource={itemstoShow}
            pagination={false}
            rowKey={(rec) => rec.product_id}
            // rowClassName={
            //     (record) => {
            //         console.log("record.delivery_status: ", record.delivery_status)
            //         return (record.delivery_status == 'canceled') ? 'cancled-table-row' : ""
            //     }
            // }
        />
        {/* {(canceled_items && canceled_items.length>0) && <>
            <div style={{ backgroundColor: "#EEE", textAlign: "center", fontSize:"15px", fontWeight:"bold", padding:"5px", color:"#888" }}>Cancled Items</div>
            <Table
                showHeader={false}
                columns={columns}
                dataSource={canceled_items}
                pagination={false}
                rowClassName={ (record) => "cancled-table-row" }
            />
        </>} */}
    </>)
}
RenderCart.propTypes = {
    // onClose: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
}
const mapStateToProps = state => {
    return ({ settings: state.grocer_storefront, });
}
export default connect(mapStateToProps)(RenderCart);
// export default RenderCart;