import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
import WebViewPage from './WebViewPage';
// import MainPage from './containers';
export const ROOT = '/page';
export const MODULE = 'PAGES';

// addReducers({ admins: reducers });
addModule( // for web app only/excluding hoeader
    { path: `${ROOT}/blank/:slug`, component: WebViewPage, exact: 1, hideNav: 1 },
)
addModule(
    { path: `${ROOT}`, component: Home, title: "Pages", exact: 0, hideNav: 1 },
)
