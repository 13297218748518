import React from 'react'
import PropTypes from 'prop-types';
import { Empty } from 'antd';
import ProductListItem from './ProductListItem';
import ProductListItem_skeleton from 'Layout_v1/ProductListItem_skeleton';


export const ProductList = props => {

    if (props.loading) {
        return (<div className="product-list">
            {String("123456").split("").map((item, i) => {
                    return <ProductListItem_skeleton key={i} />
            })}
        </div>)
    }

    if (!props.data || props.data.length<1) return (<Empty style={{minHeight:"300px"}}
        // image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        // imageStyle={{ height: 60, }}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<span>No product found</span>}
    />)
        
        
    return (
        <div className="product-list">
            {props.data.map((item, i) => {
                return <ProductListItem prod={item} key={i} style={props.itemStyle} className={props.itemClassName} />
            })}
        </div>
    )
}

ProductList.propTypes = {
    itemStyle: PropTypes.object,
    itemClassName: PropTypes.string,
    data: PropTypes.array,
    loading: PropTypes.bool,
    // topMenu: PropTypes.array,
    // labelA: PropTypes.oneOfType([
    //             PropTypes.string,
    //             PropTypes.object
    //         ]).isRequired,
}

export default ProductList;