import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import ProductListItem from 'Layout_v1/ProductListItem';
import { Alert, message } from 'antd';
import { withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import { __error } from 'Common/scripts/consoleHelper';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ProductListItem_skeleton from 'Layout_v1/ProductListItem_skeleton';

const scrollerProps = {
    additionalTransfrom: 0, 
    arrows: true,
    autoPlaySpeed: 3000,
    centerMode: false,
    className: "",
    containerClass: "container-with-dots",
    dotListClass: "",
    draggable: true,
    focusOnSelect: false,
    infinite: true,
    itemClass: "",
    keyBoardControl: true,
    minimumTouchDrag: 80,
    pauseOnHover: true,
    renderArrowsWhenDisabled: false,
    renderButtonGroupOutside: false,
    renderDotsOutside: false,
    responsive: {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
            partialVisibilityGutter: 40
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            partialVisibilityGutter: 30
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            partialVisibilityGutter: 30
        }
    },
    rewind: false,
    rewindWithAnimation: false,
    rtl: false,
    shouldResetAutoplay: true,
    showDots: false,
    sliderClass: "",
    slidesToSlide: 1,
    swipeable: true
}

const LIST_DATA = loader('src/graphqls/product/products.graphql');


const ProductListScrollerComp = ({ filter, limit, title, client }) => {
    // const isTabletOrMobileDevice = useMediaQuery({ query: '(max-device-width: 1224px)' })
    // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
    const [products, setProducts] = useState(null);
    const [busy, setBusy] = useState(true);
    const [error, setError] = useState(null);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        // if (products || busy || error) return;
        if (!products && !loaded) getData();
    }, [filter])


    const getData = async args => {
        // console.log("getData()")
        setLoaded(true)
        // setBusy(true);
        // setError(null);

        const resutls = await client.query({ query: LIST_DATA, variables: { 
                filter: JSON.stringify(filter),
                others: JSON.stringify({ limit })
            } }).then(e => (e.data.products))
            .catch(err => {
                console.log(__error("Query Error: "), err);
                return {error:{message:"Query Error!"}}
            })
        console.log("resutls: ", resutls)
        setBusy(false);
        if (resutls.error){
            setError(resutls.error.message);
            return false;
        }

        setProducts(resutls);
    }

    if (busy) return <div className="product-list">
        {String("123456").split("").map((item, i) => {
            return <ProductListItem_skeleton key={i} />
        })}
    </div>

    if (error) return <Alert message={error} type="error" showIcon />
    if (!products) return null;



    return (<div style={{ width: "100%" }} className="products-li-scroller">
        <Carousel {...scrollerProps}>
            {/* {products.map((el, i) => {
                return <ListItem product={el} key={i} />
            })} */}
            {products.map((el, i) => {
                return <ProductListItem prod={el} className={`item_${i}`} key={i} />
            })}

        </Carousel>
    </div>)


}
ProductListScrollerComp.propTypes = {
    filter: PropTypes.object.isRequired,
    title: PropTypes.string,
    limit: PropTypes.number.isRequired,
    // labelA: PropTypes.oneOfType([
    //             PropTypes.string,
    //             PropTypes.object
    //         ]).isRequired,
}

export const ProductListScroller = withApollo(ProductListScrollerComp);
export default ProductListScroller;