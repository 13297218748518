// https://github.com/FortAwesome/react-fontawesome
// https://www.npmjs.com/package/@fortawesome/react-fontawesome
/*************
 * size => xs, lg, 6x
 * fixedWidth
 * inverse
 * listItem
 * rotation={90}
 * flip="horizontal" => horizontal, vertical, both
 * spin
 * pause
 * border
 * pull="left" => left, right
 * <FontAwesomeIcon icon="spinner" size="xs" />
 * <FontAwesomeIcon icon={['fad', 'stroopwafel']} swapOpacity />
 */

import React from 'react';
import _ from 'lodash'
import { library } from '@fortawesome/fontawesome-svg-core'
// import { Button } from './Button';
// import { fab } from '@fortawesome/free-brands-svg-icons'
import {
    faThumbsUp, faBars,
    faAngleUp, faAngleDown, faAngleLeft, faAngleRight, faBell,
    faChevronDown, faChevronUp, faChevronRight, faChevronLeft,
    faCalculator, faCalendarAlt,
    faCaretRight, faCaretLeft,
    faCogs, faCog, faComment,
    faEllipsisV, faExclamation, faExclamationTriangle,
    faCheckSquare, faSquare,
    faCheck, faTimes, faTrashAlt,
    faKey, faUser, faLightbulb,
    faLock, faLockOpen,
    faPen, faSearch,
    faShoppingBag, faShuttleVan, faTruck, faTruckLoading, faDolly, faDonate, faShippingFast, faClock,
    faTh, faThLarge, faThList,
    faUserShield, faLaptopCode, faDesktop, faSignOutAlt,
    faBoxes, faCubes,
    faUpload, faEye, faImage,
    faMinus, faPlus, faHome, faSave,
    faTags, faQuestionCircle, faEnvelope, faPhoneAlt, faAt, faMobile, faCircle, faAngleDoubleRight, faUserCircle, faAddressBook, faCartArrowDown, faCoins, faExclamationCircle, faTrophy, 
} from '@fortawesome/free-solid-svg-icons'

// import {
//     faQuestionCircle as faQuestionCircleRegular,
//     faCheckSquare as faCheckSquareRegular,
//     faSquare as faSquareRegular,
// } from '@fortawesome/free-regular-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { __error } from 'Common/scripts/consoleHelper';

library.add(
    faThumbsUp, faBars, 
    // faFileInvoiceDollar,
    faAngleUp, faAngleDown, faAngleLeft, faAngleRight, faBell, 
    faChevronDown, faChevronUp, faChevronRight, faChevronLeft,
    faCalculator, faCalendarAlt, faAngleDoubleRight,
    faCaretRight, faCaretLeft,
    faCogs, faCog, faComment,
    faEllipsisV, faExclamation, faExclamationTriangle, faExclamationCircle,
    faCheckSquare, faSquare,
    // faCheckSquareRegular, faSquareRegular, faQuestionCircleRegular
    faCheck, faTimes, faTrashAlt,
    faKey, faUser, faLightbulb,
    faLock, faLockOpen,
    faPen, faSearch, 
    faShoppingBag, faShuttleVan, faTruck, faTruckLoading, faDolly, faDonate, faShippingFast, faClock,
    faTh, faThLarge, faThList,
    faUserShield, faLaptopCode, faDesktop, faSignOutAlt,
    faBoxes, faCubes, 
    faUpload, faEye, faImage,
    faMinus, faPlus, faHome, faSave,
    faTags, faEnvelope, faPhoneAlt, faAt, faMobile, faCoins,
    faQuestionCircle, faCircle, faUserCircle, faAddressBook, faCartArrowDown, faTrophy,
)
// console.log("library: ", library.definitions.far);


function verifyIconAvailability(icon_name) {
    let iconsSolid = library.definitions.fas;
    let iconsRegular = library.definitions.far;

    if (!_.isString(icon_name)) return iconsSolid[icon_name[1]] ? true : false;

    return iconsSolid[icon_name] ? true : false;
}

/********
 * size{xs | lg | 6x}
 * icon={name}
 * fixedWidth={boolean}
 * inverse={boolean}
 * listItem={boolean}
 * rotation={number}
 * flip={horizontal | vertical | both}
 * spin={boolean} // animation
 * pulse={boolean} // animation
 * border={boolean}
 * pull={left | right}
 * className={string}
 * 
 * <Icon icon="bell" />
 */
export const Icon = React.forwardRef((_props, ref) => {
    // return <FontAwesomeIcon icon={props.icon} />
    let props = { ..._props };
    
    let className = `${props.anticon && "anticon"} ${props.className||""}`;// props.skipstyle ? "" : "anticon" + props.className || "";
    delete props.className;
    delete props.anticon;

    if (props.icon == 'my-location') return <MyLocationIcon {..._props} />

    if (!verifyIconAvailability(props.icon)) return <span ref={ref} {...props} className={`${className}`}>ICON</span>;
    return <FontAwesomeIcon ref={ref} {...props} className={`${className}`} />
    // return <FontAwesomeIcon icon="search" color="green" />
});


export default Icon;


export const MyLocationIcon = ({ width = 33, height = 33 }) => <svg xmlns="http://www.w3.org/2000/svg" width={`${width}`} height={`${height}`} viewBox="0 0 33 33">
    <path id="Icon_material-my-location" data-name="Icon material-my-location" d="M18,12a6,6,0,1,0,6,6A6,6,0,0,0,18,12Zm13.41,4.5A13.491,13.491,0,0,0,19.5,4.59V1.5h-3V4.59A13.491,13.491,0,0,0,4.59,16.5H1.5v3H4.59A13.491,13.491,0,0,0,16.5,31.41V34.5h3V31.41A13.491,13.491,0,0,0,31.41,19.5H34.5v-3ZM18,28.5A10.5,10.5,0,1,1,28.5,18,10.492,10.492,0,0,1,18,28.5Z" transform="translate(-1.5 -1.5)" />
</svg>
