export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const updateSettings = payload => ({ type: UPDATE_SETTINGS, payload });

export const USER_LOGIN = 'USER_LOGIN';
export const loginUser = payload => ({ type: USER_LOGIN, payload: payload });
export const USER_LOGOUT = 'USER_LOGOUT';
export const logoutUser = payload => ({ type: USER_LOGOUT, payload: payload });
export const USER_UPDATE = 'USER_UPDATE';
export const updateUser = payload => ({ type: USER_UPDATE, payload: payload });
export const USER_TOKEN = 'USER_TOKEN';
export const updateToken = payload => ({ type: USER_TOKEN, payload: payload });


