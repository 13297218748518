import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Button, Icon, Loader, Table } from 'Common/components';
import { Row, Col, DatePicker, Space, Alert } from 'antd' 
import moment from 'moment'
import MediaQuery, { useMediaQuery } from 'react-responsive'
import { utcToDate } from 'Common/scripts/Functions'
import { connect } from "react-redux";
import { defaultTimeZone, default_timeslot_days_to_show } from 'configs'
import { useWindowSize } from 'Common/hooks';

import 'Layout_v1/styles/timeslot-selector.scss'


const RenderSlot = props => {
    const { maxDate, settings, store_settings, data, date, onSlotClick, selectedSlot, availability_days } = props;
    // let maxDate = utcToDate(); //.add(0, 'd');
    // console.log("store_settings", Number(eval(store_settings.min_slot_gap)))
    const min_slot_gap = !isNaN(eval(store_settings.min_slot_gap)) ? eval(store_settings.min_slot_gap) : (settings.timeslot_pre_gap || 5);

    const dayAbbr = moment(date).format('ddd').toLowerCase();
    const thisDayData = data[dayAbbr];

    let notAvailable = false;
    let expired = false;
    let available = true; // max_order_limit>0 && (max_order_limit < 1 || max_order_limit > totalOrders);
    let selected = false;

    // const isTabletOrMobileDevice = useMediaQuery({ query: '(max-device-width: 1000px)' })
    // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

    const [screenSize] = useWindowSize();
    const isTabletOrMobileDevice = screenSize.width < 850;


    let info = [];

    let blocked_dates = (!props.blocked_dates || props.blocked_dates == 'null' || props.blocked_dates == null) ? null : props.blocked_dates.split(",");
    
    let max_order_limit = thisDayData && thisDayData.max_order_limit ? thisDayData.max_order_limit : 0; // Number(props.max_order_limit || 0);
    let totalOrders = thisDayData && thisDayData.totalOrders ? thisDayData.totalOrders : 0; //(props.totalOrders || 0);
    let thisDay = moment(date);//.format('DD-MM-YYYY');
    
    if (available) available = blocked_dates ? blocked_dates.indexOf(thisDay.format('DD-MM-YYYY'))<0 : true;

    // if (availability_days && !availability_days.includes(moment().format("ddd"))) available = false;
    if (availability_days && !availability_days.includes(thisDay.format("ddd").toLowerCase())) available = false;

    const _onSlotClick = args => {
        let _props = { ...props }
        delete _props.onSlotClick;
        delete _props.selectedSlot;
        delete _props.__typename;
        delete _props.onSlotClick;
        onSlotClick(_props)
    }

    if (date && selectedSlot) {
        selected = moment(selectedSlot.date).format("DD-MM-YYYY") == moment(date).format("DD-MM-YYYY");
        if (selected) selected = selectedSlot.end_time == props.end_time;
        if (selected) selected = selectedSlot.start_time == props.start_time;
        if (selected) selected = selectedSlot._id == props._id;
    }

    if (props.time_range_utc){
        let time_range_utc = JSON.parse(props.time_range_utc);

        let utc_t1 = moment(time_range_utc[0], "DD-MM-YYYYTHH:mm");
        let utc_t2 = moment(time_range_utc[1], "DD-MM-YYYYTHH:mm");

        // set thisDay with slot times
        let new_t1 = thisDay.clone().set({ hour: utc_t1.format("HH"), minute: utc_t1.format("mm")});
        let new_t2 = thisDay.clone().set({ hour: utc_t2.format("HH"), minute: utc_t2.format("mm")});

        // add time gap
        expired = new_t1.isBefore(utcToDate().add(min_slot_gap, 'm'));
        // expired = new_t1.isBefore(utcToDate().add(settings.timeslot_pre_gap || 5, 'm'));
        // expired = new_t1.isBefore(utcToDate().add(1, 'm'));

        // check if slot is after the max date
        if (maxDate){
            let _d1 = new_t1.set({ hour: '00', minutes: '00', seconds: '00' });
            let _d2 = maxDate.set({ hour: '00', minutes: '00', seconds: '00' });
            notAvailable = _d2.isBefore(_d1);
        }
    }

    let bookingClsoed = (max_order_limit > 0 && max_order_limit <= totalOrders) ? true : false;
    let alt_info = "";// thisDayData ? `${totalOrders | '0'} / ${thisDayData.max_order_limit | '0'}` : undefined;

    
    if (bookingClsoed) {
        return <>{info}<Button title={alt_info} size={isTabletOrMobileDevice ? "medium" : "large"} block disabled>Clsoed.</Button></>
    }
    if (notAvailable) {
        return <>{info}<Button title={alt_info} size={isTabletOrMobileDevice ? "medium" : "large"} block disabled>Not Available</Button></>
    }
    if (expired || !props._id || notAvailable) {
        return <>{info}<Button title={alt_info} size={isTabletOrMobileDevice ? "medium" : "large"} block disabled>Closed</Button></>
    }
    else {
        return <>{info}
            {/* <div>{availability_days}</div>
            <div>{thisDay.format("ddd").toLowerCase()}</div> */}
            <Button title={alt_info} size={isTabletOrMobileDevice ? "medium" : "large"} block theme={selected ? "orange" : "green"} onClick={selected ? null : _onSlotClick} disabled={!available}>
                <>
                    {/* {available && <><Icon icon={['far', selected ? "check-square" : "square"]} /> </>} */}
                    {/* {available && <><Icon icon={['fas', selected ? "check-square" : "square"]} /> </>} */}
                    {available ? (selected ? "Selected" : "Available") : "Booked"}
                </>
            </Button>
            </>
    }

}




export class TimeSlotSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: this.props.defaultDate || moment(), 
            columns: this.props.columns, 
            data: this.props.data, 
            selectedSlot: null,
            week: 1,
            dataColumns: props.dataColumns, 
            dataRows: props.dataRows,
            busy:true,
        };
        this.onPrevClick = this.onPrevClick.bind(this);
        this.onNextClick = this.onNextClick.bind(this);
    }

    componentDidMount(){
        this.setState({ busy:true })
        this.props.getWeek(this.state.week).then(r => {
            this.setState({ ...r, busy: false })
        });
    }

    onSlotClick = args => {
        // this.setState({ selectedSlot:args})
        this.props.onSlotClick(args);
    }

    onPrevClick = args => {
        if (this.state.week <= 1) return;
        // this.onDateChange(this.state.date.subtract(1, 'months'))
        this.setState({ busy:true })
        this.props.getWeek(this.state.week - 1).then(weekData=>{
            this.setState({ week: this.state.week - 1, ...weekData, busy: false })
        });
    }

    onNextClick() {
        if (this.state.week >= 4) return;
        // this.onDateChange(this.state.date.add(1, 'months'))
        // this.setState({ week: this.state.week + 1 })
        this.setState({ busy: true })
        this.props.getWeek(this.state.week + 1).then(weekData=>{
            this.setState({ week: this.state.week + 1, ...weekData, busy: false })
        });
    }    

    render() {
        const { dataColumns, dataRows, loading, week, busy } = this.state;
        const { selectedSlot, availability_days, store_settings } = this.props;

        // let maxDate = utcToDate().add(this.props.settings.timeslot_days_to_show || 3, 'd');
        let maxDate = utcToDate().add(this.props.settings.timeslot_days_to_show || default_timeslot_days_to_show, 'd');
        // let maxDate = utcToDate().add(10, 'd');

        if (!dataColumns) return null; // <Alert message="No data" type='error' />
        
        const _columns = dataColumns.map((item, i) => {
            /// header column
            if (item.dataIndex == "time_range") return { ...item, width: 80, fixed: true, align:'right', className: "time_col-", }

            return { 
                ...item, 
                width:150,
                render: (text, rec) => {
                    return <RenderSlot 
                        availability_days={availability_days}
                        {...text} 
                        user={this.props.user}
                        store_settings={store_settings}
                        settings={this.props.settings} 
                        selectedSlot={selectedSlot} 
                        onSlotClick={this.onSlotClick} 
                        data={rec} 
                        date={item.date} 
                        maxDate={maxDate} 
                    />
                }
            }
        })
        
        return (
            <Loader loading={loading}>
                {/* <p>maxDate: {maxDate.format("DD-MM-YYYY HH:mm")}</p> */}

                <div className="timeslot-selector">
                    <Row className="header">
                        <Col>
                            <MediaQuery minDeviceWidth={1000}>
                                {(matches) => matches ? 
                                    <Button size="large" className="gray" disabled={(week <= 1)} onClick={this.onPrevClick} style={{ width: "170px" }}><Icon style={{marginRight:"10px"}} icon="angle-left" /> Previous Slots</Button>
                                    :
                                    <Button size="large" className="gray" disabled={(week <= 1)} onClick={this.onPrevClick} style={{ width: "50px" }}><Icon icon="angle-left" /></Button> 
                                }
                            </MediaQuery>
                        </Col>
                        <Col flex="auto" align="center">
                            <div style={{ fontSize: "18px", color:"#999"}}>
                                <Icon size="1x" color="#999" icon="calendar-alt" /> {moment().add(week-1, 'weeks').format("MMM-YYYY")}
                            </div>
                        </Col>
                        <Col>
                            <MediaQuery minDeviceWidth={1000}>
                                {(matches) => matches ?
                                    <Button size="large" className="gray" disabled={week >= 2} onClick={this.onNextClick} style={{ width: "170px" }}>Next Slots <Icon style={{ marginLeft: "10px" }} icon="angle-right" /></Button>
                                    :
                                    <Button size="large" className="gray" disabled={week >= 2} onClick={this.onNextClick} style={{ width: "50px" }}><Icon icon="angle-right" /></Button>
                                }
                            </MediaQuery>
                        </Col>
                    </Row>
                    
                    <Table
                        // components={Loader}
                        className="timeslotTable"
                        columns={_columns}
                        dataSource={dataRows}
                        rowKey={record => record.key}
                        pagination={false}
                        scroll={{ x: 'auto' }}
                        bordered={false}
                    />
                    
                </div>
            </Loader>
        )
    }
}

TimeSlotSelector.propTypes = {
    availability_days: PropTypes.array,
    timeslot: PropTypes.object,
    defaultDate: PropTypes.object,
}

const mapStateToProps = state => {
    return ({ 
        settings: state.grocer_storefront,
        user: state.user
    });
}
// const mapDispatchToProps = (dispatch, ownProps) => ({
//     timeslotSelect: (payload) => dispatch(timeslotSelect(payload)),
// })
export default connect(mapStateToProps)(TimeSlotSelector);

// export default TimeSlotSelector;