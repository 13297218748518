import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
import reducers from './redux/reducer';
import { ROOT, MODULE } from './constants'


// addReducers(reducers, { whitelist: ['order'] });
addReducers(reducers);
// addReducers(reducers);
addModule(
    { path: `${ROOT}`, component: Home, title: "Checkout", exact: 0, hideNav: 1 },
)
