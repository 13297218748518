import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
// import {Icon} from 'common/components'
export const ROOT = '/search';
export const MODULE = 'SEARCH';


// addReducers({ admins: reducers });
addModule(
    { path: `${ROOT}/:kw`, component: Home, title: "Search", exact: 0, hideNav: 0 },
    // { path: `${ROOT}`, component: Home, title: "Search", exact: 0, hideNav: 0 },
)
