import React, { useState } from 'react'
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import { Alert } from 'antd';
import { Button } from 'Common/components'
import { FormComponent, FormField, FormFieldGroup, rules, composeValidators } from 'Common/components/Form'
import { __error } from 'Common/scripts/consoleHelper'
// import { Redirect } from 'react-router-dom';

const ADD_RECORD = loader('src/graphqls/userDeletionRequests/addUserDeletionRequests.graphql');

const AccountDeletionRequestForm = ({ client }) => {
    const [errorMessage, set_errorMessage] = useState(null);
    const [success, set_success] = useState(false);

    const onSubmit = async(values) => {
        const input = {
            email: values.email,
            password: values.password,
        }

        let results = await client.mutate({ mutation: ADD_RECORD, variables: { input } })
            .then(r => (r?.data?.addUserDeletionRequests)).catch(err => {
                console.log(__error("Query error!"), err);
                return { error: { message: "Query Error!" } };
            });
        
        if (!results || results?.error?.message){
            set_errorMessage((results && results?.error?.message) || "Invalid response!");
            return false;
        }

        if (results.success){
            set_success(true)
        }

        return false;

    }

    if (success){
        return (<div style={{ padding:"50px" }}>
            <p align="center">You have requested for deletion of your account, a verification email has been sent to you.</p>
            <p align="center">Please check your inbox and proceed.</p>
            {/* <p>Request successfull, redirecting...</p>
            <Redirect to={`/user/profile/verify_deletion_request`} /> */}
        </div>)
    }

    return (<>
        <div className="h-center" style={{ marginBottom: "50px" }}>
            <div style={{ width: "100%", maxWidth: "350px" }}>
                <h3 style={{ textAlign: "center" }}>Account Deletion Request</h3>

                <FormComponent onSubmit={onSubmit} id='AccountDeletionRequestForm' style={{ width: "100%", maxWidth: "350px" }}
                    form_render={formProps => {
                        const { values, dirty, errors, submitting, invalid } = formProps;

                        return (<>
                            {errorMessage && <Alert showIcon type="error" message={errorMessage} description={null} />}

                            <FormField type="text" name="email" label="Email Address (Login Use)" validate={composeValidators(rules.required, rules.isEmail)} compact />
                            <FormField type="password" name="password" label="Password" validate={composeValidators(rules.required, rules.minChar(3))} compact />

                            <div style={{ height:"20px" }} />

                            <Button type="primary" htmlType="submit" loading={submitting} disabled={!dirty || invalid} block size="large">Send Deletion Request</Button>
                        </>)
                    }}
                />

            </div>
        </div>

    </>)
}
export default withApollo(AccountDeletionRequestForm);
