import React from 'react'
import PropTypes from 'prop-types';
import { Row, Col, Breadcrumb, message, Divider } from 'antd';
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { E404, ProductGalleryScroller } from 'Layout_v1';
import ProductPageSkeleton from 'Layout_v1/ProductPageSkeleton';
import AddToCartButton from 'Layout_v1/AddToCartButton';
import { Icon, Button, ProductListScroller, NotifyMeButton, DevBlock } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper'
// import { noImage } from 'configs/constants';
import { appText } from 'configs';
import { Link } from 'react-router-dom';


const GET_PROD = loader('src/graphqls/product/productBySlug.graphql');


class ProductDetails extends React.Component {
  componentDidMount() {
    if (!this.props.inline){
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  }

  productDisabled(){
    const prod = this.props.productBySlug;
    if (!prod) return null;

    if (prod.not_serving == "yes") return <div className='not-serving' />
    if (prod.available_qty < 1) return <div className='sold-out' />

    return null;
  }
   
  render() {
    const { loading, productBySlug, inline, settings: { currency } } = this.props;

    if (loading) return <ProductPageSkeleton />
    if (!loading && (!productBySlug || productBySlug.error || !productBySlug._id)) return <E404 title="Product Not Found!" />

    const prod = productBySlug;

    if (prod.category.status != 'enabled') return <p align="center">Product Unavailable</p>

    // const thumb = prod.picture_thumb ? `${process.env.REACT_APP_DATA_URL}/${prod.picture_thumb}` : undefined;
    const thumb = prod.picture_thumb ? `${prod.picture_thumb}` : undefined;
    // const img = prod.picture ? `${process.env.REACT_APP_DATA_URL}/${prod.picture}` : undefined;
    const img = prod.picture ? `${process.env.REACT_APP_DATA_URL}/${prod.picture}` : undefined;

    return (<>
      {prod && prod._id && <Helmet>
        <title>{appText.titlePrefix} - {prod.seo_title || `${prod.category.title} - ${prod.title}`}</title>
        <meta name="description" content={prod.seo_desc || prod.seo_title} />
      </Helmet>}

      <div className="product-page">
        {!inline && <>
          <Button onClick={() => this.props.history.goBack()} type="default" className="back-button" size="large"><Icon icon="angle-left" /> Back</Button>
          <div style={{ height: '10px' }} />
        </>}

        <div className="prod-body">
          {/* <p>{prod.store.title}</p> */}

          <Row className="prod-body-contents">
            <Col className="prod-body-gallery" span={24} md={12} align="center">
              {this.productDisabled()}
              <ProductGalleryScroller data={[{ thumb: prod.picture_thumb, img: prod.picture }, ...prod.gallery]} hideScrollbar showDirectPop={false} />
            </Col>

            <Col span={24} md={12} className="prod-body-contents-inner">
              <p style={{fontSize:"18px"}}>View all products by: <Link to={`/store/${prod.store.slug}`}>{prod.store.title}</Link></p>

              <div className="prod-title">{prod.title}</div>

              {prod.attributes && <div className="prod-attributes">
                {prod.attributes.filter(o => (o.show_in_store > 0)).map((o, i) => (<span key={i} className="attribute-item">{i > 0 ? ', ' : null}{o.value}{o.code}</span>))}
              </div>}

              {prod.price_was > 0 && <div><div className="price-cut">Price Cut</div></div>}
              <div className="prod-price">
                {prod.price_was > 0 && <div className="prod-price-was">{currency} {prod.price_was}</div>}
                <div className="prod-price-is">{currency} {prod.price}</div>
              </div>
              {prod.available_qty > 0 && <AddToCartButton prod={prod} qty={0} style={{ marginBottom: "20px" }} />}
              {prod.available_qty < 1 && <span style={{marginBottom:"10px", display:"inline-block"}}><NotifyMeButton prod={prod} user={this.props.user} /></span>}

              {prod.details &&
                <div className="prod-details">
                  <div className="prod-details-title">Product details for {prod.title}</div>
                  <div className="prod-details-contents">
                    <span dangerouslySetInnerHTML={{ __html: prod.details }} />
                  </div>
                </div>
              }

            </Col>
          </Row>

        </div>

      </div>

      {!inline && <>
        <div style={{ height: "20px" }} />
        <Divider><h3>Other products by this store</h3></Divider>
        <ProductListScroller
          filter={{ store_id: Number(prod.store_id), featured: "yes" }}
          // filter={{}}
          limit={10}
        />
      </>}

      <div style={{ height:"20px" }} />

    </>)

  }

}
ProductDetails.propTypes = {
  inline: PropTypes.bool
  // prop: PropTypes.type.isRequired
  // onEditRecord: PropTypes.func.isRequired
}
const WithApollo = compose(

  graphql(GET_PROD, {
    options: ({ match, slug }) => {
      return {
        variables: { slug: slug || match.params.slug },
        fetchPolicy: "no-cache", //no-cache, cache-first, network-only, cache-only, cache-and-network
      };
    },
    props: ({ ownProps, data }) => {
      const { loading, productBySlug, error } = data;
      if (error) console.log(__error("error"), error);

      return { loading, productBySlug, queryErrors: error, }
    },
  }),


)(ProductDetails);

const mapStateToProps = state => {
  return ({ 
    settings: state.grocer_storefront, 
    user: state?.grocer_storefront?.user,
  });
}
export const ProductDetailsPage = connect(mapStateToProps)(WithApollo);


const MainPage = (props) => {
  return (<>
    <ProductDetailsPage {...props} />
  </>)
}

export default MainPage;









export const ProductQuickPreviewComp = ({ prod, settings: { currency } }) => {
  // const thumb = prod.picture_thumb ? `${process.env.REACT_APP_DATA_URL}/${prod.picture_thumb}` : undefined;
  // const img = prod.picture ? `${process.env.REACT_APP_DATA_URL}/${prod.picture}` : undefined;
  const galleryData = prod.picture ? [{ thumb: prod.picture_thumb, img: prod.picture }] : [{ 
    thumb: `${process.env.REACT_APP_TEMPLATE_ASSETS}/no-image-available.png`, img: `${process.env.REACT_APP_TEMPLATE_ASSETS}/no-image-available.png` }];

  if (prod.gallery && prod.gallery.length>0) galleryData.concat(prod.gallery)

  const productDisabled = () => {
    if (!prod) return null;

    if (prod.not_serving == "yes") return <div className='not-serving' />
    if (prod.available_qty < 1) return <div className='sold-out' />

    return null;
  }



  return (<div className='product-page'>
    <div className="prod-body">
      <Row className="prod-body-contents">
        <Col className="prod-body-gallery" span={24} md={12} align="center">
          {/* {prod.available_qty < 1 && <div className='sold-out' />} */}
          {productDisabled()}
          <ProductGalleryScroller data={galleryData} hideScrollbar showDirectPop={false} />
        </Col>

        <Col span={24} md={12} className="prod-body-contents-inner">
          <p style={{ fontSize: "18px" }}>View all products by: <Link to={`/store/${prod.store.slug}`}>{prod.store.title}</Link></p>

          <div className="prod-title">{prod.title}</div>

          {prod.attributes && <div className="prod-attributes">
            {prod.attributes.filter(o => (o.show_in_store > 0)).map((o, i) => (<span key={i} className="attribute-item">{i > 0 ? ', ' : null}{o.value}{o.code}</span>))}
          </div>}

          {prod.price_was > 0 && <div className="price-cut">Price Cut</div>}
          <div className="prod-price">
            {prod.price_was > 0 && <div className="prod-price-was">{currency} {prod.price_was}</div>}
            <div className="prod-price-is">{currency} {prod.price}</div>
          </div>
          {prod.available_qty > 0 && <AddToCartButton prod={prod} qty={0} style={{ marginBottom: "20px" }} />}
          {prod.available_qty < 1 && <span style={{ marginBottom: "10px", display: "inline-block" }}><NotifyMeButton prod={prod} /></span>}

          {prod.details &&
            <div className="prod-details">
              <div className="prod-details-title">Product details for {prod.title}</div>
              <div className="prod-details-contents">
                <span dangerouslySetInnerHTML={{ __html: prod.details }} />
              </div>
            </div>
          }

        </Col>
      </Row>

    </div>
  </div>)
}
const mapStateToProps2 = state => {
  return ({ 
    settings: state.grocer_storefront, 
    user: state?.grocer_storefront?.user,
  });
}
export const ProductQuickPreview = connect(mapStateToProps2)(ProductQuickPreviewComp);
