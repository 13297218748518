import React, { Component } from 'react'
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { message, Alert, Row, Col } from 'antd';
import { connect } from "react-redux";
import { Redirect, Link } from 'react-router-dom';
import { Button } from 'Common/components'
import { FormComponent, FormField, rules, composeValidators } from 'Common/components/Form'
import { Form as Finalform } from 'react-final-form';
import { __error } from 'Common/scripts/consoleHelper'
import { updateUser } from 'Store/grocer_storefront.actions'
import { sleep } from 'Common/scripts/Functions';

const SEND_REQUEST = loader('src/graphqls/user/sendPasswordResetCode.graphql');
const VERIFY_REQUEST = loader('src/graphqls/user/verifyPasswordResetCode.graphql');
const RESET_PWD = loader('src/graphqls/user/resetPassword.graphql');

const SendCodeForm = ({ onSubmit }) => {

    return(<>
        <FormComponent onSubmit={onSubmit} id='SendCodeForm' style={{ width: "100%", margin: "0px" }} fields={{}}
            render={({ form, handleSubmit, submitting, values, invalid }) => {
                return (<>
                    <form id="SendCodeForm" size={'small'}
                        onSubmit={event => {
                            if (invalid) {
                                message.error("Invalid submission! Please verify your input.");
                                // moveToErrorPosition(errors)
                            }
                            handleSubmit(event);
                        }}>
                        <h4>Forgot Password?</h4>
                        <p>Eneter your registered email address below</p>
                        <Row gutter={[12, 12]}>
                            <Col flex="auto"><FormField wrapperStyle={{ margin: "0px" }} type="email" name="email" placeholder="Your email address" validate={rules.isEmail} /></Col>
                            <Col><Button type="primary" htmlType="submit" loading={submitting} disabled={invalid || submitting} theme="blue" block size="large" style={{ margin: "0" }}>Next</Button></Col>
                        </Row>
                    </form>
                </>)
            }}
        />
    </>)
}

const VerifyCodeForm = ({ onSubmit }) => {
    return(<>
        <h4>Check your messages</h4>
        <p>We've sent a message to email with a 4 digit code in it.</p>
        
        <FormComponent onSubmit={onSubmit} id='VerifecationForm' style={{ width: "100%", margin: "0px" }} fields={{}}
            render={({ form, handleSubmit, submitting, values, invalid }) => {
                return (<>
                    <form id="VerifecationForm" size={'small'}
                        onSubmit={event => {
                            if (invalid) {
                                message.error("Invalid submission! Please verify your input.");
                                // moveToErrorPosition(errors)
                            }
                            handleSubmit(event);
                        }}>
                        <Row gutter={[12, 12]}>
                            <Col flex="auto"><FormField wrapperStyle={{ margin: "0px" }} type="text" name="code" placeholder="Enter Code" validate={rules.required} /></Col>
                            <Col><Button type="primary" htmlType="submit" loading={submitting} disabled={invalid || submitting} theme="blue" block size="large" style={{ margin: "0" }}>Next</Button> </Col>
                        </Row>
                    </form>
                </>)
            }}
        />

        {/* <FormComponent onSubmit={props.onSubmit} id='VerifecationForm' hideDevBlock={true} style={{ margin: "0px" }} fields={{}}><>
            <Row gutter={[12, 12]}>
                <Col flex="auto"><FormField wrapperStyle={{ margin: "0px" }} type="text" name="code" placeholder="Enter Code" validate={rules.required} /></Col>
                <Col><Button type="primary" htmlType="submit" theme="blue" block size="large" style={{ margin: "0" }}>Next</Button> </Col>
            </Row>
        </></FormComponent> */}


        <p>Not got a code? <Link to="/user/forgot-password"><b>Request a new code</b></Link></p>
        <p style={{ fontStyle:"italic" }}>
            * Your code may take a few moments to arrive. 
            <br />- For email, check your Junk folder. 
            <br />- Did you use the email address you use for your Grocer account?
        </p>

    </>)
}

const ResetForm = props => {
    return(<>
        <h4>Reset Password</h4>
        <p>Please enter your new password below.</p>

        <FormComponent onSubmit={props.onSubmit} id='ResetPAsswordForm' hideDevBlock={true} style={{ margin: "0px" }} fields={{}}><>
            <FormField type="password" name="pwd" placeholder="Password" validate={rules.required} />
            <FormField type="password" name="confirm_pwd" placeholder="Confirm Password" validate={composeValidators(rules.required)} />
            <p align="center">
                <Button type="primary" htmlType="submit" theme="blue" style={{width:"200px", margin:"20px", alignSelf:"center"}} size="large">Reset</Button>
            </p>
        </></FormComponent>
    </>)
}


const ForgotPassword = props => {
    // console.log("ForgotPassword()", props);
    const [response, setResponse] = React.useState(null);
    const [code, setCode] = React.useState(null);
    const [requestCompleted, setRequestCompleted] = React.useState(false);
    const step = props.match.params.step;

    const onEmailSubmit = async ({ email }) => {
        // console.log("onEmailSubmit()", email);
        setResponse(null)
        let resutls = await props.sendPasswordResetCode(email).then(r => (r.data.sendPasswordResetCode))
        .catch(err=>{
            console.log(__error("Error: "), err)
            return { error: { message:"Unable to process your request!" } }
        })

        if (resutls.error){
            setResponse({ error: resutls.error.message })
        }else{
            message.success(resutls.success.message);
            props.history.push('/user/forgot-password/verify');
        }

        return false;
    }

    const onVerificatonCodeSubmit = async ({code}) => {
        // console.log("onVerificatonCodeSubmit()", code);
        setResponse(null)
        let results = await props.verifyPasswordResetCode(code)
        .then(r => (r.data.verifyPasswordResetCode))
        .catch(err => {
            console.log(__error("Error: "), err)
            return { error: { message:"Unable to process your request!" } }
        })

        if (results.error){
            setResponse({ error: results.error.message })
        }else{
            message.success(results.success.message);
            setCode(code);
        }

        return false;
    }

    const onResetPassword = async ({ pwd, confirm_pwd }) => {
        // console.log(`onResetPassword(${pwd}, ${confirm_pwd})`);
        setResponse(null)

        if (pwd!==confirm_pwd){
            // message.error("Password missmatch");
            setResponse({ error: "Password missmatch" })
            return;
        }

        let results = await props.resetPassword(pwd, code).then(r => (r.data.resetPassword))
        .catch(err => {
            console.log(__error("Error: "), err)
            return { error: { message: "Unable to process your request!" } }
        })

        if (results.error) {
            setResponse({ error: results.error.message })
        } else {
            message.success(results.success.message);
            setRequestCompleted(true);
        }

        return false;
        
    }
    
    return(<Row>
        <Col flex="auto"></Col>
        <Col flex="450px">
            <div style={{ width:"100%", border: "1px solid #EEE", padding:"20px 20px 10px 20px", marginTop:"50px" }}>
                {requestCompleted && <Alert message={<h3>Password Reset Successfull</h3>} description={<>
                    <div>You can now <Link to="/user/sign-in">log in</Link> using your new password</div>
                </>} type="success" showIcon />}

                {!requestCompleted && <>
                    {response && response.error && <Alert message={response.error} type="error" showIcon />}
                    {response && response.success && <Alert message={response.success} type="success" showIcon />}
                    
                    {!step && <SendCodeForm onSubmit={onEmailSubmit} />}
                    {!code && step == 'verify' && <VerifyCodeForm onSubmit={onVerificatonCodeSubmit} />}
                    {code && <ResetForm onSubmit={onResetPassword} />}
                </>}
            </div>
        </Col>
        <Col flex="auto"></Col>
    </Row>)
}

const WithApollo = compose(
    
    graphql(SEND_REQUEST, {
        props: ({ mutate }) => ({
            sendPasswordResetCode: (email) => mutate({
                variables: { email }
            }),
        })
    }),

    graphql(VERIFY_REQUEST, {
        props: ({ mutate }) => ({
            verifyPasswordResetCode: (code) => mutate({
                variables: { code }
            }),
        })
    }),

    graphql(RESET_PWD, {
        props: ({ mutate }) => ({
            resetPassword: (pwd, code) => mutate({
                variables: { pwd, code }
            }),
        })
    }),

)(ForgotPassword);
// const WithApollo = withApollo(PhoneVerification)

const mapStateToAvatarPopProps = state => {
    return ({ 
        user: state?.grocer_storefront?.user,
    });
}
const mapAvatarPopDispatchToProps = (dispatch, ownProps) => ({
    updateUser: (payload) => dispatch(updateUser(payload)),
})
export default connect(mapStateToAvatarPopProps, mapAvatarPopDispatchToProps)(WithApollo);
// export default WithApollo;
