import React from 'react'
import { Result, Button } from 'antd';


export const E404 = props => {
    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button type="primary" href="/">Back Home</Button>}
            {...props}
        />
    )

    // return (
    //     <div>
    //         <div>{window.location.href}</div>
    //         <div>{props.code || "Error 404"}</div>
    //         <div>Sorry, the page you visited does not exist</div>
    //     </div>
    // )
}

export default E404;
