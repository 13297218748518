import React from 'react'
// import { Icon, Button, ToggleButton } from 'Common/components';
import { Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { E404, LayoutWrapper } from "Layout_v1";
import { ROOT } from './'; 

import IconPage from './Pages/IconPage';
// import HomePage from './Pages/Home';
// import CategoryPage from './Pages/Category';
// import RegisterPage from './Pages/Register';
// import SignInPage from './Pages/SignIn';
// import TimeSlotSelector from './Pages/TimeSlotSelector';
// import HomePage from 'Layout_v1/Pages/Home';
// import CategoryPage from 'Layout_v1/Pages/Category';
// import RegisterPage from 'Layout_v1/Pages/Register';
// import SignInPage from 'Layout_v1/Pages/SignIn';
// import TimeSlotSelector from 'Layout_v1/Pages/TimeSlotSelector';
// import CheckoutPage from 'Layout_v1/Pages/Checkout';
// import CheckoutSuccessPage from 'Layout_v1/Pages/DEL-CheckoutSuccess';

const menuArray = props => ([
    { path: `${ROOT}`, exact:true, title: 'Icons', component:IconPage},
    // { path: `${ROOT}/Home`, exact: true, title: 'Home Page', component: HomePage},
    // { path: `${ROOT}/category`, exact: true, title: 'Category Page', component: CategoryPage},
    // { path: `${ROOT}/register`, exact: true, title: 'Register', component: RegisterPage},
    // { path: `${ROOT}/sign-in`, exact: true, title: 'SignIn', component: SignInPage},
    // { path: `${ROOT}/timeslot-selector`, exact: true, title: 'Timeslot Selector', component: TimeSlotSelector},
    // { path: `${ROOT}/checkout`, exact: true, title: 'Checkout Page', component: CheckoutPage},
    // { path: `${ROOT}/checkout-success`, exact: true, title: 'Checkout Success', component: CheckoutSuccessPage},
]);

export const Home = props => {
    let routs = menuArray(); 

    const handleClick = args => {
        console.log("handleClick: ", args);
    }

    return (
        <LayoutWrapper menuOverride={null} >
            <div style={{position:"relative", top:"-50px"}}>
                <Menu onClick={handleClick} selectedKeys={[]} mode="horizontal" theme="dark" style={{padding: 0, lineHeight:"40px"}}>
                    {routs.map((item, i) => {
                        return (<Menu.Item key={i}>
                            <Link to={item.path}>{item.title}</Link>
                        </Menu.Item>)
                    })}
                </Menu>
            </div>

            <Switch>
                {/* <Route exact={true} path={`${ROOT}/icons`} component={IconPage} /> */}
                {routs.map((item, i) => {
                    if (!item.component) return null;
                    return <Route exact={item.exact} path={item.path} component={item.component} key={i} />
                })}
                <Route key={999} component={E404} />
            </Switch>

        </LayoutWrapper>
    )

}

export default Home;
