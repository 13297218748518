import React, { Component } from 'react'
import { Carousel as _Carousel } from 'react-responsive-carousel';
import './carousel.css'


export const CarouselItem = props => {
    if (props.url)
        return <div style={props.style} className={`carousel-item ${props.className}`}><a href={props.url}><img alt={props.img} width="100%" src={props.img} /></a></div>
    else
        return <div style={props.style} className={`carousel-item ${props.className}`}><img width="100%" src={props.img} alt={props.img} /></div>
}


export const Carousel = props => {
    const default_settings = {
        autoPlay: true,
        interval: 2500,
        renderThumbs: () => null,
        infiniteLoop: true,
        // swipeable: true, swipeScrollTolerance:5,
    }

    const _props = { ...default_settings, ...props };

    if (!_props.children || _props.children.length < 1) return null;

    if (_props.children.length > 1) {
        return <_Carousel {..._props} />
    }
    else {
        return <div style={props.style} className={`simple-banner ${props.className}`} >{_props.children}</div>
    }

}

export default Carousel;