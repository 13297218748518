export const TIMESLOT_SELECT = 'TIMESLOT_SELECT';
export const ADDRESS_SELECT = 'ADDRESS_SELECT';
export const COUPON_SELECT = 'COUPON_SELECT';
export const ORDER_NOTE = 'ORDER_NOTE';
export const STATUS_UPDATE = 'STATUS_UPDATE';
export const ORDER_FLUSH = 'ORDER_FLUSH';
export const SET_DELIVERY_CHARGES = 'SET_DELIVERY_CHARGES';
// export const UPDATE_INVOICE = 'UPDATE_INVOICE';

export const timeslotSelect = payload => ({ type: TIMESLOT_SELECT, payload: payload });
export const addressSelect = payload => ({ type: ADDRESS_SELECT, payload: payload });
export const couponSelect = payload => ({ type: COUPON_SELECT, payload: payload });
export const orderNote = payload => ({ type: ORDER_NOTE, payload: payload });
export const statusUpdate = payload => ({ type: STATUS_UPDATE, payload: payload });
export const flushOrder = payload => ({ type: ORDER_FLUSH, payload: payload });
export const set_delivery_charges = payload => ({ type: SET_DELIVERY_CHARGES, payload: payload });
// export const updateInvoice = payload => ({ type: UPDATE_INVOICE, payload: payload });
