import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Steps, Row, Col, Divider, message, Alert, Input } from 'antd';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { __error } from 'Common/scripts/consoleHelper';
import { E404 } from 'Layout_v1'
import { Button, Icon, Loader, DevBlock } from 'Common/components'
import TimeSlotSelector from '../components/TimeSlotSelector'
import Checkout from '../components/Checkout'
import AddressSelect from '../components/AddressSelect'
import InvoiceVerification from '../components/InvoiceVerification'
import GetLoggedInUserData from 'Modules/User/containers/GetLoggedInUserData';
import { flushCart } from 'Store/cart.actions';
// import { ROOT, MODULE } from '../constants'
// import { utcToDate } from 'Common/scripts/Functions'
import EmailVerification from 'Modules/User/containers/EmailVerification';
// import { collectCheckoutData } from '../checkout_lib';
// import Invoice_preview from '../components/invoice_preview';
import { timeslotSelect, addressSelect, couponSelect, statusUpdate, flushOrder, orderNote, set_delivery_charges } from '../redux/actions'
// import moment from 'moment';
import './styles.scss'
// import { MilestoneProgress } from 'Common/scripts/milestone_helpers';


// const CREATE_ORDER = loader('src/graphqls/order/createOrderFromCart.graphql');
const GET_DELIVERY = loader('src/graphqls/order/calculateDeliveryCharges.graphql');

class CheckoutClass extends Component {
    state = {
        showRegistration: true,
        currentStep: 0,
        selectedSlot: null,
        address: null,
        cartSynced: false,
        orderSuccess: false,
        busy:true,
        error:false,
        redirectTo:false,
    }
    constructor(props){
        super(props);
        this.gotoNextStep = this.gotoNextStep.bind(this);
        this.gotoPrevStep = this.gotoPrevStep.bind(this);
    }
    // state = { currentStep: 1, showRegistration:true }
    componentDidMount() {
        const { order, cart, flushOrder } = this.props;
        if (order.status && order.status != 'null') flushOrder();
        this.setState({ busy: false });
    }

    onSlotSelect = args => this.setState({ selectedSlot: args });

    goBack = num => this.setState({ currentStep: num });

    gotoNextStep = () => {
        const { currentStep } = this.state;
        if (!this.verifyStep(currentStep)) return false;

        if (currentStep===0) this.getDeliveryCharges()

        this.setState({ currentStep: currentStep+1 })
    }

    gotoPrevStep = () => {
        const { currentStep } = this.state;
        this.setState({ currentStep: currentStep-1 })
    }

    verifyStep(stepNum) {
        // const { selectedSlot, address } = this.state;
        const { timeslot, address } = this.props.order;

        if (stepNum === 0) {
            if (!timeslot || timeslot == null){
                message.error("Please select a time slot");
                return false;
            }

            if (address == null){
                message.error("Please select an address");
                return false;
            }

            // return !(!this.props.order.timeslot || (this.props.order.timeslot == null) || (this.props.order.address == null))
        }
        
        if (stepNum === 1) { }

        return true;
    }

    async getDeliveryCharges(){
        const { order, client } = this.props;

        this.setState({ busy:true })

        const results = await client.query({
            query: GET_DELIVERY, variables: {
                shipping_address: {
                    _id: order.address._id,
                    full_address: order.address.full_address,
                    city: order.address.city,
                    label: order.address.label,
                    geo_point: {
                        type: 'Point',
                        coordinates: order.address.geo_point.coordinates
                    },
                    delivery_instructions: order.address.delivery_instructions,
                },
            }
        })
            .then(e => (e.data.calculateDeliveryCharges))
            .catch(err => {
                console.log(__error("API Call ERROR: locations : "), err);
                return { error: { message: "Request ERROR" } }
            })
        if (results && results.error) {
            this.setState({ error: results.error.message, busy:false })
            return message.error(results.error.message);
        }

        this.setState({ busy: false })
        this.props.on_set_delivery_charges(results.amount)
    }

    // onCheckoutPress = async(args) => {
    //     const { order, cart, user } = this.props;
    //     const { timeslot, address } = order;

    //     let data = collectCheckoutData({ order, cart })
    //     if (data.error) return false;
        
    //     this.setState({ busy: true });

    //     let orderResult = await this.props.createOrderFromCart(data)
    //         .then(r => {
    //             if (!r || r.data.createOrderFromCart) return { error: !r ? { message: "Invalid response!" } : r.data.createOrderFromCart.error }
    //             return r.data.createOrderFromCart;
    //         })
    //         .catch(error => {
    //             console.log(__error(""), error);
    //             // message.error("Unable to process order at the moment!");
    //             return { error: { message:"Unable to process order at the moment!!!!!!"}}
    //         })

    //     if (orderResult.error){
    //         message.error(orderResult.error.message);
    //         this.setState({ error: orderResult.error.message, busy:false })
    //         return orderResult;
    //     }

    //     this.setState({ busy: true });
    //     message.success("Order successfull");
    //     this.props.statusUpdate('success');
    //     this.props.flushCart();


    //     let time_range_utc = timeslot ? JSON.parse(timeslot.time_range_utc) : "";
    //     let target_date = utcToDate(timeslot.date).format('DD-MM-YYYY');
    //     let start_time = utcToDate(time_range_utc[0]).format('HHmm');
    //     let end_time = utcToDate(time_range_utc[1]).format('HHmm');

    //     let success_message = `?message=${target_date} - ${start_time} ~ ${end_time}`;

    //     this.setState({ busy: false, redirectTo: `${ROOT}/success/${encodeURI(success_message)}` });
        
    //     return orderResult;
    // }

    render() {
        if (!this?.props?.user?._id){
            window.location.href = "/user/register";
            return null;
        }

        const { currentStep, showRegistration, selectedSlot, cartSynced, orderSuccess, busy, error } = this.state;
        const { user, cart, order, settings } = this.props;
        // console.log("this.props: ", this.props)

        if (busy) return <Loader loading={true} center />

        if (this.state.redirectTo) return <Redirect to={`${this.state.redirectTo}`} />
        // if (order.status == 'success') return <Redirect to={`${ROOT}/success`} />
        if (!user || !user._id) return <Redirect to={`/user/sign-in`} />

        if (user && user._id && !cartSynced) return <GetLoggedInUserData onSuccess={() => this.setState({ cartSynced:true })} />
        if (!cart.items || cart.items.length < 1) return <E404 />
        if (order.status && (order.status != null || order.status != 'null')) {
            console.log(__error(order.status));
            return <Alert message="Impossible Happened!" description="Invalid Order Status" type="error" showIcon />
        }

        if (settings.min_order_amount > cart.grandTotal) {
            return <Alert message="Order Error" description={`Minimum order limit is ${settings.currency} ${settings.min_order_amount}`} type="error" showIcon />
        }

        if (user && user._id && user.email_confirmed < 1){
            return <div style={{ padding: "50px 0px", }}>
                <EmailVerification redirectTo="/checkout" />
            </div>
        }

        // let time_range_utc = order?.timeslot?.time_range_utc && JSON.parse(order.timeslot.time_range_utc)?.map(itm => utcToDate(itm));


        return (<>
            <Steps size="small" current={currentStep} labelPlacement="vertical">
                <Steps.Step title="Time Slot" />
                <Steps.Step title="Invoice" />
                {/* <Steps.Step title="Payment" /> */}
                <Steps.Step title="CheckOut" />
            </Steps>

            <div style={{ padding: "50px 0px" }}>

                {currentStep === 0 && <div className="checkout-page">
                    <Row>
                        <Col flex="auto">
                            {this.props.user && this.props.user._id && <AddressSelect user={this.props.user} /> }
                        </Col>
                        <Col flex="auto" align="right">
                            <Button size="large" onClick={this.gotoNextStep} // onClick={() => this.setState({ currentStep: 1 })} disabled={!this.verifyStep(2)} 
                            iconRight={<Icon icon="angle-right" />}>Continue to Checkout</Button>
                        </Col>
                    </Row>
                    <Divider style={{margin:"10px 0 20px 0"}} />
                    <TimeSlotSelector onSlotSelect={this.onSlotSelect} />
                </div>}

                {currentStep === 1 && <div className="checkout-page">
                    {error && <Alert message={"Error Found"} description={error} type="error" showIcon />}
                    
                    <InvoiceVerification error={error} onNextPress={this.gotoNextStep} goBack={this.gotoPrevStep} />
                </div>}

                {currentStep === 2 && <div className="checkout-page">
                    {error && <Alert message={"Error Found"} description={error} type="error" showIcon />}
                    <Checkout goBack={this.gotoPrevStep} />
                </div>}


                <DevBlock obj={order} />
            </div>
        </>)
    }
}

const mapStateToProps = state => {
    return ({ 
        settings: state.grocer_storefront, 
        cart: state.cart, 
        order:state.order, 
        user: state?.grocer_storefront?.user,
    });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    flushOrder: (payload) => dispatch(flushOrder(payload)),
    statusUpdate: (payload) => dispatch(statusUpdate(payload)),
    timeslotSelect: (payload) => dispatch(timeslotSelect(payload)),
    addressSelect: (payload) => dispatch(addressSelect(payload)),
    couponSelect: (payload) => dispatch(couponSelect(payload)),
    flushCart: (payload) => dispatch(flushCart(payload)),
    on_orderNote: (payload) => dispatch(orderNote(payload)),
    on_set_delivery_charges: (payload) => dispatch(set_delivery_charges(payload)),
})
const CheckoutHome = connect(mapStateToProps, mapDispatchToProps)(withApollo(CheckoutClass));

// export default withApollo(CheckoutHome);
// export default compose(
//     graphql(CREATE_ORDER, {
//         props: ({ mutate }) => ({
//             createOrderFromCart: (args) => mutate({
//                 variables: { input: { ...args } },
//                 fetchPolicy: "no-cache"
//             }),
//         })
//     }),
// )(CheckoutHome);

export default CheckoutHome;
