import React, { useEffect, useState } from 'react'
import { Row, Col, Progress } from 'antd'
import { useWindowSize } from 'Common/hooks';
import { connect } from "react-redux";
import { __error, __yellow } from 'Common/scripts/consoleHelper';
// import { DevBlock, Loader } from 'Common/components';
import { parseNewLine } from 'Common/scripts/Functions';
import { MilestoneProgress } from 'Common/scripts/milestone_helpers';



const StoreInfo = (props) => {
    const { storeInfo, settings, user } = props;
    // const isSmallDevice = useMediaQuery({ query: '(max-device-width: 465px)' })
    const [screenSize] = useWindowSize();
    const isSmallDevice = screenSize.width < 850;

    return (<>
        <Row align="top" gutter={[10]} style={{ flexWrap: isSmallDevice ? "wrap" : "nowrap" }}>
            {storeInfo.logo_thumb && <Col><img src={storeInfo.logo_thumb} style={{ width: "80px", maxHeight: "80px" }} /></Col>}

            {isSmallDevice && <Col span="24" />}

            <Col flex="auto">
                <h6 style={{ marginBottom: "0", lineHeight: "30px" }}>{storeInfo.title}</h6>
                <div>{parseNewLine(storeInfo.description)}</div>
                <div><b>Taking orders on:</b> {storeInfo?.availability_days?.toString().replaceAll(",", ", ").toUpperCase()}</div>
            </Col>
            {/* <Col><MilestoneProgress storeInfo={storeInfo} settings={settings} user={user} /></Col> */}
            {/* <Col><Link to={`/store/${store.slug}`}>View all products</Link></Col> */}
        </Row>
        <div style={{height:"20px"}} />
        <MilestoneProgress storeInfo={storeInfo} settings={settings} user={user} />
        <div style={{height:"20px"}} />
    </>)
}
const mapStateToProps = state => {
    return ({
        settings: state.grocer_storefront,
        user: state?.grocer_storefront?.user,
    });
}
export default connect(mapStateToProps)(StoreInfo);

// export default StoreInfo;