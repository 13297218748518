import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Row, Col, message, Alert } from 'antd'
import { connect } from "react-redux";
import moment from 'moment'
import { __error } from 'Common/scripts/consoleHelper';
import { Input } from 'Common/components/Form'
import { Icon, Button, Avatar, IconButton } from 'Common/components'
import { couponSelect, orderNote } from '../redux/actions'
import CouponDiscountForm from './CouponDiscountForm'
import { utcToDate } from 'Common/scripts/Functions'
import { Link } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import Invoice_preview from './invoice_preview';
import { MilestoneProgress } from 'Common/scripts/milestone_helpers';

const InvoiceVerification = props => {
    const { cart, user, order, error, settings: { currency }, settings } = props;
    const [busy, setBusy] = useState(false);

    const { timeslot, address, coupon, invoice } = order;

    if (!cart || !cart.items || cart.items.length < 1) return <div>Oops! there is noting to order!</div>

    // var grandTotal = cart.grandTotal;//cart.grandTotal;
    // var discount_value = 0;

    const onNoteUpdate = txt => props.orderNote(txt);

    const onNextPress = async () => {
        props.onNextPress()
        // setBusy(true);
        // const result = await props.onNextPress()
        // setBusy(false);
    }

    const renderAttributes = (item) => {
        if (!item.attributes) return null;
        return item.attributes.map((o, i)=>{
            if (o.value && o.show_in_store==1) return <span key={i}>{o.title} {o.value}</span>
            return null;
        })
    }

    // calculate coupon discount
    // if (coupon) {
    //     if (coupon.discount_type == 'fix') discount_value = coupon.discount_value;
    //     if (coupon.discount_type == '%') discount_value = (Number(cart.totalPrice) / 100) * Number(coupon.discount_value);
    //     // discount_value = Number(discount_value);
    //     grandTotal = Number(grandTotal) - Number(discount_value);
    // }

    let time_range_utc = JSON.parse(order.timeslot.time_range_utc);
    time_range_utc = time_range_utc.map(itm => moment(itm, "DD-MM-YYYYTHH:mm"));

    return (<>
        <h3>Invoice</h3>

        <Row gutter={[20, 0]}>
            <Col span={24} lg={{ span:12 }}>
                <table className="invoice-table">
                    <thead>
                        <tr>
                            <th colSpan={2}>Products</th>
                            <th style={{ textAlign:"center" }}>Qty</th>
                            <th style={{ textAlign: "center" }}>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={4}>Total {cart.items.length} items</td>
                        </tr>

                        {cart.items.map((item, i) => {
                            let thumb = item.picture_thumb ? `${process.env.REACT_APP_DATA_URL}/${item.picture_thumb}` : undefined;

                            return (
                                <tr key={i}>
                                    <td className="img-holder" width="54">
                                        <Link to={`/product/${item.slug}`}><Avatar type="product" src={thumb} size={50} shape="square" /></Link>
                                    </td>
                                    <td>
                                        <strong>{item.title}</strong>
                                        <div>{renderAttributes(item)}</div>
                                        {/* <span style={{ fontSize: "0.7em", textAlign: "center", display: "inline-block" }}>
                                            <AddToCartButton prod={item} size="small" />
                                            <div>in your trolley</div>
                                        </span> */}
                                    </td>
                                    <td align="center" width="100">{item.qty} x {item.price}</td>
                                    <td align="center" width="100">{currency} {item.price * item.qty}</td>
                                </tr>
                            )
                        })}


                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={3} align="right">Sub Total</td>
                            <td align="center">{currency} {cart.totalPrice}</td>
                        </tr>
                    </tfoot>
                </table>
            </Col>

            <Col span={24} lg={{ span: 12 }}>
                <MilestoneProgress showDiscountButton store_id={cart.store._id} settings={settings} user={user} />
                {props.grocer_zambia_hipo_loyalty_api_key && <div style={{ margin: "5px 0 0 0" }}>
                    <MilestoneProgress theme="green" title="Grocer Zambia" api_key={props.grocer_zambia_hipo_loyalty_api_key} showDiscountButton={true} />
                </div>}

                <CouponDiscountForm />
                <Invoice_preview />

                <div style={{ fontSize: "16px", border: "1px solid #EEE", margin: "10px 0", padding: "2px 8px", textAlign: "center" }}>
                    Scheduled for
                    <div style={{ fontWeight: "bold" }}>{moment(order.timeslot.date).format("ddd, MMM Do YYYY")} - {time_range_utc[0].format("hh:mm A")} to {time_range_utc[1].format("hh:mm A")}</div>
                </div>

                <div>
                    <Input type="textarea" defaultValue={props.order.note} value={props.order.note || undefined}
                        onChange={e => onNoteUpdate(e.target.value)}
                        label="Notes" placeholder="Any additional notes you would like to attach with your order!" name="note" rows={2} />
                </div>

                <div style={{ padding: "20px", display: "flex", justifyContent: "space-between" }}>
                    <Button type="default" className="back-button" size="large" onClick={props.goBack} iconLeft={<Icon icon="angle-left" />}>Back</Button>
                    <Button onClick={onNextPress} disabled={error!==false} loading={busy} size="large" className="green" iconRight={<Icon icon="angle-right" />}>Make Payment</Button>
                </div>

            </Col>
        </Row>

    </>)
}
InvoiceVerification.propTypes = {
    onNextPress: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    couponSelect: (payload) => dispatch(couponSelect(payload)),
    orderNote: (payload) => dispatch(orderNote(payload)),
})
const mapStateToProps = state => {
    return ({
        settings: state.grocer_storefront,
        order: state.order,
        cart: state.cart,
        user: state?.grocer_storefront?.user,
        grocer_zambia_hipo_loyalty_api_key: state?.grocer_storefront?.grocer_zambia_hipo_loyalty_api_key,
    });
}
export default connect(mapStateToProps, mapDispatchToProps)(withApollo(InvoiceVerification));
