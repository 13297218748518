import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
// import {Icon} from 'common/components'
export const ROOT = '/product';
export const MODULE = 'PRODUCT';


// addReducers({ admins: reducers });
addModule(
    { path: `${ROOT}`, component: Home, icon: 'laptop-code', title: "Product", exact: 0, hideNav: 0 },
)
