import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Alert, Divider } from 'antd';
import { Button, Loader } from 'Common/components';
import { Link, useHistory } from 'react-router-dom';
import { connect } from "react-redux";
import SignInForm from '../components/SignInForm';
import { loginUser, logoutUser } from 'Store/grocer_storefront.actions'
// import LocalStorage from 'Common/scripts/LocalStorage';
import { __error } from 'Common/scripts/consoleHelper';


const SignInPage = (props) => {
    // console.log("SignInPage: ", props)
    // const [busy, setBusy] = useState(false);
    // const [error, setError] = useState(null);
    let history = useHistory();
    // history.push(props.redirectTo || '/')

    // if (busy) return <Loader loading={true} />
    // if (error) return <Alert message={error} type="error" showIcon />

    if (props?.user?._id){
        history.push("/")
        return <p>redirecting...</p>
    }

    return (<>
        {props?.location?.search == "?code=401" && <>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "left" }}>
                <div style={{ maxWidth: "350px", width: "100%" }}>
                    <Alert message="Session expired! Please login again" showIcon type="warning" />
                </div>
            </div>
        </>}

        {/* <SignInForm {...props} style={{ marginTop: "20px", ...props.style }} onSuccessCallback={onSigninCallback} /> */}
        <SignInForm {...props} style={{ marginTop: "20px", ...props.style }} />

        {!props?.user && 
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "left", margin: "0 0 40px 0" }}>
                <div style={{ maxWidth: "350px", width: "100%" }}>
                    <Divider style={{ margin: "15px 0 10px 0" }} />
                    <p><b>Not a member yet?</b></p>
                    {props.onRegisterClick && <Button onClick={props.onRegisterClick} theme="orange" type="primary" block size="large">Register</Button>}
                    {!props.onRegisterClick && <Button type="primary" theme="orange" block size="large"><Link to="/user/register">Register</Link></Button>}
                </div>
            </div>
        }

    </>)
}
SignInPage.propTypes = {
    isPopup: PropTypes.bool,
}

const mapStateToProps = state => {
    // let token = LocalStorage.get('token');
    // if (token) LocalStorage.logout()

    // console.log("token: ", token)
    // console.log("state.grocer_storefront: ", state.grocer_storefront)
    // let user = state?.grocer_storefront?.user;
    // if (user) window.location.href = "/"

    return ({ 
        user: state?.grocer_storefront?.user,
    });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    // loginUser: (payload) => dispatch(loginUser(payload)),
    logoutUser: (payload) => dispatch(logoutUser(payload)),
})
export default connect(mapStateToProps, mapDispatchToProps)(SignInPage);

// export default SignInPage;