import React, { useEffect } from 'react'
import { connect } from "react-redux";
import { Result } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'Common/components';


const CheckoutFail = props => {
    var location = useLocation();

    let query = decodeURI(location.search).substring(1)
    let vars = query.split("&");
    var params = {}
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        Object.assign(params, { [pair[0]]: pair[1] })
    }

    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        //   return () => { };
    })

    return (<>
        <Result status="error"
            title="Oops!" subTitle={params.message}
            extra={[
                <Button size="large"><Link to="/">Continue Shopping</Link></Button>
            ]}
        />
    </>)
}

export default CheckoutFail
