import React from 'react'
import PropTypes from 'prop-types';
import { Row, Col, message, Alert } from 'antd'
import { connect } from "react-redux";
import { __error } from 'Common/scripts/consoleHelper';
// import { Icon, Button, Avatar, IconButton } from 'Common/components'
import { couponSelect, orderNote } from '../redux/actions'
import PaymentMethods from '../PaymentMethods'
// import { utcToDate } from 'Common/scripts/Functions'
import Invoice_preview from './invoice_preview';
import moment from 'moment';

const Checkout = props => {
    const { cart, user, order, settings: { currency } } = props;
    const { coupon, invoice } = order;

    if (!cart || !cart.items || cart.items.length < 1) return <div>Oops! there is noting to order!</div>

    var grandTotal = cart.grandTotal;//cart.grandTotal;
    var discount_value = 0;

    // const onCheckoutPress = async () => {
    //     setBusy(true);
    //     const result = await props.onCheckoutPress()
    //     setBusy(false);
    // }

    // calculate coupon discount
    if (coupon) {
        if (coupon.discount_type == 'fix') discount_value = coupon.discount_value;
        if (coupon.discount_type == '%') discount_value = (Number(cart.totalPrice) / 100) * Number(coupon.discount_value);
        // discount_value = Number(discount_value);
        grandTotal = Number(grandTotal) - Number(discount_value);
    }

    let time_range_utc = JSON.parse(order.timeslot.time_range_utc);
    time_range_utc = time_range_utc.map(itm => moment(itm, "DD-MM-YYYYTHH:mm"));

    return (<>
        <Row gutter={[20, 0]}>
            <Col span={24} lg={{ span:12 }}>
                <PaymentMethods onBackClick={() => props.goBack(2)} />
            </Col>
            <Col span={24} lg={{ span: 12 }}>
                <Invoice_preview />
            </Col>
        </Row>

    </>)
}
Checkout.propTypes = {
    goBack: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    couponSelect: (payload) => dispatch(couponSelect(payload)),
    orderNote: (payload) => dispatch(orderNote(payload)),
})
const mapStateToProps = state => {
    return ({
        settings: state.grocer_storefront,
        order: state.order,
        cart: state.cart,
        user: state?.grocer_storefront?.user,
    });
}
export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
