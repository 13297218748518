import React from 'react';
import { addModule, addReducers } from '../connector';
import Home from './Home';
// import {Icon} from 'common/components'
export const ROOT = '/template';
export const MODULE = 'TEMPLATE';


// addReducers({ admins: reducers });
addModule(
    { path: `${ROOT}`, component: Home, icon: 'minus', title: "Template", exact: 0, hideNav: 0 },
    // { path: `${ROOT}`, component: Home, icon: 'laptop-code', title: "Template", exact: 0, hideNav: (process.env.NODE_ENV == "development")?0:1 },
)
