import React, { useEffect } from 'react'
import LocalStorage from 'Common/scripts/LocalStorage';
import { sleep } from 'Common/scripts/Functions';
import { flushCart } from 'Store/cart.actions'
import { logoutUser } from 'Store/grocer_storefront.actions';
import { connect } from "react-redux";
// import { useHistory } from 'react-router-dom';

const Logout = ({ on_flushCart, on_logoutUser }) => {
    // let history = useHistory();

    const onLogout = async args => {
        await LocalStorage.logout()
        on_flushCart();
        on_logoutUser();
        await sleep(100);
        // history.push("/");
        window.location = "/"
    }
    
    useEffect(() => {
        onLogout();
    }, [])

    return (<>
        <p>Loggin out...</p>
    </>)
}

const _funcs = (dispatch, ownProps) => ({
    on_flushCart: () => dispatch(flushCart()),
    on_logoutUser: () => dispatch(logoutUser()),
})
export default connect(null, _funcs)(Logout);
