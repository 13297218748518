import React from 'react'
import PropTypes from 'prop-types';
import { message, Skeleton } from 'antd';
// import { graphql } from '@apollo/client/react/hoc';
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
// import { Link } from 'react-router-dom';
import { connect } from "react-redux";
// import ProductPageSkeleton from 'Layout_v1/ProductPageSkeleton';
import { E404 } from "Layout_v1";
// import { Icon, Button, Loader } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper'

const GET_RECORD = loader('src/graphqls/pages/pageBySlug.graphql');


class MainPage extends React.Component {
  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
   
  render() {
    // console.log("this.props: ", this.props);
    const { loading, pageBySlug, settings: { currency } } = this.props;

    if (loading) return <><Skeleton active /><Skeleton active /><Skeleton active /></>
    if (!loading && (!pageBySlug || pageBySlug.error || !pageBySlug._id)) return <E404 />
    if (!pageBySlug || !pageBySlug._id) return <E404 />

    return (<div className="pages-page">
      <h4>{pageBySlug.title}</h4>
      <span dangerouslySetInnerHTML={{ __html: pageBySlug.body }} />
    </div>)


  }

}

MainPage.propTypes = {
  // toggleDrawer: PropTypes.func.isRequired
  // prop: PropTypes.type.isRequired
  // onEditRecord: PropTypes.func.isRequired
}


const WithApollo = compose(

  graphql(GET_RECORD, {
    options: (props) => {
      return {
        variables: { slug: props.match.params.slug },
        // fetchPolicy: "no-cache", //no-cache, cache-first, network-only, cache-only, cache-and-network
      };
    },
    props: ({ ownProps, data }) => {
      const { loading, pageBySlug, error } = data;
      if (error) console.log(__error("error"), error);

      return { loading, pageBySlug, queryErrors: error, }
    },
  }),


)(MainPage);

const mapStateToProps = state => {
  return ({ settings: state.grocer_storefront, });
}
export default connect(mapStateToProps)(WithApollo);
// export default WithApollo;
