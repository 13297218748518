import React from 'react'
import { Route, Switch } from 'react-router-dom';
// import { Spin } from 'antd';
import { E404, LayoutWrapper } from "Layout_v1";
// import { CircleSpinner, Loader } from 'Common/components';
import MainPage from './containers';
// import { ROOT } from './'; 
// import 'Layout_v1/styles/home.scss'


const menuArray = props => ([
    // { path: `${ROOT}/:slug`, exact: false, icon: 'caret-right', title: 'Innter Home', component: MainPage},
    // { path: `/store/:store_slug`, exact: false, icon: 'caret-right', title: 'Innter Store Home', component: MainPage},
    { path: `/store/:store_slug/:cat_slug?`, exact: false, icon: 'caret-right', title: 'Innter Home', component: MainPage},
]);

export const StoreHome = props => {
    // console.log("props.match.params: ", props.match.params);
    
    let routs = menuArray();
    return (
        <LayoutWrapper topMenu={null} menuOverride={null}>
            <Switch>
                {routs.map((item, i) => {
                    if (!item.component) return null;
                    return <Route exact={item.exact} path={item.path} component={item.component} key={i} />
                })}
                <Route key={999} component={E404} />
            </Switch>
        </LayoutWrapper>

    )
}

export default StoreHome;
