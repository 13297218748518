import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table as _Table } from 'antd';
import Loader from './Loader';


/*****************
    const columns = [
        { title: 'Product', dataIndex: 'title', render:renderTitle },
        { title: 'Qty', dataIndex: 'qty', align:"center", width:"50px" },
        { title: 'Price', dataIndex: 'price', align: "center", width: '100px' },
        { title: 'Total', dataIndex: 'total', width: '100px', align: "right", render: (text, record) => `${currency} ${record.qty * record.price}` }
    ];
    <Table
        columns={columnsArray}
        dataSource={userRolesQuery ? userRolesQuery.edges : null}
        title={() => <span>Header</span>}
        footer={() => 'Footer'}
        rowKey={record => record._id}
        expandable={{
            expandedRowRender: record => <p style={{ margin: 0 }}>{record.rights}</p>,
            rowExpandable: record => record.name !== 'Not Expandable',
        }}
    />
 */
export const Table = props => {
    let _props = { ...props };
    
    _props = Object.assign(_props, {
        bordered: (props.bordered === false) ? false : true,
        scroll: (props.scroll === false) ? false : props.scroll,
        size: props.size || "middle", // small, middle, default
    })
    
    if (props.pagination !== false && (!props.pagination || !props.pagination.position)) {
        let pagination = { ...props.pagination}
        pagination = Object.assign(pagination, {position: ['none', 'bottomCenter']} )
        _props = Object.assign(_props, { pagination: {...pagination} })
    }

    if (!props.rowKey) _props = Object.assign(_props, { rowKey: (data) => data.key || data._id });
    // if (props.title) _props = Object.assign(_props, { title: () => props.title})
    // if (props.footer) _props = Object.assign(_props, { footer: () => props.footer})

    if (!props.rowClassName && !props.disableStatusHighight) {
        _props = Object.assign(_props, {
            rowClassName:(record => {
                return record.status == 'disabled' ? 'disabled-table-row' : "";
            })
        })
    }

    _props.loading = !props.loading ? false : { size: 'large', indicator: <Loader loading={true} /> };

    return <_Table {..._props} />
}

Table.propTypes = {
    bordered: PropTypes.bool,
    scroll: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object
    ]),
    loading: PropTypes.bool,
    showHeader: PropTypes.bool,
    rowClassName: PropTypes.func,
    disableStatusHighight: PropTypes.bool,
    columns: PropTypes.array.isRequired,
    // pagination: PropTypes.object,
    rowKey: PropTypes.func,
    title: PropTypes.func,
    footer: PropTypes.func,
    pagination: PropTypes.oneOfType([
                PropTypes.bool,
                PropTypes.object
            ]),
    // prop: PropTypes.type.isRequired
}
