export const appText = {
    app_name: "Grocer Zambia",
    footer_text: "© Grocerzm.com 2023 - Grocer Zambia is online supermarket and deliver grocery and household item in Africa.",
    titlePrefix: "Grocerzm.com",
    defaultTitle: "Grocerzm.com - The online supermarket, buy grocery online , food items & more'",

    about_app: "About Grocer Zambia",

    contact_phone: "(+260) 763-752-951"

}