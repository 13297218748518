import React from 'react'
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { message, BackTop, Modal, Row, Col, Divider } from 'antd';
// import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
// import LocalStorage from 'Common/scripts/LocalStorage';
// import Login from 'Modules/Login/Home'
// import { graphql, withApollo } from '@apollo/client/react/hoc';
import { graphql, withApollo } from 'react-apollo';
import { __error } from 'Common/scripts/consoleHelper'
// import { Loader } from 'Common/components';
import { loader } from 'graphql.macro';
// import compose from 'lodash.flowright';
import GetLoggedInUserData from 'Modules/User/containers/GetLoggedInUserData';
import { updateSettings } from 'Store/grocer_storefront.actions';
import { appText } from 'configs';
import { Button, DevBlock, Icon } from 'Common/components'
import { logoutUser } from 'Store/grocer_storefront.actions'
// import LocalStorage from 'Common/scripts/LocalStorage';
import { updateCategories } from 'Store/reducers/redux_nav';

import SignInPage from 'Modules/User/containers/sign-In';
import RegisterPage from 'Modules/User/containers/register';

import './styles/styles.scss';

const SETTINGS = loader('src/graphqls/settings/settings_str.graphql');
const CAT_LIST = loader('src/graphqls/product_cat/productCats_client.graphql');


//// global events for registration/.signup pops to be opened
global.RegistrationPopEvent = new CustomEvent('showRegistrationPop', { bubbles: true, detail: 'Some initial data' });
global.SigninPopEvent = new CustomEvent('showSigninPop', { bubbles: true, detail: 'Some initial data' });
// global.LogOutUser = new CustomEvent('logOutUser', { bubbles: true, detail: 'Some initial data' });
// global.BackToTopEvent = new CustomEvent('backToTopEvent', { bubbles: true, detail: 'Some initial data' });


export class _Wrapper extends React.Component {
    state = { userloaded: false, showRegistrationPop: false, showSigninPop:false };

    componentDidMount() {
        document.addEventListener('logOutUser', (e)=>{
            // console.log("addEventListener.logOutUser: ", e)
            if (!e.detail || e.detail.length<10){
                this.props.logoutUser();
            }
            // e.detail
            // this.setState({ showRegistrationPop: true, showSigninPop: false})
        }, false);

        document.addEventListener('showRegistrationPop', (e)=>{
            this.setState({ showRegistrationPop: true, showSigninPop: false})
        }, false);

        document.addEventListener('showSigninPop', (e)=>{
            this.setState({ showRegistrationPop: false, showSigninPop: true})
        }, false);

        // console.log("Wrapper.componentDidMount()");
        if (!this.props.grocer_storefront || !this.props.grocer_storefront._id) {
            this.getSettings();
            this.getCategories();
        }
    }

    componentWillUnmount(){
        this.setState({ showRegistrationPop: false, showSigninPop: false })
    }

    handelPopEvent = args => { }

    getSettings = async() => {
        let settings = await this.props.client.query({ query: SETTINGS }).then(e => {
            if (e.error || e.errors) {
                console.log(__error("ERROR: "), e);
                return { error: { message: (e.error && e.error.message) || (e.errors && e.errors[0].message) }}
            }
            if (!e?.data?.settings_str) return { error: { message: "Unable to sync application, please check your internet connection" } }
            return JSON.parse(e.data.settings_str);
        }).catch(err => {
            console.log(__error("Request ERROR: "), err);
            return { error: { message:"Request ERROR" } }
        })

        if (settings.error){
            message.error(settings.error.message);
            this.setState({ error: settings.error.message, loading: false })
            return;
        }

        this.props.updateSettings(settings);
        this.setState({ loading: false });
    }

    getCategories = async() => {
        let cats = await this.props.client.query({ 
            query: CAT_LIST,
            variables: { filter: JSON.stringify({ status: "enabled" }) },
            fetchPolicy: "cache-first",
        }).then(e => (e?.data?.productCats_client))
        .catch(err => {
            console.log(__error("Request ERROR: "), err);
            return { error: { message: "Request ERROR!" } }
        })

        if (cats.error) {
            // message.error(settings.error.message);
            // this.setState({ error: settings.error.message, loading: false })
            return;
        }

        this.props.updateCategories(cats)
    }

    render() {
        const { userloaded, showRegistrationPop, showSigninPop } = this.state;
        const { user, settings } = this.props;
       
        return <>
            {(user && user._id && !userloaded) && <GetLoggedInUserData onSuccess={() => this.setState({ userloaded: true })} />}
            <Helmet>
                <title>{appText.defaultTitle}</title>
            </Helmet>

            {this.props.children}
            
            <BackTop duration={200} style={{opacity:"0.5"}}><Button><Icon icon="chevron-up" /></Button></BackTop>

            {(!user || !user._id) && <>
                <Modal centered width="100%" height="100%" closable={true} destroyOnClose={true} footer={null} forceRender={false} maskClosable={false} visible={showRegistrationPop}
                    title="Quick Register"
                    height="200"
                    onCancel={() => this.setState({ showRegistrationPop: false, showSigninPop: false })}
                >
                    {showRegistrationPop && <>
                        <RegisterPage isPopup
                            onCancel={() => this.setState({ showRegistrationPop: false, showSigninPop: false })} 
                            onLoginClick={() => this.setState({ showRegistrationPop: false, showSigninPop: true })}
                        />
                    </>}
                </Modal>

                {/* {showSigninPop && <p>showSigninPop</p>} */}
                <Modal centered closable={true} destroyOnClose={true} footer={null} forceRender={false} maskClosable={false} visible={showSigninPop}
                    title="Sign In To Your Account"
                    onCancel={() => this.setState({ showRegistrationPop: false, showSigninPop: false })}
                >
                    {showSigninPop && <SignInPage style={{ marginTop:0 }} isPopup
                        onCancel={() => this.setState({ showRegistrationPop: false, showSigninPop: false })}
                        onRegisterClick={() => this.setState({ showSigninPop: false, showRegistrationPop: true })}
                    /> }
                </Modal>
            </>}

        </>
    }

}

const mapStateToProps = state => {
    return ({ 
        settings: state.grocer_storefront, 
        user: state?.grocer_storefront?.user,
    });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    logoutUser: (payload) => dispatch(logoutUser(payload)),
    updateSettings: (payload) => dispatch(updateSettings(payload)),
    updateCategories: (payload) => dispatch(updateCategories(payload)),
})
const WrapperWithApollo = withApollo(_Wrapper);
export const Wrapper = connect(mapStateToProps, mapDispatchToProps)(WrapperWithApollo);
export default Wrapper;
