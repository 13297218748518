import React from 'react'; 
import { ApolloClient } from 'apollo-client';
import { ApolloLink, split } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { WebSocketLink } from 'apollo-link-ws';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from 'react-apollo';
import { getMainDefinition } from 'apollo-utilities';
import { SubscriptionClient } from "subscriptions-transport-ws";
import { __error, __info, __warning } from 'Common/scripts/consoleHelper';
import { connect } from "react-redux";
import { onError } from "apollo-link-error";
// import LocalStorage from 'Common/scripts/LocalStorage';


const NETWORK_INTERFACE_URL = process.env.REACT_APP_GRAPHQL_PATH; //"http://localhost:4000/graphql";
const SUBSCRIPTION_CLIENT_URL = process.env.REACT_APP_SUBSCRIPTION_CLIENT_URL; //"ws://localhost:4000/subscriptions";



const ApolloWrapper = ({ children, token }) => {
  // console.log("USER: ", user) // user.token
  // let token = LocalStorage.get('token');
  // let refreshToken = LocalStorage.get('refreshToken');

  /******** Setting up apollo-link ********/
  const httpLink = createHttpLink({ uri: NETWORK_INTERFACE_URL });

  const tokenLink = setContext(() => {
    // console.log("setContext()")
    // let token = LocalStorage.get('token');
    // let refreshToken = LocalStorage.get('refreshToken');

    return {
      headers: {
        'x-token': token,
        'x-refresh-token': token,
        authorization: `Bearer ${token || undefined}`
        // authorization: `Bearer ${localStorage.getItem('token')}`
        // authorization: `Bearer token-123456`
      },
    }
  });

  const afterwareLink = new ApolloLink((operation, forward) => {
    const { headers } = operation.getContext();

    if (headers) {
      // let _token = headers.get('x-token');
      // let _refreshToken = headers.get('x-refresh-token');

      // if (_token) LocalStorage.set('token', _token); // localStorage.setItem('token', _token);
      // if (_refreshToken) LocalStorage.set('refreshToken', _refreshToken); //localStorage.setItem('refreshToken', refreshToken);

      // localStorage.setItem("token", nextProps?.ping?.token)
      // localStorage.setItem("refreshToken", nextProps.ping.refreshToken)
    }

    return forward(operation);
  });

  // const resetToken = onError(({ response, networkError }) => {
  //   if (networkError && networkError.statusCode === 401) {
  //     // remove cached token on 401 from the server
  //     store.dispatch(actions.signOut());
  //     networkError = undefined;
  //   }
  // });

  const timeStartLink = new ApolloLink((operation, forward) => {
    operation.setContext({ start: new Date() });
    return forward(operation);
  });
  const logTimeLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((data) => {
      // data from a previous link
      const time = new Date() - operation.getContext().start;
      if (process.env.REACT_APP_DEV == 'yes') {
        console.log(__info("APOLLO : "), ` operation ${operation.operationName} took ${time} to complete`, operation);
        // console.log(`APOLLO: operation ${operation.operationName} took ${time} to complete`);
      }
      return data;
    })
  });
  const linkLogger = timeStartLink.concat(logTimeLink);

  const errorLink = onError((args) => {
    console.log(__error("Apollo onError()"), args)
    const { graphQLErrors, networkError, operation, forward } = args;
    
    // let token = LocalStorage.get('token');
    // let refreshToken = LocalStorage.get('refreshToken');

    if (graphQLErrors){
      console.log(__error("graphQLErrors: "), graphQLErrors);

      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    }

    if (networkError){
      console.log(__error("networkError: "), networkError);

      if (networkError.statusCode==401){

      }

      // console.log(`[Network error]`, networkError);
      // console.log("networkError.statusCode: ", networkError.statusCode)
      // console.log("operation.operationName: ", operation.operationName)

      if (networkError.statusCode == 401 && token){
        console.log(__warning("Session Expired! please login again"))
        console.log("window.location.pathname: ", window.location.pathname)
        if (window.location.pathname.indexOf("/user/sign-in") < 0){
          window.location.href = "/user/sign-in/?code=401"
        }
      }

    }
  });

  let httpLinkWithMiddleware = httpLink;
  httpLinkWithMiddleware = tokenLink.concat(httpLinkWithMiddleware)
  httpLinkWithMiddleware = afterwareLink.concat(httpLinkWithMiddleware)
  httpLinkWithMiddleware = linkLogger.concat(httpLinkWithMiddleware)
  httpLinkWithMiddleware = errorLink.concat(httpLinkWithMiddleware)


  const SubClient = new SubscriptionClient(SUBSCRIPTION_CLIENT_URL, {
    reconnect: true,
    inactivityTimeout: 0,
    // onConnected: ()=>{
    //   console.log(`Subscription Socket connectd on ${SUBSCRIPTION_CLIENT_URL}`);
    // },
    connectionCallback: (error) => {
      if (!error)
        console.log(`🚀 Subscription Socket connectd on ${SUBSCRIPTION_CLIENT_URL}`);
      else
        console.log("🚨 ERROR - Subscription Socket Connection Error: ", error);
    }
  });

  const wsLink = new WebSocketLink(SubClient);

  const link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLinkWithMiddleware,
  );

  const ApolloDefaultOptions = {
    watchQuery: {
      fetchPolicy: 'network-only', //no-cache,network-only/
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  };

  const aClient = new ApolloClient({
    link,
    cache: new InMemoryCache({
      // typePolicies:{
      //   ProductQuery:{
      //     fields: {
      //       repositories: relayStylePagination()
      //     },
      //   }
      // },
      // dataIdFromObject: o => {
      //   // if (o._id) console.log(`dataIdFromObject: ${o.__typename} : ${o._id}`);
      //   // return null;

      //   console.log("O: ", o);

      //   if (!o._id) return null;

      //   let obj = {}
      //   obj[o.__typename] = o._id;
      //   return obj;
      // }
    }),
    // onError: ({ networkError, graphQLErrors }) => {
    //   console.log(__error('graphQLErrors'), graphQLErrors)
    //   console.log(__error('networkError'), networkError)
    // },
    errorPolicy: 'all',
    defaultOptions: ApolloDefaultOptions
  });



  return (<ApolloProvider client={aClient}>
    {children}
  </ApolloProvider>)
}
const mapStateToProps = state => {
  return { 
    token: state?.grocer_storefront?.token,
  };
}
export default connect(mapStateToProps)(ApolloWrapper);
