import React from 'react';
import { Link, Redirect, withRouter, useParams, useHistory } from 'react-router-dom';
import { SearchField } from 'Common/components/Form';

const SiteSearch = props => {
    let { kw } = useParams();
    let history = useHistory();

    const onSearchPress = txt => {
        if (!txt || txt.length < 1) return;
        let searchPath = `/search/${txt}`;
        history.push(searchPath)
        // setRedirectTo(searchPath);
        // window.location = searchPath;
    }

    return (<>
        <SearchField enterButton={false}
            className="site-search"
            style={{ ...props.style }}
            size="large"
            onSearch={onSearchPress}
            defaultValue={kw}
            name="keywords" placeholder="Search products..."
        />     
    </>)
}

export default SiteSearch;