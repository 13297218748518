import React, { useState } from 'react'
// import { graphql, withApollo } from '@apollo/client/react/hoc';
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { Row, Col, Modal, message, Divider, Checkbox } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { moment } from "moment";
import { E404 } from "Layout_v1";
import { Icon, Button, Heading, Loader, DevBlock, IconButton } from 'Common/components'
import { FormComponent, FormField, FormFieldGroup, rules, composeValidators } from 'Common/components/Form'
import { phoneCodes, genders } from 'configs/constants';
import { __error } from 'Common/scripts/consoleHelper'
import _ from 'lodash'
import { updateUser } from 'Store/grocer_storefront.actions';
import EmailVerification from '../containers/EmailVerification';
import { MilestoneProgress } from 'Common/scripts/milestone_helpers';


const GET_PROFILE = loader('src/graphqls/user/user.graphql');
const UPDATE_USER = loader('src/graphqls/user/edit.graphql');

const UserProfileForm = ({ toggleMode, onSubmit, inititalValues }) => {
    return (<>
        <div className="h-center" style={{ marginBottom: "50px" }}>
            <div style={{ width: "100%", maxWidth: "600px" }}>
                <h4><span>Update profile!</span></h4>

                <FormComponent onSubmit={onSubmit} id='ProdCatForm' debug={true} style={{ width: "100%" }} fields={{ ...inititalValues }}
                    form_render={({ values, submitting }) => {
                        return (<>
                            <Row>
                                <Col flex="100px"><FormField type="select" label="Gender" name="gender" data={genders} validate={rules.required} size="large" /></Col>
                                <Col flex="auto"><FormField type="text" width="100%" name="fname" label="First Name" validate={composeValidators(rules.required, rules.minChar(3))} /></Col>
                                <Col flex="auto"><FormField type="text" width="100%" name="lname" label="Last Name" /></Col>
                            </Row>

                            <Row>
                                <Col flex="auto"><FormField type="phone" name="phone" /></Col>
                                <Col flex="250px">
                                    <FormField type="date" name="dob" displayFormat={'DD-MM-YYYY'} size="large" label="DOB" style={{ width: "100%" }} />
                                </Col>
                            </Row>

                            <Row style={{padding:"20px"}}>
                                <Col flex="auto"><Button onClick={toggleMode} loading={submitting} type="white" loading={submitting} size="large">Cencel</Button></Col>
                                <Col><Button type="primary" loading={submitting} size="large" htmlType="submit">Update</Button></Col>
                            </Row>

                        </>)
                    }}
                
                />

            </div>
        </div>
    </>)
}

const UserProfileView = ({ loading, userData, user, showEmailVerification, showPhoneVerification, toggleMode }) => {
    const [showAccountDeletion, set_showAccountDeletion] = useState(false)

    let name = user.fname;
    if (user.lname && user.lname != 'null') name += ` ${user.lname}`

    return (<>
        <div className="h-center" style={{ marginBottom: "50px" }}><div style={{ width: "100%", maxWidth: "600px" }}>
            <h4>{user.gender == 'male' ? "Mr." : "Miss."} {name} <span style={{ fontSize: "14px" }}><IconButton onClick={toggleMode} icon="pen" /></span></h4>

            <Row gutter={[10]} className='profile-data-field'>
                <Col className='label'>Email</Col>
                <Col flex="auto">{user.email}</Col>
                <Col>{user.email_confirmed > 0 ? <span style={{ color: "green" }}>verified</span> : <span className='a' style={{ color: "blue" }} onClick={(e)=>{
                    e.preventDefault();
                    showEmailVerification();
                }} type="link">verify</span>}</Col>
            </Row>

            <Row gutter={[10]} className='profile-data-field'>
                <Col className='label'>Phone</Col>
                <Col flex="auto">{user.phone}</Col>
                {/* <Col>{user.phone_confirmed > 0 ? "verified" : <span className='a' style={{color:"blue"}} onClick={(e)=>{
                    e.preventDefault();
                    showPhoneVerification();
                }} type="link">verify</span>}</Col> */}
            </Row>

            <Row gutter={[10]} className='profile-data-field'>
                <Col className='label'>DOB</Col>
                <Col>{user.dob}</Col>
            </Row>

            <Divider style={{ color:"red", fontWeight:"bold", marginTop:"50px" }}>Account Deletion Request</Divider>
            <Checkbox onChange={(e) => set_showAccountDeletion(e.target.checked)}>Yes, I want to delete my account</Checkbox>
            {showAccountDeletion && <div style={{ padding:"30px" }}><Button type="danger" block><Link to='/user/profile/deletion_request'>Proceed with Deletion Request</Link></Button></div>}

        </div></div>

        {/* <Modal visible={showAccountDeletion} footer={false} title="Account Deletion Request" onCancel={() => set_showAccountDeletion(false)}>
            {showAccountDeletion && <>
                <p>deletion form</p>
            </>}
        </Modal> */}

    </>)
}

const UserProfilePage = props => {
    const [busy, setBusy] = useState(false);
    const [mode, toggleMode] = useState('preview');
    const [showEmailVerification, set_showEmailVerification] = useState(false);
    const [showPhoneVerification, set_showPhoneVerification] = useState(false);
    const { loading, userData, user, grocer_zambia_hipo_loyalty_api_key } = props;

    const onSubmit = async values => {
        let data = {
            _id: values._id,
            fname: values.fname,
            lname: values.lname || "",
            gender: values.gender,
            dob: values.dob,
            phone: `${values.phone}`,
        }

        const resutls = await props.editUser(data).then(r => (r.data.editUser))
        .catch(err => {
            console.log(__error(""), err);
            return { error: { message:"Query Error"}}
        })
        
        if (resutls.error){
            message.error(resutls.error.message);
            return false;
        }

        props.updateUser(data)
        message.success("Record updated");
        toggleMode("preview");
        
    }

    const onShowEmailVerification = () => set_showEmailVerification(!showEmailVerification);
    const onShowPhoneVerification = () => set_showPhoneVerification(!showPhoneVerification);

    const onEmailVerificationComplete = values => {
        if (!values.error){
            props.updateUser({ email_confirmed:1 })
            onShowEmailVerification();
        }
    }

    if (!user || !user._id) return <Redirect to="/" />
    if (loading) return <Loader loading={true} />

    return (<>

        {grocer_zambia_hipo_loyalty_api_key && <div style={{ marginBottom:"30px" }}>
            <MilestoneProgress title="Grocer Zambia" api_key={grocer_zambia_hipo_loyalty_api_key} />
        </div>}

        {mode == "edit" && <UserProfileForm {...props}
            inititalValues={user}
            onSubmit={onSubmit}
            toggleMode={() => toggleMode('preview')}
        />}

        {mode == "preview" && <UserProfileView {...props}
            showEmailVerification={onShowEmailVerification}
            showPhoneVerification={onShowPhoneVerification}
            toggleMode={() => toggleMode('edit')}
        />}

        <Modal title="Verify your email address" visible={showEmailVerification} width={620} onCancel={onShowEmailVerification} footer={null} bodyStyle={{ padding: "0px" }}>
            {showEmailVerification && <>
                <EmailVerification inline onSuccess={onEmailVerificationComplete} />
            </>}
        </Modal>

    </>)

    // if (mode == "edit") return <UserProfileForm {...props}
    //     inititalValues={user}
    //     onSubmit={onSubmit}
    //     toggleMode={() => toggleMode('preview')}
    // />
    
    // return <UserProfileView {...props} 
    //     showEmailVerification={onShowEmailVerification} 
    //     showPhoneVerification={onShowPhoneVerification} 
    //     toggleMode={() => toggleMode('edit')}
    // />

}


const WithApollo = compose(

    graphql(GET_PROFILE, {
        options: (props) => {
            return {
                variables: { id: props.user_id },
                fetchPolicy: "no-cache", //no-cache, cache-first, network-only, cache-only, cache-and-network
            };
        },
        props: ({ ownProps, data }) => {
            const { loading, user, error } = data;

            if (error) console.log(__error("error"), error);

            return { loading, userData:user, queryErrors: error, }
        },
    }),

    graphql(UPDATE_USER, {
        props: ({ mutate }) => ({
            editUser: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),


)(UserProfilePage);






const ModuleCheck = (props) => {
    if (!props.user || !props.user._id) return <E404 />
    return <WithApollo {...props} />
}
const mapStateToAvatarPopProps = state => {
    return ({ 
        grocer_zambia_hipo_loyalty_api_key: state?.grocer_storefront?.grocer_zambia_hipo_loyalty_api_key,
        user: state?.grocer_storefront?.user,
    });
}
const mapAvatarPopDispatchToProps = (dispatch, ownProps) => ({
    updateUser: (payload) => dispatch(updateUser(payload)),
})

const WithRedux = connect(mapStateToAvatarPopProps, mapAvatarPopDispatchToProps)(ModuleCheck);
export default WithRedux;
