import React from 'react'
import { Icon, Button } from 'Common/components'
import { Link } from 'react-router-dom';
import { appText } from 'configs'


const RegisterSuccess = props => {

    return (<>
        <div style={{ padding: "10px", alignItems: "center", display: "flex", flexDirection: "column", textAlign:"center" }}>
            <Icon icon="thumbs-up" color="#60a52c" style={{fontSize:"50px", margin:"30px"}} />
            <p style={{ padding: "0px", fontWeight: "600", fontSize: "1.5em" }}>Your registration is completed. Thank you for choosing {appText.app_name}.</p>
            <p style={{fontSize:"1.3em"}}>No you can choose from thousands of quality products which we will deliver <br /> to your doorstep ensuring the best satisfaction.</p>
            <Button theme="blue" style={{ width: "100px" }}><Link to="/">Home</Link></Button>
            {/* <span><SignInPage hideRegister /></span> */}
        </div>     
    </>)
}

export default RegisterSuccess;
