import React, { Component, useState } from 'react'
// import { graphql, withApollo } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { message, Alert, Row, Col } from 'antd';
import { connect } from "react-redux";
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { Button, DevBlock } from 'Common/components'
import { FormComponent, FormField, rules, composeValidators } from 'Common/components/Form'
import { __error } from 'Common/scripts/consoleHelper'
import { updateUser, loginUser } from 'Store/grocer_storefront.actions'
import { E404 } from 'Layout_v1';
import { processLoginRequest } from 'Common/scripts/Security';
import LocalStorage from 'Common/scripts/LocalStorage';
import { useHistory } from 'react-router-dom';

const VERIFY_EMAIL_CODE = loader('src/graphqls/user/verifyEmailCode.graphql');
const RESEND_EMAIL_CODE = loader('src/graphqls/user/resendEmailCode.graphql');
const UPDATE_EMAIL = loader('src/graphqls/user/updateUserEmail.graphql');

const verificationTimeout = 2 * 1.5;
class TimerComponent extends Component {
    initialTime = 10;
    state = { seconds: 0, timerStarted: false }
    interval = false;

    constructor(props) {
        super(props);
        this.intervalCalled = this.intervalCalled.bind(this);
    }

    // static getDerivedStateFromProps(props, state) {
    //     console.log("getDerivedStateFromProps()");

    //     if (props.timerStarted !== state.timerStarted) {
    //         this.startTimer()
    //         return { timerStarted: props.timerStarted };
    //     }
    //     return null;
    // }

    componentDidUpdate(prevProps) {
        // console.log("componentDidUpdate()");
        // console.log("prevProps: ", prevProps);
        // console.log("this.props: ", this.props);
        if (prevProps.timerStarted !== this.props.timerStarted) {
            if (this.props.timerStarted) this.startTimer();
            if (!this.props.timerStarted) this.stopTimer();
        }
    }

    componentDidMount() {
        // this.setState({ timerStarted: true, seconds: this.initialTime })
        if (this.props.timerStarted) this.startTimer()
    }
    componentWillUnmount() {
        this.stopTimer();
    }

    intervalCalled() {
        // console.log("intervalCalled()", this.state.seconds);
        if (this.state.seconds <= 0) {
            // this.setState({ timerStarted: false })
            this.stopTimer();
            if (this.props.onSuccess) this.props.onSuccess();
            return;
        }
        this.setState({ seconds: this.state.seconds - 1 })
    }

    startTimer() {
        // console.log("startTimer()");

        const { seconds, timerStarted } = this.state;
        if (timerStarted && this.interval) return;

        this.setState({ seconds: this.props.initialTime || this.initialTime, timerStarted: true });
        this.interval = setInterval(this.intervalCalled, 1000);
        // this.interval = setInterval(() => this.setState({ seconds: Number(seconds)-1 }), 1000);
    }
    stopTimer = () => {
        // console.log("stopTimer()");
        this.setState({ seconds: 0, timerStarted: false });
        clearInterval(this.interval);
    }

    render() {
        const { timerStarted, seconds } = this.state;
        // console.log(timerStarted, seconds);
        // if (!timerStarted) return null;
        return (<div style={{ ...this.props.style }}>
            {!timerStarted ? '' : `Next Request in ${this.state.seconds} seconds`}
        </div>)

        // return (<div style={{ ...this.props.style }}>{!timerStarted ? 'Verification code Expired' : `Expires in ${this.state.seconds} seconds`}</div>)
    }
}


const EmailVerification = props => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [redirecting, setRedirecting] = useState(false);
    const [timerStarted, setTimerStarted] = useState(true);
    const [enableResend, setEnableResend] = useState(false);
    const history = useHistory();

    const onVerificationsubmit = async (values) => {
        setLoading(true);
        setError(null);

        // const decoded = !props.match.params.login_reff_str ? false : JSON.parse(base64_decode(props.match.params.login_reff_str));
        const input = { code: `${values.d1}${values.d2}${values.d3}${values.d4}`, acc_string: props.acc_string }

        const verification_resutls = await props.verifyEmailCode(input).then(r => (r.data.verifyEmailCode))
            .catch(err => {
                console.log(__error("Error: "), err);
                return { error: { message: "Request Error!" } }
            })
        if (verification_resutls.error) {
            setError(verification_resutls.error.message);
            setLoading(false);
            return;
        }
        if (props.onSuccess) return props.onSuccess(verification_resutls)

        if (props?.user?._id){
            props.updateUser({ email_confirmed:1 });

            setRedirecting(true);
            history.push("/")
            // return sleep(100).then(r => {
            //     window.location = props.redirectTo || "/"
            // })
            return false;
        }

        const login_resutls = await processLoginRequest({ ...props.decoded, cart:null, client:null, onSuccessCallback:null });
       
        if (login_resutls.error){
            setError(login_resutls.error.message);
            setLoading(false);
            return;
        }

        alert("Congradulations! Your email is verified successfully.")

        await LocalStorage.login(login_resutls);
        // props.on_loginUser(login_resutls);
        props.loginUser(login_resutls);

        setRedirecting(true);
        history.push("/")
        // sleep(100).then(r=>{
        //     window.location = "/"
        // })
    }

    const onTimerComplete = args => {
        setEnableResend(true);
        setTimerStarted(false);
    }

    const on_resendCode = async args => {
        setLoading(true);
        setError(null);

        const resutls = await props.resendEmailCode({ acc_string: props.acc_string }).then(r=>{
            return r.data.resendEmailCode;
        }).catch(err=>{
            console.log(__error("Query Error: "), err);
            return { error: { message:"Query Error!" }}
        })

        setLoading(false);

        if (resutls.error){
            message.error(resutls.error.message);
            return;
        }

        message.success("Please check your email for new code")

        // setLoading(true);
        // resendCode(args).then(r => {
        //     setLoading(false);
        //     setEnableResend(false);
        //     setTimerStarted(true);
        //     if (r) {
        //     }
        // });
    }

    if (!props.inline && !props.acc_string) return(<E404 />)

    if (redirecting) return (<>
        <div style={{ padding: "10px", alignItems: "center", display: "flex", flexDirection: "column", textAlign: "center" }}>
            <p>Redirecting....</p>
        </div>
    </>)

    return (<>
        <div style={{ padding: "10px", alignItems: "center", display: "flex", flexDirection: "column", textAlign: "center" }}>
            <h4>Verify your Email Address</h4>

            {error && <Alert message={error} type='error' showIcon />}

            <FormComponent onSubmit={onVerificationsubmit} loading={loading || redirecting} id='VerifecationForm' debug={false} style={{ width: "350px", marginBottom: "0px" }} fields={{}}><>
                <Row>
                    <Col flex="auto"></Col>
                    <Col><FormField type="text" name="d1" style={{ textAlign: "center" }} width="60px" maxLength={1} validate={rules.required} /></Col>
                    <Col><FormField type="text" name="d2" style={{ textAlign: "center" }} width="60px" maxLength={1} validate={rules.required} /></Col>
                    <Col><FormField type="text" name="d3" style={{ textAlign: "center" }} width="60px" maxLength={1} validate={rules.required} /></Col>
                    <Col><FormField type="text" name="d4" style={{ textAlign: "center" }} width="60px" maxLength={1} validate={rules.required} /></Col>
                    <Col flex="auto"></Col>
                </Row>

                <Button type="primary" htmlType="submit" disabled={redirecting} block size="large" style={{ margin: "10px 0" }}>Verify Registration</Button>

                <TimerComponent timerStarted={timerStarted} onSuccess={onTimerComplete} initialTime={verificationTimeout} />
                <Row style={{ width: "350px" }}>
                    <Col flex="auto"></Col>
                    <Col><Button type="link" disabled={!enableResend || redirecting} onClick={on_resendCode}>Resend Code</Button></Col>
                </Row>

            </></FormComponent>

        </div>

    </>)

}
EmailVerification.propTypes = {
    inline: PropTypes.bool,
    onSuccess: PropTypes.func,
}


const WithApollo = compose(
    
    graphql(VERIFY_EMAIL_CODE, {
        props: ({ mutate }) => ({
            verifyEmailCode: (args) => mutate({
                variables: { ...args }
            }),
        })
    }),

    graphql(RESEND_EMAIL_CODE, {
        props: ({ mutate }) => ({
            resendEmailCode: (args) => mutate({
                variables: { ...args }
            }),
        })
    }),

    graphql(UPDATE_EMAIL, {
        props: ({ mutate }) => ({
            updateUserEmail: (args) => mutate({
                variables: { ...args }
            }),
        })
    }),

)(EmailVerification);
// const WithApollo = withApollo(EmailVerification)

const mapStateToAvatarPopProps = (state, ownProps) => {
    const { grocer_storefront: { user } } = state;

    let decoded = false;
    let acc_string;
    if (ownProps?.match?.params?.login_reff_str) {
        decoded = JSON.parse(base64_decode(ownProps.match.params.login_reff_str));
        acc_string = ownProps.match.params.login_reff_str;
    }else{
        acc_string = { username: user.email, _id: user._id }
        acc_string = base64_encode(JSON.stringify(acc_string))
    }

    return { 
        acc_string,
        decoded,
        user,
        new_user: ownProps.user,
    }
}
const mapAvatarPopDispatchToProps = (dispatch, ownProps) => ({
    updateUser: (payload) => dispatch(updateUser(payload)),
    loginUser: (payload) => dispatch(loginUser(payload)),
})
export default connect(mapStateToAvatarPopProps, mapAvatarPopDispatchToProps)(WithApollo);
