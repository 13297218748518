import React, { useState } from 'react'
// import { graphql } from '@apollo/client/react/hoc';
import { graphql, withApollo } from 'react-apollo';

import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { connect } from "react-redux";

import { Button, DevBlock, Icon } from 'Common/components'
import { FormComponent, FormField, rules, composeValidators } from 'Common/components/Form'
import { message, Alert, Row, Col } from 'antd'
import { __error } from 'Common/scripts/consoleHelper';
import { couponSelect } from '../redux/actions'
import { parseNewLine } from 'Common/scripts/Functions';

const VALIDATE_VOUCHER = loader('src/graphqls/discount_voucher/validateVoucherForOrder.graphql');


const RenderVoucher = ({ coupon, onCouponCancel }) => {
    const [showDetails, set_showDetails] = useState(false)

    return (<>
        {/* <DevBlock obj={coupon} /> */}
        <div style={{ border: "2px dashed #FFA503", borderRadius: "5px", margin: "5px 0" }}>
            <div align="center">
                <span style={{ fontSize: "24px", color: "#FFA503" }}>{coupon.loyalty_plan ? "Loyalty Reward" : "Voucher Reward"}</span>
                {` `}
                <Button onClick={onCouponCancel} size="small" shape="rounded" type="danger">Discard</Button>
            </div>
            <div align="center"><b>{coupon?.success?.message}</b></div>
            {/* <div align="center">{coupon.success.details}</div> */}

            <div align="center" className='a' onClick={() => set_showDetails(!showDetails)}>- More Details -</div>
            {showDetails && <div style={{ fontSize: "14px", padding:"5px 20px" }}>{parseNewLine(coupon?.success?.details)}</div>}

            {/* <DevBlock obj={coupon} /> */}
        </div>
    </>)
}

const _CouponDiscountForm = ({ order, user, validateVoucherForOrder, couponSelect, loyalty_plan }) => {
    const { coupon, invoice } = order;

    const [errorMessage, setErrorMessage] = useState(null);

    if (!user || !user._id) return <div>Invalid User</div>

    const onSubmit = async (values) => {
        setErrorMessage(null);

        const results = await validateVoucherForOrder({ 
            code: String(values.coupon_code).trim(), 
            customer_id: user._id,
            loyalty_plan: loyalty_plan ? true : false
        })
            .then(({ data }) => (data.validateVoucherForOrder))
            .catch(err => {
                console.log(__error("Query Error"), err);
                return { error: { message:"Query Error"}}
            })
        // console.log("results: ", results)

        if (!results || results.error){
            couponSelect(null);
            return setErrorMessage(results && results.error.message || "Voucher not found!");
        }

        couponSelect(results)
        return false;

    }

    const onCouponCancel = () => couponSelect(undefined);

    if (coupon && coupon._id && !coupon.error) return <RenderVoucher coupon={coupon} onCouponCancel={onCouponCancel} />


    return (<>
        <FormComponent onSubmit={onSubmit} id='VoucherValidationform' style={{ width: "100%" }}
            form_render={formProps => {
                const { values, dirty, errors, submitting } = formProps;

                return (<>
                    {errorMessage && <Alert showIcon type="error" message={errorMessage} description={null} />}

                    <Row gutter={[10]}>
                        <Col flex="auto">
                            <FormField type="text" wrapperStyle={{ margin: "0 0 5px 0" }} allowClear
                                // prefix={!values.coupon_code ? undefined : <Icon icon={order.coupon ? "check" : "times"} color={order.coupon ? "#60A52C" : "red"} />}
                                label="Redeam Your Discount Coupon!" placeholder="Enter the coupon code to reveal advantages" name="coupon_code"
                                validate={composeValidators(rules.minChar(4))}
                            />
                        </Col>
                        <Col>
                            <label>&nbsp;</label>
                            <Button type="primary" htmlType="submit" theme="orange" size="large" loading={submitting} disabled={(!dirty || Object.keys(errors).length > 0)}>Reveal</Button>
                        </Col>
                    </Row>

                </>)
            }}
        />

    </>)
}
const CouponDiscountForm = compose(
    graphql(VALIDATE_VOUCHER, {
        props: ({ mutate }) => ({
            validateVoucherForOrder: (args) => mutate({
                variables: { ...args }
            }),
        })
    }),
)(_CouponDiscountForm);

const mapDispatchToProps = (dispatch, ownProps) => ({
    couponSelect: (payload) => dispatch(couponSelect(payload)),
})
const mapStateToProps = state => {
    return ({ 
        order: state.order, 
        user: state?.grocer_storefront?.user,
    });
}
export default connect(mapStateToProps, mapDispatchToProps)(CouponDiscountForm);
// export default CouponDiscountForm;