import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Link, Redirect, withRouter, useHistory } from 'react-router-dom';
import { connect } from "react-redux";
import { __error } from 'Common/scripts/consoleHelper';
// import { useMediaQuery } from 'react-responsive'
// import { isMobile, isTablet, withOrientationChange } from 'react-device-detect';
import { Layout, Menu, Col, Row, Popover, Divider, Affix, Badge, Modal, Alert, message, Space } from 'antd';
import { Icon, Button, Loader, Drawer, Avatar } from 'Common/components';
import LayoutTopMenu from './LayoutTopMenu';
// import { sleep } from 'Common/scripts/Functions';
import { logoutUser, updateUser, updateSettings } from 'Store/grocer_storefront.actions';
import { flushCart } from 'Store/cart.actions'
import Cart from './Cart';
import SiteSearch from './SiteSearch';
import LocalStorage from 'Common/scripts/LocalStorage';
import { useWindowSize } from 'Common/hooks';


const { Header } = Layout;

const CurrentlyShppoing = ({cart}) => {
    if (!cart || cart.totalItems < 1 || !cart?.store?._id) return null;

    return (<div className='currently-shpooing-wrapper'>
        <div className='contents'>
            <Link className='the-button' to={`/store/${cart.store.slug}`}>Currently Shopping at: {cart.store.title}</Link>
        </div>
    </div>)
}

// export const LoggedInUserHeader = ({ user, settings }) => {
//     const [popVisible, setPopVisible] = React.useState(false);
//     const isSmallDevice = useMediaQuery({ query: '(max-device-width: 365px)' })
//     const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
//     const _isMobile = (isMobile || isSmallDevice) && !isTablet;

//     console.log("settings: ", settings)

//     const handleClickChange = visible => {
//         setPopVisible(visible);
//     };

//     if (_isMobile){
//         return (<div className="mobile-side-menu">
//             <Link to="/user/my-orders" className="menu-item"><Row>
//                 <Col><Icon className="link-icon" icon={['fa', 'truck']} /></Col>
//                 <Col flex="auto">My Orders</Col>
//             </Row></Link>
//         </div>)
//     }

//     return (<>
//         {(user && user.ordersCount > 0) && <>
//             <Button theme="light-gray" size="large"><Link to="/user/my-orders">My Orders</Link></Button>
//             <Divider type="vertical" />
//         </>}

//         <div style={{ display: "inline-block", }}>
//             <Popover
//                 visible={popVisible}
//                 content={<YourAccountMenu hide={() => setPopVisible(false)} />}
//                 onVisibleChange={handleClickChange}
//                 // onClick={setPopVisible(true)}
//                 title={<div style={{ textAlign: "center" }}>{user && (user.name || user.email)}</div>}
//                 trigger="click"
//                 placement="bottomRight" overlayClassName="your-acc-pop">
//                 <Button theme="light-gray" size="large" style={{ margin: "0" }}>Your Account <Icon style={{marginLeft:"3px"}} icon="chevron-down" /></Button>
//             </Popover>
//         </div>
//         <Divider type="vertical" />

//         <Cart />
//     </>)
// }

// export const NotLoggedInUserHeader = () => {
//     return(<>
//         <Button theme="light-gray" size="large"><Link to="/user/register">Register</Link></Button>
//         <Button className="blue" size="large" style={{ margin: "0 0 0 10px", width: "130px" }}><Link to="/user/sign-in">Sign In</Link></Button>
//         <Divider type="vertical" />
//         <Cart />
//     </>)
// }

export const YourAccountMenu_Wrapper = ({ settings, onClose, user, on_flushCart, on_logoutUser, inFooter }) => {
    const { phone_confirmed, email_confirmed } = user || {};
    // const [mobileVerificationModal, setMobileVerificationModal] = useState(false);

    let history = useHistory();

    const confirmSignOut = () => {
        // props.hide();
        Modal.confirm(
            {
                centered: true,
                // className:"confirmation-pop",
                title: 'Are You Sure',
                // icon: <Icon icon="exclamation-triangle" size="8x" color="orange" className='anticon' />,
                content: 'Are you really sure you want to sign out?',

                cancelText: "Cancel",
                cancelButtonProps: { block: true },

                okText: "Yes, sign out",
                okButtonProps: { block: true },

                onOk() { onLogout(); },
                onCancel() { console.log('Cancel'); },
            }
        );
    }

    const onLogout = async args => {
        await LocalStorage.logout().then(r=>{
            console.log("LocalStorage.logout: ", r)
        });

        on_flushCart();
        on_logoutUser();
        history.push("/")
        // sleep(1).then(r => window.location = "/")
    }

    var confirmationRequired = [];
    // if (email_confirmed < 1 || phone_confirmed < 1){
    if (email_confirmed < 1){
        confirmationRequired.push(<Link to="/user/profile" style={{ backgroundColor: "#FFFBE6", color:"orange", display:"block", width:"100%", border:"1px solid #FFE58F"}}>
            <Icon icon="exclamation-triangle" /> Verification required
        </Link>)
    }
    // if (phone_confirmed.length<1 && email_confirmed < 1){
    //     confirmationRequired.push(<Link to="/user/profile" style={{ backgroundColor: "#FFFBE6", display: "block", color: "orange", width: "100%", border: "1px solid #FFE58F" }}>
    //         <Icon icon="exclamation-triangle" /> Verification required
    //     </Link>)
    // }


    if (inFooter){
        return (<>
            {confirmationRequired && <div style={{ marginBottom:"10px" }}>
                {confirmationRequired.map((o, i) => (<div key={i}>{o}</div>))}
            </div>}
            <Button type="default" theme="blue-outline" block onClick={confirmSignOut}>Sign out</Button>
        </>)
    }


    return (<>
        <div style={{ width: '250px' }}>
            <Row gutter={[0, 12]}>
                {/* <Col flex="60px"><Avatar size={60}>U</Avatar></Col> */}
                <Col flex="auto" style={{ display: "flex", flexDirection: "column", alignContent: "left", textAlign: "left" }}>
                    <Link to="/user/profile" onClick={onClose} className="menu-item"><Row>
                        <Col><Icon icon="cog" color="#999" className="ico link-icon" /></Col><Col flex="auto">My Account</Col>
                    </Row></Link>
                    <Link to="/user/profile" onClick={onClose} className="menu-item"><Row>
                        <Col><Icon icon="trophy" color="#999" className="ico link-icon" /></Col><Col flex="auto">Grocer Zambia Rewards</Col>
                    </Row></Link>

                    <Link to="/user/profile/addresses" onClick={onClose} className="menu-item"><Row>
                        <Col><Icon icon="address-book" color="#999" className="ico link-icon" /></Col><Col flex="auto">My Addresses</Col>
                    </Row></Link>

                    <Link to="/user/my-orders" onClick={onClose} className="menu-item"><Row>
                        <Col><Icon icon="truck" color="#999" className="ico link-icon" /></Col><Col flex="auto">My Orders</Col>
                    </Row></Link>

                    {/* <Link to="/user/loyalty-points" onClick={onClose} className="menu-item"><Row>
                        <Col><Icon icon="coins" color="#999" className="ico link-icon" /></Col><Col flex="auto">My Loyalty Points</Col>
                    </Row></Link> */}

                    {/* <Link to="/bug-report"><Icon icon="exclamation-triangle" /> Bug Report</Link>
                    <Divider type="horizontal" style={{ margin: "5px" }} /> */}
                </Col>
            </Row>

            <Divider type="horizontal" style={{ margin: "0 0 10px 0" }} />
            <div style={{ margin: "0 10px" }}>
                {confirmationRequired && <div style={{ marginBottom: "10px" }}>{confirmationRequired.map((o, i) => (<div key={i}>{o}</div>))}</div>}

                {/* {phone_confirmed < 1 && <>
                    <Button type="danger" style={{ margin: "0 0 5px 0" }} onClick={() => setMobileVerificationModal(true)} block><>
                        <Icon icon="phone-alt" className="ico link-icon" /> Confirm Account
                    </></Button>
                </>} */}
                <Button type="default" theme="blue-outline" block onClick={confirmSignOut}>Sign out</Button>
            </div>
            
            <Divider type="horizontal" style={{ margin: "10px 0 10px 0" }} />
            <div style={{ margin: "0 10px", color: "#999", fontSize:"12px", textAlign:"center" }}>Timezone: {settings.default_timezone}</div>

        </div>

    </>)
}
const _props = (state) => {
    return ({
        user: state?.grocer_storefront?.user,
    });
}
const _funcs = (dispatch, ownProps) => ({
    on_flushCart: () => dispatch(flushCart()),
    on_logoutUser: () => dispatch(logoutUser()),
    on_updateUser: (payload) => dispatch(updateUser(payload)),
})
export const YourAccountMenu = connect(_props, _funcs)(YourAccountMenu_Wrapper);

const LoggedInUserMenu = ({ user, settings }) => {
    const [popVisible, setPopVisible] = useState(false);
    if (!user) return null;

    // console.log("user: ", user)

    const handleClickChange = visible => setPopVisible(visible);

    return (<div style={{ display: "inline-block" }}>
        <Popover
            visible={popVisible}
            content={<YourAccountMenu settings={settings} user={user} hide={() => setPopVisible(false)} />}
            onVisibleChange={handleClickChange} // onClick={setPopVisible(true)}
            title={<div style={{ textAlign: "center" }}>{user && (user.fname || user.email)}</div>}
            trigger="click"
            placement="bottomRight" overlayClassName="your-acc-pop">
            <div className='your-account-button'><Row align="middle" gutter={[5]}>
                {/* <Col><Avatar style={{ border: "1px solid #EEE", marginLeft: "-1px", color: "#999999" }} size={38}>{String(user.fname[0]).toUpperCase()}</Avatar></Col> */}
                <Col><Icon style={{ fontSize: "36px" }} color="#008D84" icon="user-circle" /></Col>
                <Col><span className='ellipsis' style={{ maxWidth:"90px", display:"inline-block" }}>{user?.fname || `My Account`}</span></Col>
                <Col><Icon style={{ marginLeft: "3px" }} icon="chevron-down" /></Col>
            </Row></div>
        </Popover>
    </div>)
}

const _LayoutHeader = ({ user, cart, categories, loading, container, settings }) => {
    const [showDrawerMenu, setShowDrawerMenu] = React.useState(false);
    const [showCartDrawer, setShowCartDrawer] = React.useState(false);
    const [affixed, setAffix] = React.useState(false);
    
    const [screenSize] = useWindowSize();
    const isSmallDevice = screenSize.width < 850;

    return (<>
        <Affix offsetTop={-75} onChange={_affixed => { setAffix(_affixed); }}><>
            <div className={`site-layout-header-wrapper ${isSmallDevice && 'isMobile'} ${affixed ? 'affixed' : ''}`}>
                {isSmallDevice && <>
                    <Row align="middle" gutter={[10]} style={{ width:"100%" }}>
                        <Col><Button onClick={() => setShowDrawerMenu(true)} size="large" icon={<span style={{ fontSize: "26px" }}><Icon icon="bars" color="rgba(21, 25, 32, 0.5)" /></span>} theme="white" /></Col>
                        <Col flex="auto" align="center"><Link to="/"><img src={`/assets/grocerzm-logo-190.png`} width="100%" style={{ maxWidth:"200px" }} /></Link></Col>
                        <Col><Cart /></Col>
                    </Row>
                </>}

                {!isSmallDevice && <Header className="site-layout-header" >
                    <Row align="middle" gutter={[10]}>
                        {affixed && <Col><Button onClick={() => setShowDrawerMenu(true)} size="large" icon={<span style={{ fontSize: "26px" }}><Icon icon="bars" color="rgba(21, 25, 32, 0.5)" /></span>} theme="white" /></Col>}
                        <Col><Link to="/"><img src={`/assets/grocerzm-logo-190.png`} width="190px" height="65px" /></Link></Col>
                        <Col flex="auto"><SiteSearch /></Col>
                        {(!user) && <>
                            <Col><Button shape="round" theme="orange" size="large" onClick={() => setShowDrawerMenu(false)} style={{ width: "110px" }}><Link to="/user/register">Register</Link></Button></Col>
                            <Col><Button shape="round" theme="green" size="large" onClick={() => setShowDrawerMenu(false)} style={{ width: "110px" }}><Link to="/user/sign-in">Sign In</Link></Button></Col>
                        </>}
                        {user && <Col><LoggedInUserMenu settings={settings} user={user} /></Col>}
                        {/* <Divider type="vertical" /> */}
                        <Col><Cart /></Col>
                    </Row>
                </Header>}
            </div>
            
        </></Affix>

        {!isSmallDevice && <LayoutTopMenu loading={loading} categories={categories} affixed={affixed} visible={false} onCartClick={() => setShowCartDrawer(!showCartDrawer)} />}
        <CurrentlyShppoing cart={cart} />

        <Drawer width={isSmallDevice ? "100%" : '600px'} destroyOnClose={false} maskClosable={true} placement="left" visible={showDrawerMenu} onClose={() => setShowDrawerMenu(false)}
            bodyStyle={{}} className={``} 
            // headerStyle={{ backgroundColor:"#F6F6F6"}}
            footer={!user ? false : <div style={{ width:"100%" }}>
                <YourAccountMenu onClose={console.log} user={user} on_flushCart={console.log} on_logoutUser={console.log} inFooter />
            </div>}
            title={!isSmallDevice ? <div style={{ color:"white", fontSize:"22px" }}>Navigation</div> : <>
                {user ? <>
                    <Row>
                        <Col flex="auto"><Link to="/user/profile"><div className='your-account-button white'>
                            <Row align="middle" gutter={[5]}>
                                <Col><Icon style={{ fontSize: "36px" }} color="#008D84" icon="user-circle" /></Col>
                                <Col>My Account</Col>
                            </Row>
                        </div></Link></Col>
                        <Col flex="50px" />
                    </Row>
                </> : <>
                    <Row gutter={[10]}>
                        <Col span={11}><Button shape="round" theme="orange" onClick={() => setShowDrawerMenu(false)} size="large" block><Link to="/user/register">Register</Link></Button></Col>
                        <Col span={11}><Button shape="round" theme="white" onClick={() => setShowDrawerMenu(false)} size="large" block><Link to="/user/sign-in">Sign In</Link></Button></Col>
                        <Col span={2} />
                    </Row>
                </>}
            </>}
        ><>
            <div className=''></div>
            <Space direction='vertical' style={{ width:"100%" }}>
                {/* <Button block size="large" theme="white-outline" style={{ textAlign:"left", fontSize:"22px" }}>My Account</Button> */}
                <Button block size="large" theme="white-outline" style={{ textAlign: "left", fontSize: "22px" }}>
                    <Link to="/stores">Stores</Link>
                </Button>
                {/* <Button block size="large" theme="white-outline" style={{ textAlign: "left", fontSize: "22px" }}>Categories</Button> */}
                
                <Divider style={{fontSize:"24px"}}>Categories</Divider>
                <Row gutter={[10, 10]}>
                        {categories && categories.map((cat, i)=>{
                        return <Col key={i}><Button size="large" theme="white-outline">
                            <Link to={`/category/${cat.slug}`}>{cat.title} ({cat.prodCounts})</Link>
                        </Button></Col>
                    })}
                </Row>

            </Space>

        </></Drawer>

    </>)

}
_LayoutHeader.propTypes = {
    collapsed: PropTypes.bool,
    onToggleClick: PropTypes.func,
    menu: PropTypes.array,
    // topMenu: PropTypes.array,
    // labelA: PropTypes.oneOfType([
    //             PropTypes.string,
    //             PropTypes.object
    //         ]).isRequired,
}

const mapStateToProps = state => {
    return ({ 
        user: state?.grocer_storefront?.user,
        cart: state.cart, 
        categories: state?.nav?.categories, 
        settings: state.grocer_storefront 
    });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    // loginUser: (payload) => dispatch(loginUser(payload)),
    on_logoutUser: () => dispatch(logoutUser()),
    on_updateUser: (payload) => dispatch(updateUser(payload)),
    on_updateSettings: (payload) => dispatch(updateSettings(payload)),
})
const WithRedux = connect(mapStateToProps, mapDispatchToProps)(_LayoutHeader);
export const LayoutHeader = withRouter(WithRedux);
// export default LayoutHeader;

