import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form'
import InputMask from "react-input-mask"; 
import _ from 'lodash'
import {
    Checkbox as _Checkbox,
    Input as _Input,
    InputNumber as _InputNumber,
    Switch, Alert,
    Select as _Select,
    Radio as _Radio,
    Upload, Modal, message,
    Row, Col
} from 'antd';

import { getBase64 } from 'Common/scripts/Functions';
import { __warning, __error, __success, __hilight } from 'Common/scripts/consoleHelper';
import { Button, Icon } from '../';
import UploadField from './UploadField';
import DateField from './DateField';

// const { Search } = _Input;

export const Label = props => <label className="form-field-label">{props.children}</label>

/******** Field Usage*****************
 * 
 * Simple Default Input
    <Field name="firstName" component="input" placeholder="First Name" />

 * An Arbitrary Reusable Input Component
    <Field name="interests" component={InterestPicker} />

 * Render Function
    <Field
          name="bio"
          render={({ input, meta }) => (
            <div>
              <label>Bio</label>
              <textarea {...input} />
              {meta.touched && meta.error && <span>{meta.error}</span>}
            </div>
          )}
        />

 * Render Function as Children
    <Field name="phone">
          {({ input, meta }) => (
            <div>
              <label>Phone</label>
              <input type="text" {...input} placeholder="Phone" />
              {meta.touched && meta.error && <span>{meta.error}</span>}
            </div>
          )}
        </Field>

 */



export const SearchField = props => {
    return <_Input.Search {...props} />;
}
SearchField.propTypes = {
    onSearch: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    // enterButton: PropTypes.string,
    size: PropTypes.string, // large | default | small
    placeholder: PropTypes.any,
    suffix: PropTypes.oneOfType([
        PropTypes.string, PropTypes.object
    ]),
    addonAfter: PropTypes.oneOfType([
        PropTypes.string, PropTypes.object
    ]),
    addonBefore: PropTypes.oneOfType([
        PropTypes.string, PropTypes.object
    ]),
}


/*****
 <FormField
    name="username"
    label="User name"
    type="text"
    placeholder="User name"
    validate={composeValidators(rules.required, rules.minChar(6))} />
    prefix={Node | String}
    addonAfter={Node | String}
    addonBefore={Node | String}
 */
export const FormField = props => {

    if (!props.type) return <Alert message="Undefined field type" type="warning" showIcon />
    if (!props.name) return <Alert message="Undefined field name" type="warning" showIcon />
    
    else if (
            props.type == 'text'
            // || props.type == 'password'
            || props.type == 'email'
            // || props.type == 'number'
        ) return TextField(props);
    
    else if ( props.type == 'mask' ) return MaskedField(props);
    else if ( props.type == 'phone' ) return PhoneField(props);
    
    else if (props.type == 'password') return PasswordField(props);

    else if (props.type == 'textarea') return TextareaField(props);
        
    else if (props.type == 'number') return NumberField(props);
        
    else if (props.type == 'checkbox') return CheckboxField(props);

    else if (props.type == 'radio') return RadioField(props);

    else if (props.type == 'radio-button-field') return RadioButtonGroup(props);

    else if (props.type == 'switch') return SwitchField(props);

    else if (props.type == 'select') return SelectField(props);

    else if (props.type == 'select-multiple') return SelectFieldMultiple(props);
        
    else if (props.type == 'upload') return UploadField(props);
    
    else if (props.type == 'date') return DateField(props);
    // else if (props.type == 'time') return TimeField(props);
    // else if (props.type == 'time&time') return TimeAndDateField(props);
    
    else return <div>Undfined input type</div>

}
export default FormField;
FormField.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.any,
    placeholder: PropTypes.any,
    required: PropTypes.bool,
    // labelB: PropTypes.any,
    prefix: PropTypes.oneOfType([
                PropTypes.string, PropTypes.object
            ]),
    addonAfter: PropTypes.oneOfType([
                PropTypes.string, PropTypes.object
            ]),
    addonBefore: PropTypes.oneOfType([
                PropTypes.string, PropTypes.object
            ]),
}



/*******
 * name={string}
 * placeholder={string}
 * type={string} // The type of input, see: MDN(use Input.TextArea instead of type="textarea")
 * size={large | middle | small}
 * prefix={string | ReactNode}
 * suffix={string | ReactNode}
 * validate={composeValidators(rules.required, rules.minChar(6))} />
 */
export const TextField = props => {
    const propTypes = {
        name: PropTypes.string.isRequired,
        // data: PropTypes.array.isRequired,
        // inputProps: PropTypes.object,
    }

    let _props = { ...props }
    // delete _props.inputProps;
    delete _props.data;
    delete _props.label;
    delete _props.width;
    delete _props.style;
    delete _props.onChange;
    delete _props.compact;
    delete _props.validate;
    delete _props.wrapperStyle;
    delete _props.wrapperClass;

    let style = { width: "100%", ...props.style }
    if (props.width) style = Object.assign(style, { width: props.width })

    // let _inputProps = { ...props.inputProps };
    // if (props.disabled) _inputProps = Object.assign(_inputProps, { disabled:props.disabled })

    return (
        <Field {...props}>
            {({ input, meta }) => { 
                if (props.onChange) {
                    _props.onChange = (e) => props.onChange(e, input.onChange);
                }

                return (
                    <div style={props.wrapperStyle || {}} className={`form-field ${!props.compact && "field-margins"} input ${props.wrapperClass}`}>
                        {props.label && <label>{props.label}</label>}
                        <_Input {...input}
                            {..._props}
                            // maxChar={1}
                            style={{ ...style }}
                            // size={props.size || "default"}
                            // placeholder={props.placeholder}
                            // prefix={<div>Hello</div>}
                            // prefix={props.prefix}
                            // suffix={props.suffix}
                        />
                        {/* <_Input {...input} type={props.type} placeholder={props.placeholder} /> */}
                        {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                    </div>
                )
            }}
        </Field>
    )
}




export const MaskedField = props => {
    const propTypes = {
        name: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        // data: PropTypes.array.isRequired,
        // inputProps: PropTypes.object,
    }

    let _props = { ...props }
    // delete _props.inputProps;
    delete _props.data;
    delete _props.label;
    delete _props.width;
    delete _props.style;
    delete _props.onChange;
    delete _props.compact;
    delete _props.validate;
    delete _props.wrapperStyle;
    delete _props.wrapperClass;
    // delete _props.category;
    delete _props.type;
    delete _props.mask;

    let style = { width: "100%", ...props.style }
    if (props.width) style = Object.assign(style, { width: props.width })

    // let _inputProps = { ...props.inputProps };
    // if (props.disabled) _inputProps = Object.assign(_inputProps, { disabled:props.disabled })

    return (<Field
        validate={props.validate}
        name={props.name}
        parse={value =>
            value
                .replace(/\)/g, "")
                .replace(/\(/g, "")
                .replace(/-/g, "")
                .replace(/ /g, "")
        }
        render={({ input, meta }) => {
            // if (props.onChange) {
            //     _props.onChange = (e) => props.onChange(e, input.onChange);
            // }

            return (<div style={props.wrapperStyle || {}} className={`form-field ${!props.compact && "field-margins"} input ${props.wrapperClass}`}>
                {props.label && <label>{props.label}</label>}

                <InputMask
                    disabled={false}
                    mask={props.mask || "+260 999 999-99-99"} //"+\92 (999) 999-99-99"
                    {...input}
                    // onChange={(e)=>{
                    //     let val = e.target.value;
                    //         val = val.replace(/\)/g, "").replace(/\(/g, "").replace(/-/g, "").replace(/ /g, "").replace(/_/g, "")
                    //     console.log(val);
                    //     return e
                    // }}
                    style={{ ...style }}
                >
                    {InputProps => {
                        return <_Input disabled={false} {...InputProps} placeholder={props.placeholder} />;
                    }}
                </InputMask>

                {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
            </div>)
        }}
    />)

}

export const PhoneField = props => {
    return <MaskedField 
        placeholder="+260 XXX XXX XX XX"
        name="phone"
        type="mask"
        mask="+\260 999 999-99-99"
        label="Mobile Number"
        {...props}
    />
}



export const PasswordField = props => {
    const propTypes = {
        name: PropTypes.string.isRequired,
        // data: PropTypes.array.isRequired,
        // inputProps: PropTypes.object,
    }

    let _props = { ...props }
    // delete _props.inputProps;
    // delete _props.data;
    delete _props.label;
    delete _props.width;
    delete _props.style;
    delete _props.onChange;
    delete _props.compact;
    delete _props.validate;
    delete _props.wrapperStyle;
    delete _props.wrapperClass;

    let style = { width: "100%", ...props.style }
    if (props.width) style = Object.assign(style, { width: props.width })

    return (
        <Field {...props}>
            {({ input, meta }) => { 
                if (props.onChange) {
                    _props.onChange = (e) => props.onChange(e, input.onChange);
                }

                return (
                    <div style={props.wrapperStyle || {}} className={`form-field ${!props.compact && "field-margins"} input ${props.wrapperClass}`}>
                        {props.label && <label>{props.label}</label>}
                        <_Input.Password {...input}
                            {..._props}
                            style={{ ...style }}
                            // size={props.size || "default"}
                            // placeholder={props.placeholder}
                            // prefix={props.prefix}
                            // suffix={props.suffix}
                        />
                        {/* <_Input {...input} type={props.type} placeholder={props.placeholder} /> */}
                        {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                    </div>
                )
            }}
        </Field>
    )
}

export const TextareaField = props => {
    const propTypes = {
        name: PropTypes.string.isRequired,
        data: PropTypes.array.isRequired,
        wrapperClass: PropTypes.string,
    }

    let _props = { ...props }
    delete _props.inputProps;
    delete _props.data;
    delete _props.label;
    delete _props.width;
    delete _props.style;
    delete _props.onChange;
    delete _props.compact;
    delete _props.validate;
    delete _props.wrapperClass;
    delete _props.wrapperStyle;

    let style = { width: "100%", ...props.style }
    if (props.width) style = Object.assign(style, { width: props.width })

    // let _inputProps = { ...props.inputProps };
    // if (props.disabled) _inputProps = Object.assign(_inputProps, { disabled: props.disabled })

    return (
        <Field {...props}>
            {({ input, meta }) => (
                <div style={props.wrapperStyle || {}} className={`form-field ${!props.compact && "field-margins"} input ${props.wrapperClass}`}>
                    {props.label && <label>{props.label}</label>}
                    <_Input.TextArea {...input}
                        {..._props}
                        rows={props.rows || 4}
                        style={{ ...style }}
                        // size={props.size || "default"}
                        // placeholder={props.placeholder}
                        // prefix={props.prefix}
                        // suffix={props.suffix}
                    />
                    {/* <_Input {...input} type={props.type} placeholder={props.placeholder} /> */}
                    {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                </div>
            )}
        </Field>
    )
}

export const NumberField = props => {
    const propTypes = {
        name: PropTypes.string.isRequired,
        min: PropTypes.number,
        max: PropTypes.number,
        disabled: PropTypes.bool,
        // data: PropTypes.array.isRequired,
    }

    let _props = { ...props }
    // delete _props.inputProps;
    delete _props.data;
    delete _props.label;
    delete _props.width;
    delete _props.style;
    delete _props.type;
    delete _props.compact;
    delete _props.validate;
    delete _props.wrapperStyle;
    delete _props.wrapperClass;
    // delete _props.onChange;

    let style = { width: "100%", ...props.style }
    if (props.width) style = Object.assign(style, { width: props.width })

    // let _inputProps = { ...props.inputProps };
    // if (props.disabled) _inputProps = Object.assign(_inputProps, { disabled: props.disabled })

    return (
        <Field {...props}>
            {({ input, meta }) => (
                // <div className={`form-field ${!props.compact && "field-margins"} input-number ${props.className}`}>
                <div style={props.wrapperStyle || {}} className={`form-field ${!props.compact && "field-margins"} input-number ${props.wrapperClass}`}>
                    {props.label && <label>{props.label}<br /></label>}
                    <_InputNumber {...input}
                        {..._props}
                        style={{ ...style}}
                        // size={props.size || "default"}
                        // prefix={props.prefix}
                        // suffix={props.suffix}
                    />
                    {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                </div>
            )}
        </Field>
    )
}

export const SwitchField = props => {
    // let _inputProps = { ...props.inputProps };
    // if (props.disabled) _inputProps = Object.assign(_inputProps, { disabled: props.disabled })

    let _props = { ...props };
    // delete _props.inputProps;
    delete _props.data;
    delete _props.label;
    delete _props.width;
    delete _props.style;
    delete _props.type;
    delete _props.compact;
    delete _props.validate;
    delete _props.wrapperStyle;
    delete _props.wrapperClass;

    return (
        <Field name={props.name} validate={props.validate}>
            {({ input, meta }) => { 
                let _input = { ...input}
                delete _input.checked;
                delete _input.value;
                
                return (
                    // <div className={`form-field ${!props.compact && "field-margins"} switch ${props.className}`}>
                    <div style={props.wrapperStyle || {}} className={`form-field ${!props.compact && "field-margins"} switch ${props.wrapperClass}`}>
                        {/* <input {...input} type={props.type} placeholder={props.placeholder} /> */}
                        <span>
                            <Switch {..._input} {..._props}
                                checkedChildren={props.checkedText || <Icon icon="check" />}
                                unCheckedChildren={props.uncheckedText || <Icon icon="times" />}
                                defaultChecked={input.value ? true : props.defaultChecked}
                            />
                            {props.label && <label>{props.label}</label>}
                            {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                        </span>
                    </div>
                )
            }}
        </Field>
    )
}

export const RadioField = props => {

    let _props = { ...props };
    delete _props.name;
    delete _props.label;
    delete _props.type;
    delete _props.children;
    delete _props.wrapperStyle;
    delete _props.wrapperClass;

    return (
        <Field {...props} 
        // name={props.name} validate={props.validate} 
        type="radio">
            {({ input, meta }) => { 
                let _input = { ...input}
                {/* console.log('====================================');
                console.log("_input: ", _input);
                console.log('===================================='); */}
                {/* delete _input.checked; */}
                {/* delete _input.value; */}
                
                return (
                    <div style={props.wrapperStyle || {}} className={`form-field ${!props.compact && "field-margins"} radio ${props.wrapperClass}`}>
                        <span><_Radio 
                        {..._input} 
                        {..._props}
                        // onChange={(e) => {
                        //     console.log(e.target.checked)
                        // }}
                        ><span className="label">{props.label || ""}{props.children}</span></_Radio></span>
                        {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                    </div>
                )
            }}
        </Field>
    )
}

/*****
<FormField type="radio-button-field" name="label" data={[
    { title: "Home", _id: 'home' },
    { title: "Office", _id: 'office' },
    { title: "Other", _id: 'other' }
    ]} />
 */
const RadioButtonGroup = props => {
    const [val, setVal] = React.useState(null);

    let _props = { ...props };
    delete _props.type;
    delete _props.label;
    delete _props.data;
    delete _props.wrapperStyle;
    delete _props.wrapperClass;
    delete _props.compact;

    return(<>
        <Field name={props.name} validate={props.validate} type="radio">
            {({ input, meta }) => {
                let _input = { ...input }

                // meta.initial
                const onChange = (e) => {
                    setVal(e.target.value);
                    input.onChange(e)
                }

                return (<div style={props.wrapperStyle || {}} className={`form-field ${!props.compact && "field-margins"} input ${props.wrapperClass}`}>
                    {props.label && <span className="label">{props.label}</span>}
                    <div>
                        <_Radio.Group {..._input} {..._props} value={val || meta.initial} onChange={onChange}>
                            <Row>
                                {props.data.map((item, i) => (<Col key={i}>
                                    <_Radio.Button type="primary" value={item._id || item.title}>{item.title}</_Radio.Button>
                                </Col> ))}
                            </Row>
                        </_Radio.Group>
                    </div>
                </div>)
            }}
        </Field>
    </>)
}

export const CheckboxField = props => {

    let _props = { ...props };
    delete _props.name;
    delete _props.label;
    // delete _props.width;
    // delete _props.style;
    delete _props.type;
    delete _props.children;
    // delete _props.compact;
    // delete _props.validate;
    delete _props.wrapperStyle;
    delete _props.wrapperClass;

    return (
        <Field name={props.name} validate={props.validate} type="checkbox">
            {({ input, meta }) => { 
                let _input = { ...input}
                delete _input.checked;
                delete _input.value;
                
                return (
                    <div style={props.wrapperStyle || {}} className={`form-field ${!props.compact && "field-margins"} checkbox ${props.wrapperClass}`}>
                        <span><_Checkbox {..._input} {..._props}><span className="label">{props.label || ""}</span></_Checkbox></span>{props.children}
                        {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                    </div>
                )
            }}
        </Field>
    )
}



export const SelectFieldMultiple = props => {
    const propTypes = {
        name: PropTypes.string.isRequired,
        data: PropTypes.array.isRequired,
        addNull: PropTypes.bool,
        label: PropTypes.string,
    }

    // let _inputProps = { ...props.inputProps };
    // if (props.disabled) _inputProps = Object.assign(_inputProps, { disabled: props.disabled })

    const keyMap = props.keyMap ? props.keyMap.split("=>") : ['_id', 'title']

    return (
        <Field name={props.name} validate={props.validate}>
            {({ input, meta }) => {
                
                // const multiple = props.fieldProps && props.fieldProps.mode == "multiple";

                let value = (!input.value || input.value == null || input.value == "null") ? undefined : input.value;
                // if (input.value && input.value!=undefined && _.isString(input.value) && input.value.indexOf(",") > -1 && multiple) value = input.value.split(",");
                // else if (input.value && input.value != undefined ) value = String(input.value);

                let defaultVal = props.data ? props.data.find(o=>o.default===true) : undefined;
                defaultVal = (defaultVal && defaultVal._id) || undefined; 

                let list_data = props.data ? [...props.data] : [];
                if (props.addNull) list_data.unshift({ _id: null, title: " " });
                
                return (
                    // <div className={`form-field ${!props.compact && "field-margins"} select ${props.className}`}>
                    <div style={props.wrapperStyle || {}} className={`form-field ${!props.compact && "field-margins"} select ${props.wrapperClass}`}>
                        {props.label && <label>{props.label}</label>}
                        <_Select loading={props.loading}
                            {...input}
                            value={value}
                            defaultValue={defaultVal}
                            placeholder={props.placeholder}
                            style={{ width: props.width || '100%' }}
                        > 
                            {list_data.map((item, i) => {
                                if (!item) return null;
                                if (_.isString(item))
                                    return <_Select.Option key={String(item)}>{String(item)}</_Select.Option>
                                return <_Select.Option key={i} value={String(item[keyMap[0]])}>{String(item[keyMap[1]])}</_Select.Option>
                            })}
                        </_Select>
                        {/* <_Select mode="tags" style={{ width: '100%' }} placeholder="Tags Mode">
                            {list_data.map((item, i) => {
                                if (!item) return null;
                                if (_.isString(item))
                                    return <_Select.Option key={String(item)}>{String(item)}</_Select.Option>
                                return <_Select.Option key={i} value={String(item[keyMap[0]])}>{String(item[keyMap[1]])}</_Select.Option>
                            })}
                        </_Select> */}
                        {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                    </div>
                )
            }}
        </Field>
    )
}

export const SelectField = props => {
    const propTypes = {
        name: PropTypes.string.isRequired,
        data: PropTypes.array.isRequired,
        addNull: PropTypes.bool,
        label: PropTypes.string,
    }

    let _props = { ...props };
    // delete _props.inputProps;
    delete _props.keyMap;
    delete _props.data;
    delete _props.addNull;
    delete _props.label;
    delete _props.width;
    delete _props.compact;
    delete _props.validate;
    delete _props.wrapperStyle;
    delete _props.wrapperClass;
    delete _props.inputProps;
    // delete _props.onChange;

    // let _inputProps = { ...props.inputProps };
    // if (props.disabled===true) _inputProps = Object.assign(_inputProps, { disabled: true })

    const keyMap = props.keyMap ? props.keyMap.split("=>") : ['_id', 'title']

    return (
        <Field name={props.name} validate={props.validate}>
            {({ input, meta }) => {
                if (props.onChange){
                    _props.onChange = (e) => props.onChange(e, input.onChange);
                    }

                let value;
                if (input.value && _.isArray(input.value)) value = input.value;
                else if (input.value) value = String(input.value);

                let defaultVal = props.data ? props.data.find(o => (o.default===true)) : null;
                defaultVal = (defaultVal && defaultVal._id) || undefined;

                let list_data = props.data ? [...props.data] : [];
                if (props.addNull) list_data.unshift({ _id: null, title: " " });

                return (
                    <div style={props.wrapperStyle || {}} className={`form-field ${!props.compact && "field-margins"} select ${props.wrapperClass}`}>
                        {props.label && <label>{props.label}</label>}
                        <_Select
                            {...input}
                            value={value}
                            size={'large'}
                            style={{ width: props.width || '100%' }}
                            defaultValue={defaultVal}
                            {...props.inputProps}
                            {..._props}
                        >
                            {list_data.map((item, i) => {
                                if (!item) return null;
                                if (_.isString(item))
                                    return <_Select.Option key={String(item)}>{String(item)}</_Select.Option>
                                return <_Select.Option key={i} value={String(item[keyMap[0]])}>{String(item[keyMap[1]])}</_Select.Option>
                            })}
                        </_Select>
                        {meta.error && meta.touched && <div className="field-error">{meta.error}</div>}
                    </div>
                )
            }}
        </Field>
    )
}

// export const CheckboxField = props => { }

export const Select = props => {
    let _props = { ...props}
    const keyMap = _props.keyMap ? _props.keyMap.split("=>") : ['_id', 'title']

    if (props.width) _props.style = Object.assign({..._props.style}, { width: props.width || '100%'})

    var data = props.data;
    if (props.addNull && data[0]._id) data.unshift({ _id: "", title: "" });
    
    delete _props.keyMap;
    delete _props.data;
    delete _props.addNull;
    delete _props.width;
    delete _props.label;
    delete _props.compact;
    delete _props.validate;

    return (<div className="simple-field">
        {props.label && <Label>{props.label}</Label>}
        <_Select {..._props} >
            {data.map((item, i) => {
                if (!item) return null;
                // if (!item) return <_Select.Option key={i}></_Select.Option>
                
                if (_.isString(item))
                    return <_Select.Option key={String(item)}>{String(item)}</_Select.Option>
                return <_Select.Option key={i} value={String(item[keyMap[0]])}>{String(item[keyMap[1]])}</_Select.Option>
            })}
        </_Select>
        </div>
    )
}

export const Input = props => {
    let _props = { ...props }
    
    if (props.width) _props.style = Object.assign({..._props.style}, { width: props.width || '100%'})
    
    delete _props.addNull;
    delete _props.width;
    delete _props.type;
    delete _props.label;
    delete _props.compact;
    delete _props.validate;

    return (<div className="simple-field">
        {props.label && <Label>{props.label}</Label>}
        
        {/* {(props.type && props.type == 'number') && <_InputNumber {..._props} />} */}
        {props.type == 'number' && <_InputNumber {..._props} />}
        
        {props.type == 'textarea' && <_Input.TextArea {..._props} />}
        
        {(!props.type) && <_Input {..._props} />}
        {/* {(!props.type || props.type != 'number') && <_Input {..._props} />} */}
        
    </div>)
    
}

export const Checkbox = props => {
    let _props = { ...props };
    // delete _props.children;

    return (<_Checkbox {..._props} />)
}
