import React from 'react'
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { Divider, message, Alert } from 'antd';
// import axios from 'axios';
import { connect } from "react-redux";
// import { graphql, withApollo } from '@apollo/client/react/hoc';
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { __error } from 'Common/scripts/consoleHelper'
// import { mergeCarts } from 'Common/scripts/Functions';
import { Icon, Button, Loader, DevBlock } from 'Common/components'
import { FormComponent, FormField, rules, composeValidators } from 'Common/components/Form'
// import { loginUser, logoutUser } from '../redux/actions'
import { loginUser, logoutUser } from 'Store/grocer_storefront.actions'
import GetLoggedInUserData from '../containers/GetLoggedInUserData';
import PhoneVerification from '../containers/PhoneVerification'
// import { appVer } from 'configs';
import { processLoginRequest } from 'Common/scripts/Security';
// import LocalStorage from 'Common/scripts/LocalStorage';

const MERGE_CART = loader('src/graphqls/cart/mergeCart.graphql');


export const SignInForm = props => {
    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [doAutologin, setDoAutologin] = React.useState(false);
    // const [processing, set_processing] = React.useState(false);
    // const [loggedInUser, setLoggedInUser] = React.useState(false);

    React.useEffect(() => {
        // console.log("handelResponse: ", handelResponse);
        if (props.autoLoginInfo){
            setDoAutologin(props.autoLoginInfo)
            onSubmit(props.autoLoginInfo);
        }
        
        return () => {
            return false;
        };
    }, [props.autoLoginInfo])

    const onSubmit = async ({ username, pwd }) => {
        setErrorMessage(null);
        setLoading(true);
        // const login_resutls = await processLoginRequest({ username, pwd, cart: props.cart, client: props.client, onSuccessCallback: props.onSuccessCallback });
        const login_resutls = await processLoginRequest({ username, pwd, cart: props.cart, client: props.client });
        setLoading(false);
        if (login_resutls.error) return setErrorMessage(login_resutls.error.message)

        
        if (!login_resutls.token || !login_resutls?.user?._id) return setErrorMessage("Invalid login response!");
        
        // await LocalStorage.login(login_resutls);
        props.on_loginUser(login_resutls);
        // window.location.href = "/"

        // if (!props.onSuccessCallback) return setLoggedInUser({ token: login_resutls.token, user: login_resutls.user });
    }


    if (props.user && loading) return <GetLoggedInUserData onSuccess={() => setLoading(false)} />
    if (props.user && !loading) return <PhoneVerification onCancel={props.onCancel} />
    
    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign:"left", ...props.style }}>
            <div style={{ maxWidth: "350px", width:"100%" }}>
                <FormComponent onSubmit={onSubmit} id='ProdCatForm' loading={loading} hideDevBlock={true} style={{ width: "100%" }}>
                    <h3>Sign in</h3>
                    {errorMessage && <Alert showIcon type="error" message={errorMessage} description={null} /> }

                    <FormField type="text" name="username" wrapperStyle={{ margin: "15px 0" }} label="Email" validate={composeValidators(rules.required, rules.isEmail)} />
                    <FormField type="password" name="pwd" wrapperStyle={{ margin: "15px 0" }} label="Password" validate={composeValidators(rules.required, rules.minChar(4))} />

                    <p align="right">
                        <Link to="/user/forgot-password" onClick={props.onCancel} style={{ color:"#0073b1"}}>Forgot password?</Link>
                    </p>

                    <div style={{ textAlign: "right" }}><Button type="primary" htmlType="submit" block size="large">Sign in</Button></div>
                </FormComponent>
            </div>
        </div>
    )
}
SignInForm.propTypes = {
    autoLoginInfo: PropTypes.object,
    // onSuccessCallback: PropTypes.func.isRequired,
}


const WithApollo = compose(
    graphql(MERGE_CART, {
        props: ({ mutate }) => ({
            mergeCart: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),
)(SignInForm);

const mapStateToProps = state => {
    return ({
        user: state?.grocer_storefront?.user
    });
}
const mapDispatchToProps = (dispatch, ownProps) => ({
    on_loginUser: (payload) => dispatch(loginUser(payload)),
    logoutUser: (payload) => dispatch(logoutUser(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withApollo(WithApollo));

// export default SignInForm;