import React from 'react'
import PropTypes from 'prop-types';
import { Row, Col, Breadcrumb, message, Alert } from 'antd';
// import { graphql } from '@apollo/client/react/hoc';
import { graphql } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { ProductListScroller, ProductGalleryScroller, ProductList, RelatedCategoriesMenu } from 'Layout_v1';
import { Icon, Button, Loader, Pagination, DevBlock } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper'
import { defaultPageSize, paginationRangeArray } from 'configs'
import StoreInfo from './StoreInfo';

const LIST_DATA = loader('src/graphqls/product/client_productsQuery.graphql');


class ProdList extends React.Component {
    defaultFilter = this.props.searchFilter; // || { cat_slug: this.props.match.params.cat_slug };
    state = {
        pagination: {
            current: 1, pageSize: defaultPageSize,
            first: defaultPageSize, after: 0
        },
        filter: this.defaultFilter,
        sort: { title: 1 },
        busy: false, //this.props.loading
        loading: true,
        itemsLoaded: 0,
        client_productsQuery: null
    };

    componentDidMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    componentWillReceiveProps(nextProps) {
        const { subscribeToMore, client_productsQuery } = nextProps;
        var total = (client_productsQuery && client_productsQuery.edges) ? client_productsQuery.edges.length : 0;

        if (!nextProps.loading && this.state.loading) {
            this.setState({
                loading: false,
                itemsLoaded: this.state.itemsLoaded + total,
                client_productsQuery
            });
        }
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            ...this.state,
            busy: true,
            pagination: {
                ...this.state.pagination,
                pageSize: pagination.pageSize,
                current: pagination.current
            }
        })
        this.doLoadMore({ ...pagination });
    }

    onSortChange = sortValue => {
        this.setState({
            sort: JSON.parse(sortValue),
            busy: true,
            pagination: { ...this.state.pagination, current: 1 }
        });
        this.doLoadMore({
            sort: JSON.parse(sortValue),
            current: 1
        });
    }

    refresh = (args = {}) => {
        const { pagination, filter, sort } = this.state;

        let first = args.first || pagination.first;
        let after = args.after || pagination.after + pagination.first;
        let _filter = args.filter || filter; // JSON.stringify(args.filter || filter);

        this.setState({
            pagination: { first, after },
            busy: true,
        })

        this.props.loadMoreRows(first, after, _filter, undefined, true).then(r => {
            this.setState({ busy: false })
        })
    }

    doLoadMore = (args = {}) => {
        const { pagination, filter, sort, itemsLoaded } = this.state;

        let first = args.first || pagination.first;
        let after = args.after || pagination.after + pagination.first;
        let _filter = args.filter || filter; //JSON.stringify(args.filter || filter);

        this.setState({
            pagination: { first, after },
            busy: true,
        })

        this.props.loadMoreRows(first, after, _filter).then(({ data }) => {
            // console.log("this.props.loadMoreRows: ", data);

            const { client_productsQuery } = data;
            var total = (client_productsQuery && client_productsQuery.edges) ? client_productsQuery.edges.length : 0;
            this.setState({
                busy: false,
                itemsLoaded: (itemsLoaded + total),
                client_productsQuery: {
                    totalCount: client_productsQuery.totalCount,
                    edges: [...this.state.client_productsQuery.edges, ...client_productsQuery.edges,],
                    pageInfo: client_productsQuery.pageInfo,
                    __typename: 'ProductQuery'
                }
            })
        })

    }

    render() {
        const { pagination, busy, loading, itemsLoaded, client_productsQuery } = this.state;
        const pageConfigs = {}; // { topBanner: `${process.env.REACT_APP_TEMPLATE_ASSETS}/find-it-cheaper-banner.png` }

        if (client_productsQuery?.error) return <Alert message={client_productsQuery.error.message} type="error" showIcon />

        console.log("client_productsQuery: ", client_productsQuery)

        return (
            <div className="page-columns-">
                <div className="page-col2">
                    {pageConfigs.topBanner && <img style={{ width: "100%", marginBottom: "20px" }} src={pageConfigs.topBanner} />}
                    {this.props.storeInfo && <StoreInfo catInfo={this.props.catInfo} storeInfo={this.props.storeInfo} />}
                    <div style={{ fontSize:"32px" }}>{this.props?.catInfo?.title}</div>

                    {/* if its a search query */}
                    {this.props.searchFilter && <>
                        <Row style={{ marginBottom: "20px" }}>
                            <Col flex="auto">
                                <h6><div>
                                    {this.props.searchFilter.search.keywords}
                                    <span style={{ color: "#999", fontSize: "0.8em", marginLeft: "10px" }}>
                                        ({client_productsQuery && client_productsQuery.totalCount || 0} search results)
                                    </span>
                                </div></h6>
                            </Col>
                            <Col><div style={{ display: "flex", justifyContent: "flex-end" }}></div></Col>
                        </Row>
                    </>}

                    {/* Products Listing */}
                    <ProductList
                        loading={loading}
                        data={(client_productsQuery && client_productsQuery.edges) || []} total={(client_productsQuery && client_productsQuery.totalCount) || 0} />

                    {busy && <div style={{ position: "relative", margin: "20px 0" }}><Loader loading={busy} /></div>}

                    {(client_productsQuery && client_productsQuery.totalCount > itemsLoaded) &&
                        <div style={{ textAlign: "center", padding: "20px" }}>
                            <Button loading={loading || busy} onClick={() => this.doLoadMore()} block theme="gray" size="large"><Icon style={{ margin: "3px 5px 0 0" }} icon={"angle-down"} /> More <Icon style={{ margin: "3px 0 0 5px" }} icon={"angle-down"} /></Button>
                        </div>
                    }

                </div>

            </div>
        )
    }

}
ProdList.propTypes = {
    // toggleDrawer: PropTypes.func.isRequired
    // prop: PropTypes.type.isRequired
    // onEditRecord: PropTypes.func.isRequired
}


const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            var filter = {};
            // if (props.searchFilter) Object.assign(filter, { ...props.searchFilter })
            if (props.filter) Object.assign(filter, { ...props.filter })

            let vars = { first: defaultPageSize, after: 0, filter: JSON.stringify(filter) };

            return {
                variables: vars, //{ first: defaultPageSize, after: 0, filter: JSON.stringify({ cat_slug: props.match.params.slug }) },
                fetchPolicy: "no-cache", //no-cache, cache-first, network-only, cache-only, cache-and-network
                notifyOnNetworkStatusChange: true,
            };

        },
        props: (props) => {
            const { ownProps, data } = props;
            const { loading, client_productsQuery, error, fetchMore, subscribeToMore } = data;

            if (error) console.log(__error("error"), error);

            const loadMoreRows = (first, after, filter, others, reset) => {
                let vars = { first, after, others, 
                    filter: JSON.stringify({
                        ...filter,
                        ...ownProps?.filter,
                    })
                };

                return fetchMore({
                    variables: vars,
                    fetchPolicy: "no-cache", //"network-only",
                    updateQuery: (prev, { fetchMoreResult }) => {

                        return {
                            client_productsQuery: {
                                totalCount: fetchMoreResult.client_productsQuery.totalCount,
                                // edges: [...previousResult.branchesQuery.edges, ...newEdges],
                                edges: [...prev.client_productsQuery.edges, ...fetchMoreResult.client_productsQuery.edges,],
                                pageInfo: fetchMoreResult.client_productsQuery.pageInfo,
                                __typename: 'ProductQuery'
                            }
                        }

                    }
                })
            }

            return { loading, client_productsQuery, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

)(ProdList);

export default WithApollo;
