import React, { Component, useState } from 'react'
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { __error } from 'Common/scripts/consoleHelper';
import { Alert, Input, message, Result, Space } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { Button, DevBlock, Loader } from 'Common/components';
import { loader } from 'graphql.macro';
import { withApollo } from 'react-apollo';
import axios from 'axios';
import qs from 'qs'

const ADD_TEMP_TO_CART = loader('src/graphqls/cart/addTransactionToCart.graphql');
const INIT_PAYMENT = loader('src/graphqls/payment_methods/initPaymentProcess.graphql');
const VERIFY_PAYMENT = loader('src/graphqls/payment_methods/spenn_verifyStatus.graphql');

const CollectPhoneNumber = ({ updatePhone }) => {
    const [phone, setPhone] = useState(null);

    return (<>
        <Space direction='vertical' style={{ width:"100%" }}>
            <h1>phone number</h1>
            <Input type='text' value={phone} onChange={({ target }) => setPhone(target.value)} placeholder='+XXX XXX XXX XXX' />
            <div align="right"><Button disabled={!phone} onClick={() => updatePhone(phone)}>Next</Button></div>
        </Space>
    </>)
}

class SpennPayment extends Component {
    state = {
        busy: false, 
        processingPayment: false, 
        verifying: false, 
        transactionData: null, 
        verificationData: null, 
        error: null, fatelError: null, 
        debugData: null,
        initData: null,
        phone: null,
    }

    componentDidMount() {
        // if (this.props.orderData) this.createTransaction();
    }

    async createTransaction({ phone }) {
        const { orderData, initialValues } = this.props;

        this.setState({ busy: true, processingPayment: false, fatelError:false, error: false });

        let input = {
            service_key: initialValues.ref_key,
            shipping_address: orderData.shipping_address,
            discount_voucher_code: orderData.discount_voucher_code,
            note: orderData.note,
            delivery_slot: orderData.delivery_slot,
            discount_voucher_code: orderData.discount_voucher_code && String(orderData.discount_voucher_code).trim() || undefined,
            phone,
        }

        const results = await this.props.client.mutate({ mutation: INIT_PAYMENT, variables: { input } }).then(({ data }) => (data.initPaymentProcess))
            .catch(err => {
                console.log(__error("Query Error: "), err)
                return { error: { message: "Query Error" } }
            });
        if (results.error) {
            this.setState({ busy: false, processingPayment: false, fatelError: results.error.message });
            return;
        }


        this.setState({ busy: true, processingPayment: true, transactionData: results });

    }

    async addTransactionToCart(input) {
        // this.props.cart
        const results = await this.props.client.mutate({ mutation: ADD_TEMP_TO_CART, variables: { input } }).then(({ data }) => (data.addTransactionToCart))
            .catch(err => {
                console.log(__error("Query Error: "), err)
                return { error: { message: "Query Error" } }
            });

        return results;
    }

    async verifyPayment(){
        const { busy, processingPayment, error, fatelError, initData, transactionData } = this.state;
        this.setState({ error: null, fatelError: null, verifying: true })

        let json_response = JSON.parse(transactionData.json_response); // { requestId, externalReference, status } json_response.response

        let input = {
            external_reference: json_response.response.externalReference, 
            requestId: json_response.response.requestId
        }

        const results = await this.props.client.mutate({ mutation: VERIFY_PAYMENT, variables: input })
            .then(({ data }) => (data.spenn_verifyStatus))
            .catch(err => {
                console.log(__error("Query Error: "), err)
                return { error: { message: "Query Error" } }
            });
        console.log("results: ", results)

        if (!results) return this.setState({ error: "Invalid response!", verifying:false })

        this.setState({ verificationData: results, error: (results && results?.error?.message) || null, verifying: false })
    }

    parseVerificationData(verificationData){
        const { verifying } = this.state;
        // return <p>parseVerificationData</p>

        let details = verificationData?.error?.details || verificationData?.success?.details;
        // if (!verificationData || details == 'pending') {
        //     return (<>
        //         <Loader loading={true} center />
        //         <div align="center">
        //             <div align="center" style={{ fontSize: "20px", fontWeight: "bold" }}>Payment Pending</div>
        //             <div align="center" style={{ padding: "10px 0" }}>
        //                 Please cehck your Mobile for Payment Confirmation on SPENN Smart App
        //                 <br /><b>Then</b><br />
        //                 Click on Verify Payment button below after completing SPENN payment process
        //             </div>
        //             <Button onClick={() => this.verifyPayment()}>Verify Payment</Button>
        //         </div>
        //     </>)           
        // }
        if (verificationData && details == 'approved') {
            window.location.href = `/checkout/success?message=${verificationData.success.message}`;

            return (<>
                <Loader loading={true} center />
                <Result
                    icon={<SmileOutlined />}
                    title={`Great, ${verificationData.success.message}`}
                    // extra={<Button type="primary">Next</Button>}
                />
                {/* <div align="center" style={{ fontSize: "20px", fontWeight: "bold" }}>{verificationData.success.message}</div> */}
            </>)
        }
        if (verificationData && (details == 'rejected' || details == 'cancelled')) {
            window.location.href = `/checkout/no-success?message=${verificationData.error.message}`;
            return (<>
                <Loader loading={true} center />
                <div align="center" style={{ fontSize: "20px", fontWeight: "bold" }}>{verificationData.error.message}</div>
            </>)
        }
        if (verificationData && details == 'invalid') {
            window.location.href = `/checkout/no-success?message=${verificationData.error.message}`;
            return (<>
                <Loader loading={true} center />
                <div align="center" style={{ fontSize: "20px", fontWeight: "bold" }}>{verificationData.error.message}</div>
            </>)
        }

        return (<>
            <Loader loading={true} center />
            <div align="center">
                <div align="center" style={{ fontSize: "20px", fontWeight: "bold" }}>Payment Pending</div>
                <div align="center" style={{ padding: "10px 0" }}>
                    Please cehck your Mobile for Payment Confirmation on SPENN Smart App
                    <br /><b>Then</b><br />
                    Click on Verify Payment button below after completing SPENN payment process
                </div>
                <Button loading={verifying} onClick={() => this.verifyPayment()}>Verify Payment</Button>
            </div>
        </>)           

    }


    render() {
        if (!this?.props?.orderData) return null;
        const { busy, processingPayment, error, fatelError, initData, transactionData, verificationData, phone } = this.state;


        if (!phone) return <CollectPhoneNumber updatePhone={v => {
            this.setState({ phone: v })
            this.createTransaction({ phone:v });
        }} />


        let json_response;
        if (transactionData && transactionData.json_response) json_response = JSON.parse(transactionData.json_response)

        if (processingPayment) return (<div className='center'>
            {error && <Alert type="error" message={error} showIcon />}

            {this.parseVerificationData(verificationData)}
            {/* <Loader loading={true} center />
            <div align="center">
                <p>Please cehck your Mobile for Payment Confirmation on SPENN Smart App</p>
                <p>Click on Verify Payment button below after completing SPENN payment process</p>
                <Button onClick={() => this.verifyPayment()}>Verify Payment</Button>
            </div> */}

            <DevBlock obj={json_response} />
        </div>)

        if (error) return <Alert type="error" message={error} showIcon />
        if (fatelError) return <Alert type="error" message={fatelError} showIcon />

        return (<Loader loading={busy}>
            <p>Proceeding with Kazang Mobile Payment . . .</p>
            <DevBlock obj={transactionData} />
        </Loader>)
    }
}
SpennPayment.propTypes = {
    orderData: PropTypes.object.isRequired,
    initialValues: PropTypes.object.isRequired,
}


const mapStateToProps = state => {
    return ({
        settings: state.grocer_storefront,
        order: state.order,
        cart: state.cart,
        user: state?.grocer_storefront?.user,
    });
}
const WithApollo = connect(mapStateToProps)(SpennPayment);
export default withApollo(WithApollo)
