import React from 'react'
import { Icon, Button, Avatar } from 'Common/components'
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';


export const ProductGalleryItem = props => {
    const thumb = props.thumb ? (props.thumb.indexOf('http') > -1 ? props.thumb : `${process.env.REACT_APP_DATA_URL}/${props.thumb}`) : undefined;

    return (
        <div className={`product-gallery-item-wrapper ${props.className}`}>
            <div className="product-gallery-item">
                <div className="li-img" onClick={() => props.onClick(props)}>
                    <Avatar type="product" style={{ zIndex: 0 }} shape="square" size={65} src={thumb} />
                </div>
            </div>
        </div>
    )
}

ProductGalleryItem.propTypes = {
    onClick: PropTypes.func.isRequired,
    // labelA: PropTypes.oneOfType([
    //             PropTypes.string,
    //             PropTypes.object
    //         ]).isRequired,
}
export default ProductGalleryItem;
