import React, { Component } from 'react'
import { DevBlock } from 'Common/components'

export default class Kz_canceled extends Component {
    render() {
        return (<>
            <DevBlock obj={this.props} />
        </>)
    }
}
