import React, { Component, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Spin, message } from 'antd';
import { loader } from 'graphql.macro';
import { graphql, withApollo } from 'react-apollo';
import compose from 'lodash.flowright';
import debounce from 'lodash/debounce';
import { FormField } from './Form'
import { __error } from 'Common/scripts/consoleHelper'
// import { Loader, IconButton, Drawer, Button, DevBlock, Heading } from 'Common/components'
import _ from 'lodash'

const LOCATIONS = loader('src/graphqls/locations/locations.graphql');


const LocationSelectionComp = props => {
    const [state, setState] = React.useState({ filter:"", loading: false, list: props.defaultValues || [] });

    useEffect(() => {
        if (!props.preload) return;
        _fetchData(props.preload);

        // return () => console.log;
    }, [props.preload])

    const _fetchData = async (value) => {
        if (value!==true && (!value || value.length < 1)) return;
    
        var filter = { ...props.filter };
        if (!(value === true || value===false)){
            if (_.isString(value)) Object.assign(filter, { search: { keywords: value } })
            // else if (value) filter = JSON.stringify({ ...props.preload });
        }
        // avoid re-fetching
        if (state.filter == JSON.stringify(filter)) return console.log("Already fetched");

        setState({ list: [], loading: true, filter: JSON.stringify(filter) });

        const results = await props.client.query({ query: LOCATIONS, variables: { filter: JSON.stringify(filter) } }).then(e => (e.data.locations)).catch(err => {
            console.log(__error("API Call ERROR: locations : "), err);
            return { error: { message:"Request ERROR" } }
        })

        setState({ 
            ...state, 
            loading: false, 
            list: results.error ? [] : results.map(o => ({ _id: o.code, title: o.title, raw: o }))
        })

        if (results.error){
            message.error(results.error.message);
            return;
        }

        return true;
    }
    const fetchData = debounce(_fetchData, 800);

    const changeHandler = (val, callback) => {
        if (props.onChange) props.onChange(state.list.find(o=>o._id==val));
        callback(val);
    }

    return (<>
        <FormField validate={props.validate}
            // type={props.multi ? 'select-multiple' : "select" }
            type={props.mode == 'multiple' ? 'select-multiple' : 'select'}
            name={props.name}
            label={props.label}
            // onChange={props.onChange}
            onChange={changeHandler}
            data={state.list}
            placeholder={props.placeholder || "Search Locations"}
            addNull={false}
            compact={props.compact}
            size={props.size}
            style={{...props.style, width:"100%"}}
            allowClear={props.allowClear}
            // keyMap="value=>text"
            // defaultValue={props.defaultValue && props.defaultValue._id}
            inputProps={{
                onSelect: props.onSelect || undefined, // console.log,
                onDeselect: props.onDeselect || undefined, // console.log,
                loading:state.loading,
                mode: props.mode || undefined, // tags, multiple
                showSearch: true,
                defaultActiveFirstOption: false,
                showArrow: true,
                notFoundContent: state.loading ? <Spin size="small" /> : null,
                filterOption: false,
                onSearch: fetchData,
                // onChange:handelChange,
            }}
        />
    </>)
}
LocationSelectionComp.propTypes = {
    mode: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValues: PropTypes.array,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func,
    filter: PropTypes.object,
}

export const LocationSelection = withApollo(LocationSelectionComp);
export default LocationSelection;

