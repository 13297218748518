import React, { Component, Image } from 'react'
// import { graphql } from '@apollo/client/react/hoc';
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { Row, Col, Modal, message, Divider, Steps } from 'antd';
import { connect } from "react-redux";
import { Icon, Button, IconButton, Heading, Loader, DevBlock } from 'Common/components'
// import { FormComponent } from 'Common/components/Form'
import { __error } from 'Common/scripts/consoleHelper'
import LocationForm from './LocationForm';

const RECORD = loader('src/graphqls/user/userAddress.graphql');
const ADD_RECORD = loader('src/graphqls/user/addUserAddress.graphql');
const UPDATE_RECORD = loader('src/graphqls/user/editUserAddress.graphql');

class AddressForm extends Component {
    constructor(props){
        super(props);
        this.state = { loading: this.props.loading }
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit = async (data) => {
        console.log("onSubmit: ", data);
        // const { userAddress } = this.props;

        var results;

        if (data._id) {
            results = await this.props.editUserAddress(data)
                .then(r => (r.data.editUserAddress))
                .catch(err => {
                    console.log(__error("ERROR"), err)
                    return { error: { message:"Unable to complete request at the moment" } }
                })
        }
        else {
            results = await this.props.addUserAddress(data)
                .then(r => (r.data.addUserAddress))
                .catch(err => {
                    console.log(__error("ERROR"), err)
                    return { error: { message: "Unable to complete request at the moment" } }
                })
        }

        if (results.error){
            message.error(results.error.message);
            return false;
        }

        this.props.onSuccess(results);
        return true;
    }


    render(){
        // const { busy, options, map, locationConfirmed, fields } = this.state;
        const { loading, userAddress } = this.props;
        if(loading) return <Loader loading={true} />

        return (<div className="h-center">
            <LocationForm buttonLabel="Save" onSubmit={this.onSubmit} userAddress={userAddress} />
        </div>)

    }


}

const mapStateToAvatarPopProps = state => {
    return ({ 
        user: state?.grocer_storefront?.user,
    });
}
export const WithRedux = connect(mapStateToAvatarPopProps)(AddressForm);

const WithApollo = compose(

    graphql(ADD_RECORD, {
        props: ({ mutate }) => ({
            addUserAddress: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),

    graphql(UPDATE_RECORD, {
        props: ({ mutate }) => ({
            editUserAddress: (args) => mutate({
                variables: { input: { ...args } }
            }),
        })
    }),

)(WithRedux);

const EditWrapper = compose(
    graphql(RECORD, {
        options: ({ fields }) => {
            return {
                variables: { id: fields._id },
                fetchPolicy: "no-cache",
            };
        },
        props: ({ ownProps, data }) => {
            const { loading, userAddress, error } = data;
            if (error) console.log(__error("error"), error);

            return { loading, userAddress, queryErrors: error, }
        },
    }),
)(WithApollo);

const Wrapper = props => (props.fields && props.fields._id > 0) ? <EditWrapper {...props} /> : <WithApollo {...props} />
export default Wrapper;

// export default WithApollo;
