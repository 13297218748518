import React, { Component } from 'react'
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { __error } from 'Common/scripts/consoleHelper'
import { Table, Button } from 'Common/components';
import { utcToDate } from 'Common/scripts/Functions'
import { defaultPageSize } from 'configs'
import { E404 } from "Layout_v1";
import { Alert, Menu } from 'antd';
import { topMenu, ROOT, MODULE } from '../constants';

const LIST_DATA = loader('src/graphqls/order/myOrdersQuery.graphql');

export class OrdersList extends Component {
    state = {
        pagination: { current: 1, pageSize: defaultPageSize, },
        busy: false,
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.loading && !nextProps.loading) {
            this.setState({
                pagination: {
                    ...this.state.pagination,
                    total: nextProps?.myOrdersQuery?.totalCount || 0
                }
            })
        }

    }

    columns = [
        { title: 'Order Serial', dataIndex: 'serial', render: (text, row) => (<Link to={`${ROOT}/order/${text}`}>{text}</Link>), width:150 },
        { title: 'Store', dataIndex: 'store', render: (text, row) => {
            return <div style={{ minWidth: "100px" }}><Link to={`/store/${row.store.slug}`}>{row.store.title}</Link></div>
        }},
        { title: 'Total Items', dataIndex: 'items', align: "center", render: (text, row) => (row.items.length), width:'70px' },
        { title: 'Date', dataIndex: 'created_at', align: "center", render: (text, row) => (utcToDate(text).format("Do MMM YYYY ~ hh:mm A")), width:'170px' },
        { title: 'Total', dataIndex: 'invoice.total', align: "center", width: '100px', render: (__, rec) => {
            return <>{Number(rec?.invoice?.total).toFixed(2)}</>;
        } },
        { title: 'Grand Total', dataIndex: 'invoice.grand_total', align: "center", width: '100px', render: (__, rec) => {
            // console.log("rec.invoice: ", rec.invoice)
            return <>{Number(rec?.invoice?.grand_total).toFixed(2)}</>;
        } },
        { title: 'Status', dataIndex: 'status', align: "center", width: '100px' },
        { title: ' ', dataIndex: 'actions', align: "center", width: '100px', render: (text, row) => {
            return (<><Button><Link to={`${ROOT}/re-order/${row.serial}`}>Re-Order</Link></Button></>)
        }},
    ];

    handleTableChange = (pagination, filters, sorter) => {
        this.setState({
            ...this.state,
            busy: true,
            pagination: {
                ...this.state.pagination,
                pageSize: pagination.pageSize,
                current: pagination.current
            }
        })
        this.props.loadMoreRows(
            pagination.pageSize,
            pagination.pageSize * (pagination.current - 1),
            JSON.stringify({ "customer._id": this.props.user._id }),
        ).then(r => {
            this.setState({ busy: false })
            return r;
        })
    };


    render() {
        const { loading, myOrdersQuery, queryErrors } = this.props;
        const { pagination, busy } = this.state;

        return (<>
            <Menu mode="horizontal" style={{ marginBottom: "20px" }}>
                {topMenu.map((item, i) => (<Menu.Item key={i}><Link to={item.to}>{item.label}</Link></Menu.Item>))}
            </Menu>


            <h4>Orders ({myOrdersQuery && myOrdersQuery.totalCount || 0})</h4>

            {(myOrdersQuery && myOrdersQuery.error) && <Alert message={myOrdersQuery.error.message} type="error" showIcon />}

            <div style={{ overflowX:"auto" }}>
                <Table loading={loading || busy}
                    columns={this.columns}
                    dataSource={myOrdersQuery ? myOrdersQuery.edges : null}
                    rowKey={record => (record._id)}
                    pagination={pagination}
                    onChange={this.handleTableChange}
                />
            </div>

        </>)
    }
}



const WithApollo = compose(

    graphql(LIST_DATA, {
        options: props => {
            return { 
                variables: { 
                    first: defaultPageSize, after: 0,
                    others: JSON.stringify({ sort:{ _id:-1 }})
                },
                // fetchPolicy: "no-cache", //no-cache, cache-first, network-only, cache-only, cache-and-network
                notifyOnNetworkStatusChange: true,
            };
            // return { variables: { first: defaultPageSize, after: 0, filter: JSON.stringify({ "customer._id": props.user._id || 0 }) } };
        },
        props: ({ ownProps, data }) => {
            const { loading, myOrdersQuery, error, fetchMore, subscribeToMore } = data;

            if (error) console.log(__error("error"), error);

            const loadMoreRows = (count, startFrom, filter, others) => {

                let vars = { first: count, after: startFrom, filter: filter, others: others };

                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    const totalCount = fetchMoreResult.myOrdersQuery.totalCount
                    const newEdges = fetchMoreResult.myOrdersQuery.edges
                    const pageInfo = fetchMoreResult.myOrdersQuery.pageInfo
                    return {
                        // By returning `cursor` here, we update the `fetchMore` function to the new cursor.
                        myOrdersQuery: {
                            totalCount,
                            // edges: [...previousResult.branchesQuery.edges, ...newEdges],
                            edges: newEdges,
                            pageInfo,
                            __typename: 'OrderQuery'
                        }
                    }
                }

                return fetchMore({ variables: vars, updateQuery: updateQuery });
            }

            return { loading, myOrdersQuery, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

)(OrdersList);


// const ModuleCheck = props => {
//     if(!props.user || !props.user._id) return <Redirect to="/" />
//     return <WithApollo {...props} />
//     // return withApollo(WithApollo)
// }

const ModuleCheck = (props) => {
    if (!props.user || !props.user._id) return <E404 />
    return <WithApollo {...props} />
}
const mapStateToAvatarPopProps = state => {
    return ({ 
        user: state?.grocer_storefront?.user,
    });
}
const WithRedux = connect(mapStateToAvatarPopProps)(ModuleCheck);
export default WithRedux;


// const mapStateToAvatarPopProps = state => {
//     return ({ user: state?.grocer_storefront?.user, });
// }
// export default connect(mapStateToAvatarPopProps)(ModuleCheck);




// export default (props) => {
//     if(!props.user || !props.user._id){
//         return <p>not logged in</p>
//     }
//     return withApollo(WithRedux(WithApollo));
// } 

// export default withApollo(connect(mapStateToAvatarPopProps)(WithApollo));
