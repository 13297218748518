import React, { Component } from 'react'
import { Row, Col, Modal, message, Menu, Space } from 'antd';
import { Link } from 'react-router-dom';
import { Route, Switch, Redirect } from 'react-router-dom';
import { E404, LayoutWrapper } from "Layout_v1";
import { connect } from "react-redux";
import Profile from '../components/user-profile';
import OrderAddress from '../components/user-address';
import UserPassword from '../components/user-password';
import AccountDeletionRequestForm from '../components/AccountDeletionRequestForm';
import VerifyAccountDeletionRequest from '../components/VerifyAccountDeletionRequest';
import { topMenu, ROOT, MODULE } from '../constants';

import '../styles.scss'


const ProfilePage = props => {
    const { user } = props;
    if (!user || !user._id) return <Redirect to="/" />

    return (<div className="profile-page">
        <Menu mode="horizontal" style={{ marginBottom: "20px" }}>
            {topMenu.map((item, i) => (<Menu.Item key={i}><Link to={item.to}>{item.label}</Link></Menu.Item>))}
        </Menu>

        <Switch>
            <Route exact={true} path={`${ROOT}/profile`} render={() => (<Profile user_id={user._id} />)} />
            <Route exact={true} path={`${ROOT}/profile/addresses`} render={() => (<OrderAddress user_id={user._id} />)} />
            <Route exact={true} path={`${ROOT}/profile/password`} render={() => (<UserPassword user_id={user._id} />)} />
            <Route exact={true} path={`${ROOT}/profile/deletion_request`} render={() => (<AccountDeletionRequestForm user_id={user._id} />)} />
            <Route exact={true} path={`${ROOT}/profile/verify_deletion_request`} render={() => (<VerifyAccountDeletionRequest user_id={user._id} />)} />
            <Route component={E404} />
        </Switch>
    </div>)
}



const ModuleCheck = (props) => {
    if (!props.user || !props.user._id) return <E404 />
    return <ProfilePage {...props} />
}
const mapStateToAvatarPopProps = state => {
    return ({ 
        user: state?.grocer_storefront?.user,
    });
}
const WithRedux = connect(mapStateToAvatarPopProps)(ModuleCheck);
export default WithRedux;

