import React from 'react';
import { Button as _Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import Icon from './Icon';


// https://ant.design/components/button/

/***
 * disabled={boolean}
 * ghost={boolean} // transparent background
 * href={string} // redirect url of link button
 * target={string} // same as target attribute of a, works when href is specified
 * htmlType={string}
 * icon={ReactNode}
 * loading={boolean}
 * shape={circle | round}
 * size={large | middle | small}
 * type={primary | ghost | dashed | link}
 * block={boolean}
 * danger={boolean}
 */

// export const Button = props => <_Button {...props} type={props.type || "primary"} />;
/*****
 * React.forwardRef used to fix refs error
 * ERROR: Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()
 */
export const Button = React.forwardRef((props, ref) => { 
    let _props = { 
        shape:"round",
        theme:"green",
        type:'primary',
        alt:"Button",
        ...props
    };
    delete _props.theme;
    delete _props.className;
    delete _props.iconRight;
    delete _props.iconLeft;

    let className = props.className || "";
    className += " button ";
    if (props.theme) className += ` ${props.theme} `;

    if(props.tip) return <Tooltip title={props.tip}><_Button ref={ref} {..._props} className={className} /></Tooltip>;

    return <_Button ref={ref} {..._props} className={className}>
        {props.iconLeft && <span style={{marginRight:"5px"}}>{props.iconLeft}</span>}
        {props.children}
        {props.iconRight && <span style={{ marginLeft: "5px" }}>{props.iconRight}</span>}
    </_Button>;
})
export default Button;

export const ToggleButton = props => {
    const propTypes = {
        labelA: PropTypes.any.isRequired,
        labelB: PropTypes.any,
        toggle: PropTypes.bool,
        // icon: PropTypes.oneOfType([
        //             PropTypes.string,
        //             PropTypes.object
        //         ]),
    }

    let _props = { ...props };
    _props = Object.assign(_props)
    delete _props.toggle;
    delete _props.labelA;
    delete _props.labelB;
    // <Button type="primary" shape="circle" icon={<SearchOutlined />} />
    return <Button {..._props} >{!props.toggle ? props.labelA : (props.labelB || props.labelA)}</Button>

}


// export const IconButton = props => {
export const IconButton = React.forwardRef((props, ref) => {
    let _props = { ...props };
    delete _props.icon;
    delete _props.style;

    return <Button ref={ref} 
        style={{ margin: '3px', ...props.style }} 
        type={props.type || "default"} 
        shape='circle' 
        size={"small"} 
        {..._props}
        ><Icon style={{ fontSize: "0.8em" }} icon={props.icon} /></Button>
});
