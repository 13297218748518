import React, { Component } from 'react'
import { graphql, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import compose from 'lodash.flowright';
import { message, Row, Col, Popconfirm, Modal } from 'antd';
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { Icon, Button, IconButton, Heading, Loader, DevBlock } from 'Common/components'
import { __error } from 'Common/scripts/consoleHelper'
import SubscriptionHandler from 'Common/scripts/SubscriptionHandler';
import AddressForm from './AddressForm'


const GET_RECORD = loader('src/graphqls/user/userAddresses.graphql');
const QUERY_SUBSCRIPTION = loader('src/graphqls/user/subscriptionAddress.graphql');
const DELETE_RECORD = loader('src/graphqls/user/deleteUserAddress.graphql');
const SET_DEFAULT = loader('src/graphqls/user/setDefaultAddress.graphql');

const RenderAddress = props => {
    const [busy, setBusy] = React.useState(false);

    const makeDefault = args => {
        setBusy(true);

        props.setDefaultAddress(props.fields._id, props.fields.user_id)
            .then(r => {
                setBusy(false);
                if (r.data.setDefaultAddress.error) message.error(r.data.setDefaultAddress.error.message)
                props.onRefreshList(r.data.setDefaultAddress);
            })
            .catch(err => {
                setBusy(false)
                console.log(__error(" "), err);
                message.error("Query error")
            })
    }

    const isDefault = props.fields.is_default && props.fields.is_default > 0;

    return (<div className="user-address-display">
        <Row>
            <Col flex="auto">
                <div><b>{props.fields.label}</b></div>
            </Col>
            <Col align="right"><div>
                <IconButton onClick={makeDefault} icon="check" size="small" theme="gray" disabled={isDefault} tip={isDefault ? "Default address" : "Make this default"} type={isDefault ? 'primary' : 'default'} />
                <IconButton onClick={props.onClick} icon="pen" size="small" />
                <Popconfirm title="Sure to delete?" onConfirm={() => props.onDeletePress(props.fields)}>
                    <IconButton icon="trash-alt" />
                </Popconfirm>
                {props.fields.verified == 'yes' && <div style={{ color:'green' }}><Icon icon={'check'} style={{ marginRight: '2px' }} />Verified</div>}
            </div></Col>
        </Row>
        <div>{props.fields.full_address}, {props.fields.city}</div>
        {props.fields.delivery_instructions && <div style={{ marginTop: "20px" }}><i><pre>{props.fields.delivery_instructions}</pre></i></div>}
    </div>)
}

export class UserAddressesPage extends Component {
    state = { showAddForm: false, showAddressForm: false, selectedAddress:null };
    componentWillReceiveProps(nextProps) {
        const { subscribeToMore } = nextProps;
        if (!this.subscription) {
            this.subscription = new SubscriptionHandler({
                _subscribeToMore: subscribeToMore,
                _document: QUERY_SUBSCRIPTION,
                _variables: {
                    id: this.props.user_id
                },
                _subscriptionName: "addressesUpdated",
                _subscriptionType: "simple-array",
                _queryName: "userAddresses",
                _typename: "Address",
                debug: true
            });
        }

    }

    onDeletePress = (rec) => {
        this.props.deleteUserAddress(rec._id).then(r => {
            message.success("Removed");
        })
    }

    onRefreshList(listItems) {
        if (!listItems.error) this.props.loadMoreRows(this.props.user_id)
    }

    toggleAddressForm = (selectedAddress) => {
        this.setState({ showAddressForm: !this.state.showAddressForm, selectedAddress: selectedAddress || null })
    }

    onAddressAdded = args => {
        this.toggleAddressForm();
    }

    render(){
        const { loading, userAddresses, user } = this.props;
        const { showAddForm, showAddressForm } = this.state;

        if (loading) return <Loader loading={true} />
        if (!user || !user._id) return <Redirect to="/" />

        return (<div className="h-center" style={{ marginBottom: "50px" }}>
            <div style={{width:"100%", maxWidth:"600px"}}>
                <h3><span />Your Addresses<span /></h3>
                <div>
                    <Row gutter={[0, 12]}>
                        <Col flex="auto"> </Col>
                        <Col align="right"><Button size="large" type="primary" onClick={() => this.toggleAddressForm()}>Add Another</Button></Col>
                    </Row>
                    
                    {userAddresses && userAddresses.map((item, i) => (<RenderAddress
                        onClick={() => this.toggleAddressForm(item)}
                        onRefreshList={(e)=>this.onRefreshList(e)}
                        setDefaultAddress={this.props.setDefaultAddress}
                        onDeletePress={(rec) => this.onDeletePress(rec)}
                        fields={item} key={i} />))}
                    {/* {userAddresses.map((item, i)=> ( <AddressForm fields={item} key={i} /> ))} */}
                </div>
            </div>

            <Modal title="" visible={showAddressForm} width={"700px"} onCancel={()=>this.toggleAddressForm()} footer={false} 
                bodyStyle={{ border: "1px solid black", padding: "0px", minHeight:"550px", overflowY:"scroll", overflowX:"hidden", backgroundColor:"white" }}>
                {showAddressForm && <AddressForm onCancel={()=>this.toggleAddressForm()} fields={this.state.selectedAddress} user_id={this.props.user._id} onSuccess={this.onAddressAdded} />}
            </Modal>

        </div>)
    }

}

const WithApollo = compose(

    graphql(GET_RECORD, {
        options: (props) => {
            return {
                variables: { id: props.user._id },
                notifyOnNetworkStatusChange: true,
                // variables: { id: props.user_id },
                // fetchPolicy: "no-cache", //no-cache, cache-first, network-only, cache-only, cache-and-network
            };
        },
        props: ({ ownProps, data }) => {
            const { loading, userAddresses, error, fetchMore, subscribeToMore } = data;

            if (error){
                console.log(__error("error"), error);
                // console.log("data: ", data.networkError.result.error)
                // if (data?.networkError?.result?.error?.message) return data.networkError.result;
            }

            const loadMoreRows = (id) => {
                let vars = { id };
                let updateQuery = (previousResult, { fetchMoreResult }) => {
                    return { userAddresses: fetchMoreResult.userAddresses }
                }
                return fetchMore({ variables: vars, updateQuery: updateQuery }).then(r => {
                    if (r.error || r.errors) {
                        message.error(`Query Error!`);
                        console.log(__error("QUERY ERROR"), r);
                    }
                    return r;
                });
            }

            return { loading, userAddresses, queryErrors: error, subscribeToMore, loadMoreRows }
        },
    }),

    graphql(DELETE_RECORD, {
        props: ({ mutate }) => ({
            deleteUserAddress: (id) => mutate({
                variables: { id }
            }),
        })
    }),

    graphql(SET_DEFAULT, {
        props: ({ mutate }) => ({
            setDefaultAddress: (id, user_id) => mutate({
                variables: { id, user_id }
            }),
        })
    }),

)(UserAddressesPage);

const mapStateToAvatarPopProps = state => {
    return ({ 
        user: state?.grocer_storefront?.user,
    });
}
export default connect(mapStateToAvatarPopProps)(WithApollo);

// export default WithApollo;
