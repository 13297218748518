import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import ProdList from '../components/products_list';
import { withApollo } from 'react-apollo';
import { Loader } from 'Common/components';
import { gql } from 'graphql.macro';
import { __error } from 'Common/scripts/consoleHelper';
import { Alert } from 'antd';
import { Helmet } from "react-helmet";

const GET_STORE = gql`
    query relatedData($store_filter: String) {
      stores(filter: $store_filter) {
        _id
        title
        slug
        logo_thumb
        description
        availability_days
        category
        country
        status
        hipo_loyalty_program_apikey
      }
    }`

const GET_CAT = gql`
  query relatedData($cat_filter: String) {
      productCats(filter: $cat_filter) {
        _id
        title
        slug
        seo_title seo_desc
      }
  }`

const GET_CAT_STORE = gql`
  query relatedData($cat_filter: String, $store_filter:String) {
      productCats(filter: $cat_filter) {
        _id
        title
        slug
        seo_title seo_desc
      }
      stores(filter: $store_filter) {
        _id
        title
        slug
        logo_thumb
        description
        availability_days
        category
        country
        status
      }
  }`


const CategoryIndex = props => {
  const [busy, setBusy] = useState(false);
  const [relatedData, set_relatedData] = useState(null);
  const [error, setError] = useState(null);
  const { store_slug, cat_slug, kw } = props?.match?.params;
  // console.log("props?.match?.params: ", props?.match?.params)

  let filter = {}
  if (store_slug) Object.assign(filter, { store_slug, status:'published' })
  if (cat_slug) Object.assign(filter, { cat_slug, status:'enabled' })
  
  useEffect(() => {
    getRelatedData();
  }, [props])
  
  const getRelatedData = async () => {
    let variables = {}
    let query = null;

    if (cat_slug){
      query = GET_CAT;
      Object.assign(variables, { cat_filter: JSON.stringify({ slug:cat_slug }) })
    }

    if (store_slug){
      query = GET_STORE;
      Object.assign(variables, { store_filter: JSON.stringify({ slug:store_slug }) })
    }

    if (store_slug && cat_slug) query = GET_CAT_STORE;
    if (!query) return;

    setBusy(true);

    const _relatedData = await props.client.query({ query, variables, // fetchPolicy: "no-cache",
    }).then(r => {
      const response = r.data;
      if(!response) return { error:{message:"Invalid response"}}

      const { stores, productCats } = response;
      if (stores && stores.error) return stores;
      if (productCats && productCats.error) return productCats;

      return { productCats: productCats && productCats[0], stores: stores && stores[0] }

    }).catch(err => {
      console.log(__error("Query Error"), err);
      return { error:{ message:"Query Error" } };
    });

    
    if (_relatedData.error) setError(_relatedData.error.message);
    else set_relatedData(_relatedData);

    setBusy(false);
  }
 
  if (busy) return <Loader type="skeleton" loading={true} />
  if (error) return <Alert message={error} type="error" showIcon />

  return (<>
    {relatedData && <Helmet>
      <title>{relatedData?.stores?.title || ""} {relatedData?.productCats?.seo_title || relatedData?.productCats?.title || ""}</title>
      <meta name="description" content={`${relatedData?.stores?.description || ""} - ${relatedData?.productCats?.seo_desc || relatedData?.productCats?.seo_title || ""}`} />
    </Helmet>}

    <ProdList 
      // searchFilter={props.searchFilter}
      storeInfo={relatedData ? relatedData.stores : null}
      catInfo={relatedData ? relatedData.productCats : null}
      filter={{ store_slug, cat_slug, search: !kw ? undefined : { keywords:kw }, store_status: { $ne:"offline"} }} 
    />
  </>)

}

export default withApollo(CategoryIndex);